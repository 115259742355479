<script>
// import SidebarItem from '@/components/SidebarItem'
import path from 'path'
import { createMenu } from '@/untils/util.menu'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['sidebar', 'permission_routes']),
    isCollapse () {
      return !this.sidebar.opened
    },
    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  },
  components: {
    // SidebarItem
  },
  render (h) {
    return (
      <el-scrollbar
        class={{ 'menu-scroll-bar': true, 'is---collapse': this.isCollapse }}
      >
        <el-menu
          class='menu_bar'
          router
          default-active={this.activeMenu}
          collapse={this.isCollapse}
          collapse-transition={false}
          unique-opened
        >
          {this.permission_routes.map(menu =>
            createMenu.call(this, h, menu, menu.path)
          )}
        </el-menu>
        <div onClick={() => this.onToggleCollapse()} class='collapse-button'>
          <i class='el-icon-s-fold'></i>
        </div>
      </el-scrollbar>
    )
  },
  methods: {
    resolvePath (basePath, routePath) {
      return path.resolve(basePath, routePath)
    },
    onToggleCollapse () {
      this.$store.dispatch('app/toggleSideBar')
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';
@import '@/assets/icon_font/menu/iconfont.css';
@import '@/assets/icon_font/color-icon/iconfont.css';
.menu-scroll-bar {
  height: calc(100vh - 44px);
  width: 189px;
  flex-shrink: 0;
  background-color: #fff;
  border-right: solid 1px #e6e6e6;

  [class^='iconfont'] {
    display: inline-block;
    margin-right: 7px;
    height: 24px;
    line-height: 24px;
    width: 24px;
    flex-shrink: 0;
    text-align: center;
    border-radius: 3px;
    font-size: 16px;
    background-color: #f0f0f0;
    color: #262626 !important;
  }

  &.is---collapse {
    width: 50px;

    [class^='iconfont'] {
      margin-right: 0;
      margin-left: 3px;
    }

    .el-icon-s-fold {
      transform: rotate(180deg);
    }
  }

  .collapse-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 13px;
    bottom: 13px;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    background-color: #f7f8fa;
    color: #86909b;
    cursor: pointer;

    .el-icon-s-fold {
      font-size: 14px;
    }

    &:hover {
      background-color: #e5e6eb;
    }
  }

  .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .el-scrollbar__bar {
    display: none;
  }

  .el-menu--collapse {
    width: 50px;
    margin-bottom: 50px;

    .el-tooltip {
      padding: 0 10px !important;
    }
  }

  .menu_bar {
    // height: calc(100vh - 44px);
    border-right: none;

    .el-menu-item {
      padding: 0 10px !important;
      height: 50px;
      line-height: 50px;
      font-size: 12px;

      &.is-active {
        [class^='iconfont'] {
          background-color: $--color-primary;
          color: #fff !important;
        }
      }
    }

    .el-submenu {
      .el-submenu__title {
        padding: 0 10px !important;
        height: 50px;
        line-height: 50px;
        font-size: 12px;
      }

      .el-submenu {
        .el-submenu__title {
          padding: 0 20px !important;
          padding-left: 40px !important;
        }

        .el-menu-item {
          padding: 0 30px !important;
          padding-left: 50px !important;
        }
      }

      &.is-active {
        & > .el-submenu__title {
          color: $--color-primary;

          .el-submenu__icon-arrow {
            color: $--color-primary;
          }
        }

        [class^='iconfont'] {
          background-color: $--color-primary;
          color: #fff !important;
        }
      }
      .el-menu-item {
        box-sizing: border-box;
        padding: 0 20px !important;
        padding-left: 40px !important;
        min-width: 0;
      }
    }
  }
}

.el-menu--popup.el-menu--popup-right-start {
  .el-menu-item {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
  }
}
</style>
