import router from '@/router'
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store/index'
import { Message } from 'element-ui'
import { useTitle } from '@vueuse/core'

router.beforeEach(async (to, from, next) => {
  Nprogress.start()
  const userInfo = store.getters.userInfo
  const authorities = to.meta && to.meta.authorities
  const whiteList = ['/user/login']
  if (userInfo.username) {
    const lastTime = store.getters.lastTime
    // 判断是否账户过期
    if (Date.now() - lastTime > 1 * 60 * 60 * 1000) {
      Message.error('登录账户已过期，请重新登录！')
      await store.dispatch('logout')
      next({
        path: '/user/login',
        replace: true
      })
      Nprogress.done()
    } else {
      const hasRoles = store.getters.role
      // 更新登录时间
      await store.dispatch('setLastTime')
      if (hasRoles) {
        next()
      } else {
        if (whiteList.indexOf(to.path) > -1) {
          next()
        } else {
          try {
            const { status } = await store.dispatch('getUserRoles')
            if (status) {
              const accessRoutes = await store.dispatch('generateRoutes')
              accessRoutes.push({
                path: '/:pathMatch(.*)*',
                hidden: true,
                component: () => import(/* webpackChunkName: "error" */ '@/views/error/NotFoundView')
              })
              router.addRoutes(accessRoutes)
              next({ ...to, replace: true })
            } else {
              next({
                path: '/user/login',
                query: {
                  redirect: to.fullPath
                }
              })
              Nprogress.done()
            }
          } catch (error) {
            await store.dispatch('logout')
            if (whiteList.indexOf(to.path) === -1) {
              next({
                path: '/user/login',
                query: {
                  redirect: to.fullPath
                }
              })
              Nprogress.done()
            } else {
              next()
            }
          }
        }
      }
    }
  } else {
    if (to.matched && to.matched.length > 0) {
      if (authorities === 'login') {
        next({
          path: '/user/login',
          query: {
            redirect: to.fullPath
          }
        })
        Nprogress.done()
      } else {
        next()
      }
    } else {
      next({ path: '/404', replace: true })
      Nprogress.done()
    }
  }
})

const pageTitle = useTitle()
router.afterEach((to) => {
  pageTitle.value = to.meta.title ? '默默通-' + to.meta.title : '默默通物流'
  Nprogress.done()
})
