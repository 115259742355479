const front = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ '@/views/front/LayoutView'),
    children: [
      {
        path: '',
        meta: {
          title: '首页'
        },
        component: () => import(/* webpackChunkName: "index" */ '@/views/front/home/IndexView')
      },
      {
        path: 'gdlist',
        meta: {
          title: '货源中心'
        },
        component: () => import(/* webpackChunkName: "gdlist" */ '@/views/front/goods/list/IndexView')
      },
      {
        path: 'gddetail/:id',
        component: () => import(/* webpackChunkName: "gddetail" */ '@/views/front/goods/detail/IndexView')
      },
      {
        path: 'tklist',
        meta: {
          title: '车源中心'
        },
        component: () => import(/* webpackChunkName: "tklist" */ '@/views/front/trucks/list/IndexView')
      },
      {
        path: 'tkdetail/:id',
        component: () => import(/* webpackChunkName: "tkdetail" */ '@/views/front/trucks/detail/IndexView')
      },
      {
        path: 'about',
        meta: {
          title: '关于我们'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/front/about/IndexView')
      },
      {
        path: 'news',
        meta: {
          title: '资讯'
        },
        component: () => import(/* webpackChunkName: "news" */ '@/views/front/news/list/IndexView')
      },
      {
        path: 'newdetail/:id',
        component: () => import(/* webpackChunkName: "newdetail" */ '@/views/front/news/detail/IndexView')
      },
      {
        path: 'pricechart',
        component: () => import(/* webpackChunkName: "pricechart" */ '@/views/front/data/PriceView')
      },
      {
        path: 'pay',
        meta: {
          authorities: 'login'
        },
        component: () => import(/* webpackChunkName: "pay" */ '@/views/front/pay/IndexView')
      },
      {
        path: 'payresult',
        meta: {
          authorities: 'login'
        },
        component: () => import(/* webpackChunkName: "payresult" */ '@/views/front/pay/result/IndexView')
      }
    ]
  },
  {
    path: '/thdqys',
    meta: {
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "thdqys" */ '@/views/front/third/qiyuesuo/IndexView')
  },
  {
    path: '/qysresult',
    meta: {
      authorities: 'login'
    },
    component: () => import(/* webpackChunkName: "qysresult" */ '@/views/front/third/qiyuesuo/ResultView')
  }
]
export default front
