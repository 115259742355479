import service, { fileService } from '@/untils/axios-config'
/**
 * Axios response
 * @typedef {Promise.<{ data: { result } }>} AxiosResponse
 */
const request = (fileName, actionname, data = {}) => {
  return service.post(`${fileName}/unifyCallFunc`, { actionname, ...data }, { headers: { Methdname: actionname } })
}
const filerequest = (fileName, actionname, formData) => {
  return fileService.post(`${fileName}/${actionname}`, formData)
}

const services = {
  user: {
    // 用户登录
    login: ({ username, password }) => request('Public', 'login', { username, password }),
    // 用户注册
    reg: (params) => request('Public', 'reg', params),
    // 获取短信验证码
    getCode: (phone) => request('Public', 'getCode', { phone }),
    // 判断手机号是否存在
    isMobExist: (mob) => request('Public', 'is_mob_exist', { mob }),
    // 重置密码
    resetPassword: (params) => request('Public', 'resetPassword', params),
    // 验证码登录
    loginbyMobCode: (params) => request('Public', 'loginbymobcode', params),
    // 退出登录
    quit: (uid) => request('Public', 'quit', { uid }),
    // 获取当前用户角色
    getCurrentUserRoleXX: () => request('Public', 'getCurrentUserRoleXX'),
    // 获取用户菜单 FOR PC
    getUserMenueList4PC: () => request('Member', 'getUserMenueList4PC'),
    // 获取用户角色信息
    getUserRoleInfo: (curgpid) => request('Public', 'getUserRoleInfo', { curgpid }),
    // 获取用于切换的公司列表
    getCompList4Change: () => request('Member', 'getCompList4Change'),
    // 切换公司
    changeTheComp: (params) => request('Member', 'changeTheComp', params),
    // 设置用户角色信息
    setUserRole: (curgpid) => request('Public', 'setUserRole', { curgpid }),
    // 获取隐私政策内容
    getPrivXX: () => request('PrivXXMgr', 'getPrivXX')
  },
  comUserMgr: {
    // 获取用户公司下的部门
    getDeptmentList: () => request('UserMgr', 'getDeptmentList'),
    // 获取未关联公司的用户列表
    getMemberUserDlmcList: (params) => request('UserMgr', 'getMemberUserDlmcList', params),
    // 获取人员信息列表
    getUserRegList: (params) => request('UserMgr', 'getUserRegList', params),
    // 修改用户
    saveRegUserNew: (params) => request('UserMgr', 'saveRegUserNew', params),
    // 删除人员信息
    delRegUserNew: (params) => request('UserMgr', 'delRegUserNew', params),
    // 新增用户
    addRegUser: (params) => request('UserMgr', 'addRegUser', params),
    // 获取关联公司的用户列表
    getDlmcList: (params) => request('UserMgr', 'getDlmcList', params),
    // 获取未关联权限列表和已关联权限列表
    getfuncList: (params) => request('UserMgr', 'getfuncList', params),
    // 企业用户分配权限 post传递
    addUserFuncs: (params) => request('UserMgr', 'addUserFuncs', params),
    // 引入用户
    importRegUsers: (params) => request('UserMgr', 'importRegUsers', params),
    // 合同授权管理-初始化
    getHTGrantUserInit: () => request('UserMgr', 'getHTGrantUserInit'),
    // 合同授权管理-授权与取消授权
    grantHTUsers: (params) => request('UserMgr', 'grantHTUsers', params)
  },
  deptMgr: {
    // 获取部门管理主列表面板
    getSubGroupList: (params) => request('SubGroupMgr', 'getSubGroupList', params),
    // 创建部门信息
    addSubGroupList: (params) => request('SubGroupMgr', 'addSubGroupList', params),
    // 修改部门面板信息
    editSubXX: (params) => request('SubGroupMgr', 'editSubXX', params),
    // 删除部门信息
    delSubXX: (params) => request('SubGroupMgr', 'delSubXX', params),
    // 获取关联人员列表和已关联人员列表
    getsubList: (params) => request('SubGroupMgr', 'getsubList', params),
    getStaffList4Dept: (params) => request('SubGroupMgr', 'getStaffList4Dept', params),
    // 关联人员信息
    addgpuserXX: (params) => request('SubGroupMgr', 'addgpuserXX', params),
    // 修改岗位类型
    modPostType: (params) => request('SubGroupMgr', 'modPostType', params)
  },
  flowmgr: {
    // 获取流程主列表面板
    getFlowsList: (params) => request('BeseFlowMgr', 'getFlowsList', params),
    // 修改流程信息启用/禁用状态
    editFlowStatus: (params) => request('BeseFlowMgr', 'editFlowStatus', params),
    // 删除流程信息
    delFlowXX: (params) => request('BeseFlowMgr', 'delFlowXX', params),
    // 获取未引入的流程数据
    getBaseFlowList: () => request('BeseFlowMgr', 'getBaseFlowList'),
    // 引入流程信息
    addFlowcs: (params) => request('BeseFlowMgr', 'addFlowcs', params),
    // 流程设置-获取已设置的流程节点及初始化信息
    getFlowNodeList: (params) => request('BeseFlowMgr', 'getFlowNodeList', params),
    // 根据id设置流程节点
    addNodeXX: (params) => request('BeseFlowMgr', 'addNodeXX', params),
    // 设置节点审批顺序
    delProceXX: (params) => request('BeseFlowMgr', 'delProceXX', params),
    // 根据id修改节点信息
    setProceXX: (params) => request('BeseFlowMgr', 'setProceXX', params)

  },
  feedback: {
    // 提交回访信息
    visitBack: (params) => request('TousuMgr', 'visitBack', params),
    // 结束回访信息
    endTouSuInfo: (params) => request('TousuMgr', 'endTouSuInfo', params),
    // 获取回访日志
    getTouSuHisList: (tousuid) => request('TousuMgr', 'getTouSuHisList', { tousuid }),
    // 获取咨询投诉列表
    getTouSuList: (params) => request('TousuMgr', 'getTouSuList', params)
  },
  person: {
    // 获取用户手机号
    getUserPhoneXX: () => request('PrivXXMgr', 'getUserPhoneXX'),
    // 获取用户信息（主要是针对个人资料设置这块）
    getUserPrivXX: () => request('PrivXXMgr', 'getUserPrivXX'),
    // 身份基本信息
    getUserIDCardXX: () => request('PrivXXMgr', 'getUserIDCardXX'),
    // 注销账户
    delUserXX: () => request('PrivXXMgr', 'delUserXX'),
    // 修改密码
    updateUserPassword: (params) => request('PrivXXMgr', 'updateUserPassword', params),
    // 修改手机号码
    updateMobPhone: (params) => request('PrivXXMgr', 'updateMobPhone', params),
    // 同时更新图像、所属公司和电子邮箱
    updateAllXX: (params) => request('PrivXXMgr', 'updateAllXX', params),
    // CA 个人手机号码三要素验证（用于获取证书）
    sendCode4AppCert: (params) => request('BestSign', 'sendCode4AppCert', params),
    // 申请CA证书
    appUserCACert: (params) => request('BestSign', 'appUserCACert', params),
    // CA 注册账号并完善证件信息
    regUser: () => request('BestSign', 'regUser')
  },
  whcall: {
    // :呼叫中心初始化 FOR 仓储管家
    getInitXX4CCGJ: () => request('WHCallMgr', 'getInitXX4CCGJ'),
    // 获取普通车辆排队列表 FOR 仓储管家
    getTruckQueeList4CCGJ: (whname) => request('WHCallMgr', 'getTruckQueeList4CCGJ', { whname }),
    // 获取下一辆车 FOR 仓储管家
    nextTruckIn4CCGJ: (params) => request('WHCallMgr', 'nextTruckIn4CCGJ', params),
    // 车辆出库 FOR 仓储管家
    truckOut4CCGJ: (tkqueeid) => request('WHCallMgr', 'truckOut4CCGJ', { tkqueeid }),
    // 车辆跳过此轮排队 FOR 仓储管家
    skipCurTruckQuee4CCGJ: (params) => request('WHCallMgr', 'skipCurTruckQuee4CCGJ', params)
  },
  certificate: {
    // 身份证ocr识别
    idCardOCR: (params) => request('Ziyun', 'idCardOCR', params),
    // 身份证图片上传
    uploadIDCard: (params) => request('Ziyun', 'uploadIDCard', params),
    // 营业执照图片上传
    uploadBusinessLicense: (params) => request('Ziyun', 'uploadBusinessLicense', params),
    // 个人用户实名认证
    identityUser: (params) => request('QYSuoSign', 'identityUser', params),
    // 验证用户是否已认证及认证类型
    getUserRzXX: () => request('RzUserMgrV1', 'getUserRzXX'),
    // 行驶证OCR识别
    vehicleLicenseOCR: (params) => request('Ziyun', 'vehicleLicenseOCR', params),
    newvehicleLicenseOCR: (params) => request('Ziyun', 'newvehicleLicenseOCR', params),
    // 驾驶证识别
    drivingLicenseOCR: (params) => request('Ziyun', 'drivingLicenseOCR', params),
    // 司机驾驶证图片上传
    uploadDriverLicense: (params) => request('Ziyun', 'uploadDriverLicense', params),
    uploadDriverQualify: (params) => request('Ziyun', 'uploadDriverQualify', params),
    // 获取驾驶证识别信息（OCR）POST
    newdrivingLicenseOCR: (params) => request('Ziyun', 'newdrivingLicenseOCR', params),
    // 道路运输经营许可证图片上传
    uploadRoadJYXKZ: (params) => request('Ziyun', 'uploadRoadJYXKZ', params),
    // 获取省份信息
    getProviceList: () => request('AddrMgr', 'getProviceList'),
    // 发起司机认证
    tjdriverrz: (params) => request('RzUserMgrV1', 'tjdriverrz', params),
    // 修改司机认证（修改认证申请审核中的）
    moddriverrz: (params) => request('RzUserMgrV1', 'moddriverrz', params),
    // 修订司机认证（已通过认证申请审核的）
    retjdriverrz: (params) => request('RzUserMgrV1', 'retjdriverrz', params),
    // 营业执照OCR识别
    businessLicenseOCR: (params) => request('Ziyun', 'businessLicenseOCR', params),
    // 发起仓储认证
    tjwhrz4comp: (params) => request('RzUserMgrV1', 'tjwhrz4comp', params),
    // 修改仓储认证（认证申请中）
    modwhrz4comp: (params) => request('RzUserMgrV1', 'modwhrz4comp', params),
    // 修订仓储认证（已认证通过的）
    retjwhrz4comp: (params) => request('RzUserMgrV1', 'retjwhrz4comp', params),
    // 发起运输公司认证
    tjtrancomprz: (params) => request('RzUserMgrV1', 'tjtrancomprz', params),
    // 修改运输公司认证（认证申请审核中）
    modtrancomprz: (params) => request('RzUserMgrV1', 'modtrancomprz', params),
    // 修改运输公司认证（已审核通过的认证申请）
    retjtrancomprz: (params) => request('RzUserMgrV1', 'retjtrancomprz', params),
    // 企业用户身份信息校验
    identityComp: (params) => request('QYSuoSign', 'identityComp', { dev: 'PC', ...params }),
    // 货主认证-个人
    tjgdrz4person: (params) => request('RzUserMgrV1', 'tjgdrz4person', params),
    // 修改货主认证-个人（认证申请审核中）
    modgdrz4person: (params) => request('RzUserMgrV1', 'modgdrz4person', params),
    // 修订货主认证-个人（已通过认证申请审核的）
    retjgdrz4person: (params) => request('RzUserMgrV1', 'retjgdrz4person', params),
    // 货主认证-公司
    tjgdrz4comp: (params) => request('RzUserMgrV1', 'tjgdrz4comp', params),
    // 修改货主认证-公司（认证申请审核中）
    modgdrz4comp: (params) => request('RzUserMgrV1', 'modgdrz4comp', params),
    // 修订货主认证-公司（已通过认证审核的）
    retjgdrz4comp: (params) => request('RzUserMgrV1', 'retjgdrz4comp', params),
    // 获取个体身份校验信息
    // getPersonSMCheckXX: () => request('RzUserMgr', 'getPersonSMCheckXX'),
    // 获取公司真实性校验信息
    // getCompSMCheckXX: () => request('RzUserMgr', 'getCompSMCheckXX'),
    // 通过用户名称和认证类型获取认证信息
    getchkedRzXX: (params) => request('RzUserMgrV1', 'getchkedRzXX', params),
    // 通过用户名称和认证类型获取认证信息
    getunchkRzXX: (params) => request('RzUserMgrV1', 'getunchkRzXX', params),
    // 取回认证（认证审核中）
    endNowRzApp: (params) => request('RzUserMgr', 'endNowRzApp', params),
    // 取回认证申请或取回认证变更申请（审核中） For ALL
    getBackRZAndChgApp: (params) => request('RzUserMgrV1', 'getBackRZAndChgApp', params),
    // 申请取消认证（已认证的）
    cancelRzApp: (params) => request('RzUserMgrV1', 'cancelRzApp', params),
    // 取回资料变更申请（认证审核中）
    endNowRzChgApp: (params) => request('RzUserMgr', 'endNowRzChgApp', params),
    // 上传司机从业资格证图片信息(专门上传64位图片)
    uploadDriverQualifyBase64Img: (params) => request('ElArcMgr', 'uploadDriverQualifyBase64Img', params),
    // 上传经营许可证图片信息(专门上传64位图片)
    uploadRoadJYXKZBase64Img: (params) => request('ElArcMgr', 'uploadRoadJYXKZBase64Img', params),
    // 上传行驶证图片信息(专门上传64位图片)
    uploadVechicleBase64Img: (params) => request('ElArcMgr', 'uploadVechicleBase64Img', params),
    // 上传道路运输证图片信息(专门上传64位图片)
    uploadRoadTranBase64Img: (params) => request('ElArcMgr', 'uploadRoadTranBase64Img', params),
    // 获取司机视角下营业执照信息
    getDriverBussinessXX: () => request('CertificateMgr', 'getDriverBussinessXX'),
    // 完善司机营业执照信息
    modDriverBusLicenseXX: (params) => request('CertificateMgr', 'modDriverBusLicenseXX', params)
  },
  aiworkbook: {
    // 获取推荐分享指定用户列表
    getVisitorList: () => request('YFPriceAI', 'getVisitorList'),
    // 获取跨区域物流联动我的发布运价列表 for 线路发布
    getYFPriceList: (params) => request('YFPriceAI', 'getYFPriceList', params),
    // 获取初始化信息 用于发布运价识别用户信息
    getInitXX4Comp: () => request('YFPriceAI', 'getInitXX4Comp'),
    // 获取小梦AI推送方式列表
    getAIPushTypeList: () => request('YFPriceAI', 'getAIPushTypeList'),
    // 获取配送方式列表
    getAIPSModelList: () => request('YFPriceAI', 'getAIPSModelList'),
    // 获取运输条件列表
    getAIShipList: () => request('YFPriceAI', 'getAIShipList'),
    // 判断线路是否被认领 for 线路发布
    decideLineClaim: (params) => request('YFPriceAI', 'decideLineClaim', params),
    // 跨区域物流联动发布运费价格 for 线路发布
    addYFPriceInfo: (params) => request('YFPriceAI', 'addYFPriceInfo', params),
    getDlmcAndMyTel: (params) => request('WX', 'getDlmcAndMyTel', params),
    bindPushUserInfo: (params) => request('WX', 'bindPushUserInfo', params),
    // 撤回线路认领信息 for 线路发布——认领待审核
    getBackPsrtAppInfo: (params) => request('YFPriceAI', 'getBackPsrtAppInfo', params),
    // 删除已撤回的线路认领信息 for 线路发布——已撤回认领
    delPsrtAppInfo: (params) => request('YFPriceAI', 'delPsrtAppInfo', params),
    // 删除民间线路运价信息
    delYFPriceInfo: (params) => request('YFPriceAI', 'delYFPriceInfo', params),
    getPushAccountList: (params) => request('YFPriceAIPush', 'getPushAccountList', params),
    delPushInfo: (params) => request('YFPriceAIPush', 'delPushInfo', params),
    againPushInfo: (params) => request('YFPriceAIPush', 'againPushInfo', params),
    // 获取初始化信息 for 平台方
    getInitXX4Admin: () => request('YFPriceAI', 'getInitXX4Admin'),
    // 获取物流运价列表 for 物流运价列表
    getPsrtNewList: (params) => request('YFPriceAI', 'getPsrtNewList', params),
    // 获取单条数据信息及最新一条官方数据
    getToChangeYfInfo: (params) => request('YFPriceAI', 'getToChangeYfInfo', params),
    // 更改线路报价类型 for 物流运价列表-店铺运价
    alterYfInfo4Ntype: (params) => request('YFPriceAI', 'alterYfInfo4Ntype', params),
    // 获取物流运价审批列表 for 物流运价审批
    getYFPriceApproveList: (params) => request('YFPriceAI', 'getYFPriceApproveList', params),
    // 审核通过第一次认领后发布的线路信息 for 物流运价审批——认领待审核
    chkedPsrtAppInfo: (params) => request('YFPriceAI', 'chkedPsrtAppInfo', params),
    // 审核通过第一次认领之后的发布线路信息 for 物流运价审批——报价待审核
    chkedPsrtNewInfo: (params) => request('YFPriceAI', 'chkedPsrtNewInfo', params),
    // 驳回线路认领信息 for 物流运价审批——认领待审核
    BHChkPsrtAppInfo: (params) => request('YFPriceAI', 'BHChkPsrtAppInfo', params),
    // 驳回报价信息 for 物流运价审批——报价待审核
    BHChkPsrtNewInfo: (params) => request('YFPriceAI', 'BHChkPsrtNewInfo', params),
    // 取消审核通过的线路信息 for 物流运价审批——运价已通过
    canchkPsrtAppInfo: (params) => request('YFPriceAI', 'canchkPsrtAppInfo', params),
    // 导出物流运价审批列表 for 物流运价审批
    exportYFPriceApproveList: (params) => request('YFPriceAI', 'exportYFPriceApproveList', params),
    // 获取认证公司列表（承运方和托运方） for 线路台账
    getRZAppComp: (params) => request('YFPriceAI', 'getRZAppComp', params),
    // 获取发布人员列表 for 线路台账
    getYWSeller: (params) => request('YFPriceAI', 'getYWSeller', params),
    // 获取线路列表-用于选择线路 for 查看线路权限配置
    getPsrtAccountList: (params) => request('YFPriceAI', 'getPsrtAccountList', params),
    // 线路台账添加指定发布人 for 线路台账
    addPsrtAccountInfo: (params) => request('YFPriceAI', 'addPsrtAccountInfo', params),
    // 删除/取消认领权线路台账信息 for 线路台账
    delPsrtAccountInfo: (params) => request('YFPriceAI', 'delPsrtAccountInfo', params),
    // 获取未关联账号列表（包含托运方和承运方） for 线路台账
    getUserDlmcList4Acc: (params) => request('YFPriceAI', 'getUserDlmcList4Acc', params),
    // 编辑指定线路发布人信息 for 线路台账
    editPsrtAccountInfo: (params) => request('YFPriceAI', 'editPsrtAccountInfo', params),
    // 获取线路台账列表 for 线路台账
    exportPsrtAccountList: (params) => request('YFPriceAI', 'exportPsrtAccountList', params),
    // 获取线路列表-用于选择线路 for 查看线路权限配置
    getPsrtAccountList4Inquire: (params) => request('YFPriceAI', 'getPsrtAccountList4Inquire', params),
    // 获取账号列表 用于添加线路 for 查看线路权限配置
    getUserDlmcList4Add: (params) => request('YFPriceAI', 'getUserDlmcList4Add', params),
    // 给指定线路关联账号配置权限 for 查看线路权限配置
    addPsrtAuthInfo: (params) => request('YFPriceAI', 'addPsrtAuthInfo', params),
    // 获取线路权限配置列表 for 查看线路权限配置
    getPsrtAuthMgrList: (params) => request('YFPriceAI', 'getPsrtAuthMgrList', params),
    // 删除已关联线路信息 for 查看线路权限配置
    delPsrtAuthInfo: (params) => request('YFPriceAI', 'delPsrtAuthInfo', params),
    // 获取指定关联线路关联的账号列表 for 查看线路权限配置
    getToLinkUserdlmcList: (params) => request('YFPriceAI', 'getToLinkUserdlmcList', params),
    // 获取已过滤账号列表 用于修改  for 查看线路权限配置
    getUserDlmcList4Edit: (params) => request('YFPriceAI', 'getUserDlmcList4Edit', params),
    // 给指定线路修改账号配置权限 for 查看线路权限配置
    editPsrtAuthInfo: (params) => request('YFPriceAI', 'editPsrtAuthInfo', params),
    // 删除指定线路关联的账号列表 for 查看线路权限配置
    delToLinkUserdlmcList: (params) => request('YFPriceAI', 'delToLinkUserdlmcList', params),
    // 批量导入路线运价信息
    qrImportPSRtPrices: (params) => request('YFPriceAI', 'qrImportPSRtPrices', params),
    // 编辑运价
    editPSRtPrice: (params) => request('YFPriceAI', 'editPSRtPrice', params)

  },
  sysfunc: {
    // 点击添加或查询按钮初始化弹框中下拉列表数据
    initRegFun: () => request('SysFuncMgr', 'initRegFun'),
    // 获取权限配置管理初始化列表
    getRegFunlist: (params) => request('SysFuncMgr', 'getRegFunlist', params),
    // 删除权限管理信息
    delRegFunNew: (params) => request('SysFuncMgr', 'delRegFunNew', params),
    // 新增权限配置信息
    addRegFunNew: (params) => request('SysFuncMgr', 'addRegFunNew', params),
    // 修改权限功能面板
    saveRegFunNew: (params) => request('SysFuncMgr', 'saveRegFunNew', params)
  },
  levelfunc: {
    // 获取角色等级下参与关联的用户列表（还未关联上的）
    getNotInLevUserList: (params) => request('GplevUserFlow', 'getNotInLevUserList', params),
    // 获取角色等级下已关联的用户列表
    getInLevUserList: (params) => request('GplevUserFlow', 'getInLevUserList', params),
    // 取消关联等级用户信息
    delLeveUserForId: (params) => request('GplevUserFlow', 'delLeveUserForId', params),
    // 关联等级用户信息
    addLeveUsercs: (params) => request('GplevUserFlow', 'addLeveUsercs', params),

    // 获取下拉列表权限信息
    getInitFunInfo: () => request('Gplevfunclist', 'getInitFunInfo'),
    // 初始化各角色下的等级列表
    getLevelsList: (params) => request('Gplevfunclist', 'getLevelsList', params),
    // 添加角色等级信息
    addLevelsXX: (params) => request('Gplevfunclist', 'addLevelsXX', params),
    // 删除角色等级信息
    delLevelsXX: (params) => request('Gplevfunclist', 'delLevelsXX', params),
    // 根据对应的角色等级获取权限信息
    getLevelFunList: (params) => request('Gplevfunclist', 'getLevelFunList', params),
    // 根据对应的角色等级获取流程信息
    getLevelFlowList: (params) => request('Gplevfunclist', 'getLevelFlowList', params),
    // 取消关联的等级权限信息
    delLevelFuncXX: (params) => request('Gplevfunclist', 'delLevelFuncXX', params),
    // 取消等级流程信息
    delflowcXX: (params) => request('Gplevfunclist', 'delflowcXX', params),
    // 获取参与关联的权限列表
    getFuncList: (params) => request('Gplevfunclist', 'getFuncList', params),
    // 添加关联权限信息
    addGroupFuncs: (params) => request('Gplevfunclist', 'addGroupFuncs', params),
    // 确定关联流程信息
    addGroupFlowcs: (params) => request('Gplevfunclist', 'addGroupFlowcs', params)
  },
  rolefunc: {
    // 初始化各角色-权限统计数
    getGroupFunNum: (params) => request('GroupFunMgr', 'getGroupFunNum', params),
    // 取消关联角色权限信息
    delGroupFuncs: (params) => request('GroupFunMgr', 'delGroupFuncs', params),
    // 获取参与关联的权限列表
    getFunList4Attach: (params) => request('GroupFunMgr', 'getFunList4Attach', params),
    // 确定关联权限信息
    addGroupFuncs: (params) => request('GroupFunMgr', 'addGroupFuncs', params)
  },
  credit: {
    // 获取信用评价列表
    getEvaluateList: (params) => request('CreditMgr', 'getEvaluateList', params),
    // 修改评价状态
    editEvaluateInfo: (params) => request('CreditMgr', 'editEvaluateInfo', params),
    // 获取信用星级列表
    getCreditList: (params) => request('CreditMgr', 'getCreditList', params),
    // 修改信用星级
    editCreditInfo: (params) => request('CreditMgr', 'editCreditInfo', params)
  },
  colums: {
    // 获取分类列表
    getFidClassList: (params) => request('PCNewMgr', 'getFidClassList', params),
    // 添加栏目信息
    addFidInfo: (params) => request('PCNewMgr', 'addFidInfo', params),
    // 获取栏目列表
    getXCNewList: (params) => request('PCNewMgr', 'getXCNewList', params),
    // 是否推荐
    istg: (params) => request('PCNewMgr', 'istg', params),
    // 是否设置系统栏目
    isSetSystemFid: (params) => request('PCNewMgr', 'isSetSystemFid', params),
    // 是否显示物流圈
    isshowincircle: (params) => request('PCNewMgr', 'isshowincircle', params),
    // 编辑栏目信息
    editFidInfo: (params) => request('PCNewMgr', 'editFidInfo', params),
    // 删除栏目信息
    delFidInfo: (params) => request('PCNewMgr', 'delFidInfo', params)
  },
  publishnews: {
    // 获取栏目列表
    getFidList: () => request('PCNewMgr', 'getFidList'),
    // 发布资讯
    addNewsInfo: (params) => request('PCNewMgr', 'addNewsInfo', params)
  },
  newsList: {
    // 获取资讯列表
    getXCNewsList: (params) => request('PCNewMgr', 'getXCNewsList', params),
    // 编辑资讯信息
    editNewsInfo: (params) => request('PCNewMgr', 'editNewsInfo', params),
    // 删除资讯信息
    delNewsInfo: (params) => request('PCNewMgr', 'delNewsInfo', params),
    // 获取栏目列表
    getFidList: (params) => request('PCNewMgr', 'getFidList', params),
    // 获取资讯信息
    getNews4Info: (params) => request('PCNewMgr', 'getNews4Info', params)
  },
  HomepageNews: {
    // 获取首页资讯信息
    getHomeNewFidInfo: (params) => request('PCNewMgr', 'getHomeNewFidInfo', params),
    // 获取指定栏目的资讯列表
    getNewList4Newfid: (params) => request('PCNewMgr', 'getNewList4Newfid', params),
    // 获取资讯信息
    getNewsInfo: (params) => request('PCNewMgr', 'getNewsInfo', params)
  },
  detailNews: {
    // 获取首页资讯信息
    getHomeNewFidInfo: (params) => request('PCNewMgr', 'getHomeNewFidInfo', params),
    // 获取指定栏目的资讯列表
    getNewList4Newfid: (params) => request('PCNewMgr', 'getNewList4Newfid', params),
    // 获取资讯信息
    getNewsInfo: (params) => request('PCNewMgr', 'getNewsInfo', params)
  },
  GoodsLIst: {
    // 获取初始化信息
    getInit: (params) => request('GDXXPubCenter', 'getInit', params),
    // 获取货源中心列表
    getGoodCenterList: (params) => request('GDXXPubCenter', 'getGoodCenterList', params)
  },
  goodsgddetail: {
    // 获取详情信息
    getgdsrcxx: (params) => request('GDXXPubCenter', 'getgdsrcxx', params),
    // 获取首页车源推荐列表
    getTKRecommendList: (params) => request('GDXXPubCenter', 'getTKRecommendList', params),
    // 收藏
    collectGDSrcInfo: (params) => request('GDXXPubCenter', 'collectGDSrcInfo', params),
    // 取消收藏
    cancelCollectGDSrcInfo: (params) => request('GDXXPubCenter', 'cancelCollectGDSrcInfo', params),
    // 投诉
    tousuGDSrcInfo: (params) => request('GDXXPubCenter', 'tousuGDSrcInfo', params),
    // 我要接单 司机
    acceptOrder4SJZJ: (params) => request('GDXXPubCenter', 'acceptOrder4SJZJ', params),
    // 我要接单 物流公司
    acceptOrder4WLSJ: (params) => request('GDXXPubCenter', 'acceptOrder4WLSJ', params)
  },
  Trucksgddetail: {
    // 获取详情信息
    getTKSrcXX: (params) => request('FreeTruckCenter', 'getTKSrcXX', params),
    // 获取首页车源推荐列表
    getGDRecommendList: (params) => request('FreeTruckCenter', 'getGDRecommendList', params),
    // 收藏
    collectTKSrcInfo: (params) => request('FreeTruckCenter', 'collectTKSrcInfo', params),
    // 取消收藏
    cancelCollectTKSrcInfo: (params) => request('FreeTruckCenter', 'cancelCollectTKSrcInfo', params),
    // 投诉车源信息
    tousuTKSrcInfo: (params) => request('FreeTruckCenter', 'tousuTKSrcInfo', params)
  },
  TrucksList: {
    // 获取初始化信息
    getInit: (params) => request('FreeTruckCenter', 'getInit', params),
    // 获取车源中心列表
    getTKSrcCenterList: (params) => request('FreeTruckCenter', 'getTKSrcCenterList', params)
  },
  ZxtsMgr: {
    // 发起咨询与投诉
    appTousuInfo: (params) => request('TousuMgr', 'appTousuInfo', params)
  },
  ZxtsList: {
    // 获取咨询投诉列表
    getTouSu4qtList: (params) => request('TousuMgr', 'getTouSu4qtList', params)
  },
  SignOrderList: {
    // 获取签收单据列表 FOR 货主帝家
    getSignOrderList4HZDJ: (params) => request('OrderSign', 'getSignOrderList4HZDJ', params),
    // 获取签收单据列表 FOR 物流世家
    getSignOrderList4WLSJ: (params) => request('OrderSign', 'getSignOrderList4WLSJ', params),
    // 获取签收单据列表 FOR 司机之家
    getSignOrderList4SJZJ: (params) => request('OrderSign', 'getSignOrderList4SJZJ', params),
    // 获取签收单据列表 FOR 运营方
    getSignOrderList4YYF: (params) => request('OrderSign', 'getSignOrderList4YYF', params),
    // 查看待签收中的订单详情
    getunSignOrderDetail: (params) => request('OrderSign', 'getunSignOrderDetail', params),
    // 根据订单编号获取签收内容
    getSignOrderXX: (params) => request('OrderSign', 'getSignOrderXX', params),
    // 查看待审核、已签收、已驳回中的签收订单详细情况
    getSignOrderDetail: (params) => request('OrderSign', 'getSignOrderDetail', params),
    // 根据签收单编号获取签收内容（查看签收）
    getHisSignOrderList: (params) => request('OrderSign', 'getHisSignOrderList', params),
    // 初次提交签收内容
    tjsigncontent: (params) => request('OrderSign', 'tjsigncontent', params),
    // 取回
    getbacksigndd: (params) => request('OrderSign', 'getbacksigndd', params),
    // 驳回
    bhsigndd: (params) => request('OrderSign', 'bhsigndd', params),
    // 被退回的签收重新提交
    retjsigncontent: (params) => request('OrderSign', 'retjsigncontent', params),
    // 审核通过签收信息
    chksigndd: (params) => request('OrderSign', 'chksigndd', params),
    // 打印送料单（对于待签收栏目里面的打印）--废弃
    printdd4unsign: (params) => request('OrderSign', 'printdd4unsign', params),
    // 打印送料单（对于待审核和已签收栏目里面的打印）--废弃
    printdd4signed: (params) => request('OrderSign', 'printdd4signed', params),
    // 更新送料单打印次数（对于待签、待审核和已签收收栏目里面的打印）
    updateddprint: (params) => request('OrderSign', 'updateddprint', params),
    // 打印送料单（对于已派车里面的打印）
    printPSOrderXX: (params) => request('DZOrderMgr', 'printPSOrderXX', params)
  },
  WHMgrNew: {
    // 获取物料信息列表 for 平台方物流维护管理
    getMaterialList: (params) => request('WHMgr', 'getMaterialList', params),
    // 获取品牌列表用于检索数据 for 平台方物流维护管理
    getBrandList: (params) => request('WHMgr', 'getBrandList', params),
    // 获取品规名称列表用于检索数据 for 平台方物流维护管理
    getMatnameList: (params) => request('WHMgr', 'getMatnameList', params),
    // 获取规格型号用于检索数据 for 平台方物流维护管理
    getXhList: (params) => request('WHMgr', 'getXhList', params),
    // 获取计量类型列表
    getUnitList: (params) => request('WHMgr', 'getUnitList', params),
    // 获取加工类型列表
    getJgTypeList: (params) => request('WHMgr', 'getJgTypeList', params),
    // 添加物料信息 for 平台方物流维护管理
    addMaterialInfo: (params) => request('WHMgr', 'addMaterialInfo', params),
    // 修改物料信息 for 平台方物流维护管理
    editMaterialInfo: (params) => request('WHMgr', 'editMaterialInfo', params),
    // 删除物料信息 for 平台方物流维护管理
    delMaterialInfo4Admin: (params) => request('WHMgr', 'delMaterialInfo4Admin', params),
    // 获取仓储方物料申请列表 for 平台方视角
    getMaterApplyList4Plat: (params) => request('WHMgr', 'getMaterApplyList4Plat', params),
    // 审核通过仓储方添加物流申请信息 for 平台方物料维护
    chkPassMaterApplyInfo: (params) => request('WHMgr', 'chkPassMaterApplyInfo', params),
    // 驳回仓储方添加物流申请信息 for 平台方物流维护
    BHMaterApplyInfo: (params) => request('WHMgr', 'BHMaterApplyInfo', params),
    //
    // 查询物料信息 for 仓储方物料维护管理
    getMaterMgrList4WhMgr: (params) => request('WHMgr', 'getMaterMgrList4WhMgr', params),
    // 获取仓库列表用于引入物料 for 仓储方物料维护管理
    getWhList4WhMgr: (params) => request('WHMgr', 'getWhList4WhMgr', params),
    // 获取待引入物料列表 for 仓储方物料维护管理
    getunImportMaterialList: (params) => request('WHMgr', 'getunImportMaterialList', params),
    // 引入仓储物料数据 for 仓储方物料维护管理 post
    importMaterialInfo4WhMgr: (params) => request('WHMgr', 'importMaterialInfo4WhMgr', params),
    // 获取品牌列表用于检索数据 for 仓储方物流维护管理
    getBrandList4CCGJ: (params) => request('WHMgr', 'getBrandList4CCGJ', params),
    // 获取品规名称列表用于检索数据 for 默默通物流维护管理
    getMatnameList4CCGJ: (params) => request('WHMgr', 'getMatnameList4CCGJ', params),
    // 获取规格型号用于检索数据 for 默默通物流维护管理
    getXhList4CCGJ: (params) => request('WHMgr', 'getXhList4CCGJ', params),
    // 向仓储方申请添加物料数据 for 仓储方物料维护管理
    addMaterInfo4WHMgr: (params) => request('WHMgr', 'addMaterInfo4WHMgr', params),
    // 修改物料信息 for 仓储方物料维护管理
    editMaterialInfo4WhMgr: (params) => request('WHMgr', 'editMaterialInfo4WhMgr', params),
    // 删除仓储物料信息 for 仓储方物料维护管理
    delMaterialInfo4WhMgr: (params) => request('WHMgr', 'delMaterialInfo4WhMgr', params),
    // 获取仓储方物料申请列表 for 仓储方物料我的添加申请列表
    getMaterApplyList: (params) => request('WHMgr', 'getMaterApplyList', params),
    // 取回仓储方添加物流申请信息 for 仓储方物料我的添加申请列表
    getBackMaterApplyInfo: (params) => request('WHMgr', 'getBackMaterApplyInfo', params),
    // 获取入库列表表头
    getRKTabHead: (params) => request('WHMgr', 'getRKTabHead', params),
    // 获取出库列表表头
    getCKTabHead: (params) => request('WHMgr', 'getCKTabHead', params),
    // 获取入库列表
    getRKDDList: (params) => request('WHMgr', 'getRKDDList', params),
    // 获取出库列表
    getCKDDList: (params) => request('WHMgr', 'getCKDDList', params),
    // 获取钢之梦入库审批记录
    getRKDJLogsFromGZM: (params) => request('WHMgr', 'getRKDJLogsFromGZM', params),
    // 获取钢之梦出库审批记录
    getCKDJLogsFromGZM: (params) => request('WHMgr', 'getCKDJLogsFromGZM', params),
    // 获取默默通入库审批记录
    getRKDJLogs: (params) => request('WHMgr', 'getRKDJLogs', params),
    // 获取默默通出库审批记录
    getCKDJLogs: (params) => request('WHMgr', 'getCKDJLogs', params)
  },
  BiddingMgrNew: {
    // 获取报价管理表头 For ALL
    getTabHead: (params) => request('BiddingMgr', 'getTabHead', params),
    // 检索报价管理列表（主要用于物流公司） For PC
    getBiddingList4WLSJ4PC: (params) => request('BiddingMgr', 'getBiddingList4WLSJ4PC', params),
    // 修改报价信息
    modTheQueryBJXX: (params) => request('BiddingMgr', 'modTheQueryBJXX', params)
  },
  WHMgrList: {
    // 获取排队规则列表
    getQueueRuleList: (params) => request('WHMgr', 'getQueueRuleList', params),
    // 获取未关联规则列表和已关联规则列表
    getRuleList: (params) => request('WHMgr', 'getRuleList', params),
    // 添加规则
    addWhnameRule: (params) => request('WHMgr', 'addWhnameRule', params)
  },
  DZOrderMgrlist: {
    // 获取未对账单据列表 FOR 运营方 1
    getUnDZOrderList4YYF4PC: (params) => request('DZOrderMgr', 'getUnDZOrderList4YYF4PC', params),
    // 获取未对账单据列表 FOR 物流公司 PC 1
    getUnDZOrderList4WLSJ4PC: (params) => request('DZOrderMgr', 'getUnDZOrderList4WLSJ4PC', params),
    // 获取对账单据列表 FOR 物流公司 PC 1
    getDZOrderList4WLSJ4PC: (params) => request('DZOrderMgr', 'getDZOrderList4WLSJ4PC', params),
    // 批量发起对账 3
    multSendDZOrders: (params) => request('DZOrderMgr', 'multSendDZOrders', params),
    // 获取对账原始单据详情 4
    getYSDZOrderXX: (params) => request('DZOrderMgr', 'getYSDZOrderXX', params),
    // 获取对账单据详情 5
    getDZOrderXX4PC: (params) => request('DZOrderMgr', 'getDZOrderXX4PC', params),
    // 发起运单对账时所用到的初始化函数
    getSendDZYDInitXX: (params) => request('DZOrderMgr', 'getSendDZYDInitXX', params),
    // 默默通运营方创建对账单
    createDZOrderByForm4YYF: (params) => request('DZOrderMgr', 'createDZOrderByForm4YYF', params),
    // 一键取回对账单 6
    getBackDZOrder: (params) => request('DZOrderMgr', 'getBackDZOrder', params),
    // 撤销审核对账单 9
    cxDZOrder: (params) => request('DZOrderMgr', 'cxDZOrder', params),
    // 发起者重新提交对账 11
    reTjDZOrder: (params) => request('DZOrderMgr', 'reTjDZOrder', params),
    // 确认提交对账 13
    qrSendDZOrder: (params) => request('DZOrderMgr', 'qrSendDZOrder', params),
    // 获取对账原始单据列表（用于发起、重新提交和编辑时增加原始单据） FOR 运营方PC 12
    getPCAddYSDZOrderList4YYF: (params) => request('DZOrderMgr', 'getPCAddYSDZOrderList4YYF', params),
    // 获取对账原始单据列表（用于发起、重新提交和编辑时增加原始单据） FOR 物流 3
    getPCAddYSDZOrderList4WLSJ: (params) => request('DZOrderMgr', 'getPCAddYSDZOrderList4WLSJ', params),
    // 获取未对账单据列表 FOR 货主 1
    getUnDZOrderList4HZDJ4PC: (params) => request('DZOrderMgr', 'getUnDZOrderList4HZDJ4PC', params),
    // 获取对账单据列表 FOR 货主 PC 2
    getDZOrderList4HZDJ4PC: (params) => request('DZOrderMgr', 'getDZOrderList4HZDJ4PC', params),
    // 退回上一环节 10
    backPreDZOrder: (params) => request('DZOrderMgr', 'backPreDZOrder', params),
    // 获取对账单据列表 FOR 运营方 PC 2
    getDZOrderList4YYF4PC: (params) => request('DZOrderMgr', 'getDZOrderList4YYF4PC', params),
    // 审核通过对账单 7
    agreeDZOrder: (params) => request('DZOrderMgr', 'agreeDZOrder', params),
    // 驳回对账单 8
    bohuiDZOrder: (params) => request('DZOrderMgr', 'bohuiDZOrder', params),
    // 获取对账单详情
    getDZOrderDetails: (params) => request('DZOrderMgr', 'getDZOrderDetails', params),
    // 批量审核通过对账单
    multagreeDZOrder: (params) => request('DZOrderMgr', 'multagreeDZOrder', params),
    // 批量驳回对账单
    multbohuiDZOrder: (params) => request('DZOrderMgr', 'multbohuiDZOrder', params),
    // 导出未对账单据列表 FOR 运营方 PC
    exportUnDZOrderList4YYF4PC: (params) => request('DZOrderMgr', 'exportUnDZOrderList4YYF4PC', params),
    // 导出对账单据列表 FOR 运营方 PC
    exportDZOrderList4YYF4PC: (params) => request('DZOrderMgr', 'exportDZOrderList4YYF4PC', params),
    // 导出未对账单据列表 FOR 货主 PC
    exportUnDZOrderList4HZDJ4PC: (params) => request('DZOrderMgr', 'exportUnDZOrderList4HZDJ4PC', params),
    // 导出对账单据列表 FOR 货主 PC
    exportDZOrderList4HZDJ4PC: (params) => request('DZOrderMgr', 'exportDZOrderList4HZDJ4PC', params),
    // 导出未对账单据列表 FOR 物流公司 PC
    exportUnDZOrderList4WLSJ4PC: (params) => request('DZOrderMgr', 'exportUnDZOrderList4WLSJ4PC', params),
    // 导出对账单据列表 FOR 物流公司 PC
    exportDZOrderList4WLSJ4PC: (params) => request('DZOrderMgr', 'exportDZOrderList4WLSJ4PC', params),
    // 获取运杂费申请流转详情
    getHisOperList: (params) => request('DZOrderMgr', 'getHisOperList', params)
  },
  YDAccMgrlist: {
    // 获取运单台帐列表（主要用于运营方） For 运营方
    getYDAccList4YYF4PC: (params) => request('YDAccMgr', 'getYDAccList4YYF4PC', params),
    // 获取运单台帐列表（主要用于运营方） For 运营方
    getYDAccList4WLSJ4PC: (params) => request('YDAccMgr', 'getYDAccList4WLSJ4PC', params),
    // 获取运单台帐列表（主要用于运营方） For 运营方
    getYDAccList4HZDJ4PC: (params) => request('YDAccMgr', 'getYDAccList4HZDJ4PC', params),
    // 获取运单台帐表头 For 物流世家+货主帝家+默默通运营方
    getYDAccHeadFields: (params) => request('YDAccMgr', 'getYDAccHeadFields', params),
    // 修改托运方1信息 For 运营方
    modTypartXXOneByYDAccIDS4New: (params) => request('YDAccMgr', 'modTypartXXOneByYDAccIDS4New', params),
    // 修改托运方2信息 For 运营方
    modTypartXXTwoByYDAccIDS4New: (params) => request('YDAccMgr', 'modTypartXXTwoByYDAccIDS4New', params),
    // 修改对账原始单据、运单台帐、运单和货源订单中的货运信息，由网络货运转普通货运（按事务机制处理，新功能）
    changeTheNetOrderToUsualByYDAccIDS: (params) => request('YDAccMgr', 'changeTheNetOrderToUsualByYDAccIDS', params),
    // 交换对账原始单据、运单台帐、运单和货源订单中的托运方1和托运方2信息（按事务机制处理，新功能）
    swapTypartXXOneAndTwoByYDAccIDS: (params) => request('YDAccMgr', 'swapTypartXXOneAndTwoByYDAccIDS', params),
    // 获取订单明细列表表头及其它配套系数 For 物流世家+货主帝家+默默通运营方
    getYDAccDetailHeadFields: (params) => request('YDAccMgr', 'getYDAccDetailHeadFields', params),
    // 获取运单台帐列表（主要用于运营方） For 运营方
    getYDAccDetailList4YYF4PC: (params) => request('YDAccMgr', 'getYDAccDetailList4YYF4PC', params),
    // 获取运单明细台帐列表（主要用于物流世家） For 物流世家
    getYDAccDetailList4WLSJ4PC: (params) => request('YDAccMgr', 'getYDAccDetailList4WLSJ4PC', params),
    // 获取运单台帐列表（主要用于货主帝家） For 货主帝家
    getYDAccDetailList4HZDJ4PC: (params) => request('YDAccMgr', 'getYDAccDetailList4HZDJ4PC', params)
  },
  DZJsMgrlist: {
    // 修改对账单、对账原始单据、运单台帐、运单和货源订单中的托运方1信息（按事务机制处理，新功能
    modTypartXXOneByDZOrderIDS4New: (params) => request('DZJsMgr', 'modTypartXXOneByDZOrderIDS4New', params),
    // 修改对账单、对账原始单据、运单台帐、运单和货源订单中的托运方2信息（按事务机制处理，新功能）
    modTypartXXTwoByDZOrderIDS4New: (params) => request('DZJsMgr', 'modTypartXXTwoByDZOrderIDS4New', params),
    // 查看对账原始单据审批备注 For 运营方+货主+物流公司
    getBeizhuByYSDJID: (params) => request('DZJsMgr', 'getBeizhuByYSDJID', params),
    // 获取参与对账的原始单据列表表头 For 物流世家+司机之家+默默通运营方+货主帝家
    getYSDJTabHead4SendDZ: (params) => request('DZJsMgr', 'getYSDJTabHead4SendDZ', params),
    // 获取未对账的原始单据列表（主要用于物流世家发起对账用） For 物流世家
    getYSDJList4SendDZ4WLSJ: (params) => request('DZJsMgr', 'getYSDJList4SendDZ4WLSJ', params),
    // 批量发起对账 FOR 物流世家
    multSendDZOrders4WLSJ: (params) => request('DZJsMgr', 'multSendDZOrders4WLSJ', params),
    // 取状态类型及对应的操作按钮 For 物流世家+司机之家+货主帝家+默默通运营方
    getDZInitXX4Check4PC: (params) => request('DZJsMgr', 'getDZInitXX4Check4PC', params),
    // 取状态类型及对应的操作按钮 For 物流世家+司机之家+货主帝家+默默通运营方
    getDZOrderList4WLSJ4PC: (params) => request('DZJsMgr', 'getDZOrderList4WLSJ4PC', params),
    // 一键取回对账单 FOR 承运方
    getBackDZOrder: (params) => request('DZJsMgr', 'getBackDZOrder', params),
    // 取回审核 FOR 承运方、货主或默默通运营方
    getBackCheckDZOrder: (params) => request('DZJsMgr', 'getBackCheckDZOrder', params),
    // 取消对账单 FOR 承运方
    cancelDZOrder: (params) => request('DZJsMgr', 'cancelDZOrder', params),
    // 确认发起对账单（对于自动生成的单据） FOR 物流世家
    qrSendDZOrder4WLSJ: (params) => request('DZJsMgr', 'qrSendDZOrder4WLSJ', params),
    // 获取对账单据详情 FOR PC
    getDZOrderXX4PC: (params) => request('DZJsMgr', 'getDZOrderXX4PC', params),
    // 获取对账明细列表表头及其它配套系数
    getTabHead4DZDetails: (params) => request('DZJsMgr', 'getTabHead4DZDetails', params),
    // 审核通过对账单 FOR 货主、承运方和默默通运营方
    agreeDZOrder: (params) => request('DZJsMgr', 'agreeDZOrder', params),
    // 获取参与对账的原始单据列表表头（向对账单中添加原始单据使用） For 物流世家+司机之家+默默通运营方+货主帝家
    getYSDJTabHead4AddInDZOrder: (params) => request('DZJsMgr', 'getYSDJTabHead4AddInDZOrder', params),
    // 获取参与对账的原始单据列表表头（向对账单中添加原始单据使用） For 物流世家+司机之家+默默通运营方+货主帝家（PC使用）
    getYSDJList4AddInDZOrder4PC: (params) => request('DZJsMgr', 'getYSDJList4AddInDZOrder4PC', params),
    // 获取对账管理列表 For 货主帝家（PC使用）
    getDZOrderList4HZDJ4PC: (params) => request('DZJsMgr', 'getDZOrderList4HZDJ4PC', params),
    // 将对账单退回运营方 FOR 货主
    returnDZOrder2YYF4HZDJ: (params) => request('DZJsMgr', 'returnDZOrder2YYF4HZDJ', params),
    // 获取对账管理列表 For 运营方（PC使用）
    getDZOrderList4YYF4PC: (params) => request('DZJsMgr', 'getDZOrderList4YYF4PC', params),
    // 将对账单退回承运方 FOR 运营方
    returnDZOrder2CYF: (params) => request('DZJsMgr', 'returnDZOrder2CYF', params),
    // 将对账单退回上一级
    returnDZOrderPreStep: (params) => request('DZJsMgr', 'returnDZOrderPreStep', params),
    // 获取对账单据审批流转详情
    getDZOrderCheckLogs: (params) => request('DZJsMgr', 'getDZOrderCheckLogs', params),
    // 获取对账单据中特定对账原始单修改日志
    getYSDJMondifyLogs: (params) => request('DZJsMgr', 'getYSDJMondifyLogs', params),
    // 获取对账单资金分配初始化信息 For 物流公司
    getDZOrderFPInitXX: (params) => request('DZJsMgr', 'getDZOrderFPInitXX', params),
    // 确认分配并自动生成收款申请单 For 物流公司
    qrDZOrderFPAndGenerateSKApp: (params) => request('DZJsMgr', 'qrDZOrderFPAndGenerateSKApp', params),
    // 获取对账单资金分配信息，用于查看资金分配情况 For 物流公司
    getDZOrderFPDetails: (params) => request('DZJsMgr', 'getDZOrderFPDetails', params),
    // 修改托运方1信息 For 运营方
    modTypartXXByDZOrderID: (params) => request('DZJsMgr', 'modTypartXXByDZOrderID', params),
    // 修改托运方2信息 For 运营方
    modTypartXXTwoByDZOrderID: (params) => request('DZJsMgr', 'modTypartXXTwoByDZOrderID', params)
  },
  DZCfgMgrlist: {
    // 获取待引入的对账单位信息 FOR
    getdzCompList: (params) => request('DZCfgMgr', 'getdzCompList', params),
    // 导入资金信息
    importtrancomp: (params) => request('DZCfgMgr', 'importtrancomp', params),
    // 获取对账配置列表
    getDZCfgList: (params) => request('DZCfgMgr', 'getDZCfgList', params),
    // 编辑
    editdzcfgInfo: (params) => request('DZCfgMgr', 'editdzcfgInfo', params),
    // 删除
    deldzcfgInfo: (params) => request('DZCfgMgr', 'deldzcfgInfo', params)
  },
  CoreMember: {
    // 获取默默通司机之家后台首页相关初始化信息
    getMemberIndexData4SJZJ: (params) => request('Member', 'getMemberIndexData4SJZJ', params),
    // 获取默默通家家协同后台首页相关初始化信息
    getMemberIndexData4JJXT: (params) => request('Member', 'getMemberIndexData4JJXT', params),
    // 获取默默通物流公司后台首页相关初始化信息
    getMemberIndexData4WLSJ: (params) => request('Member', 'getMemberIndexData4WLSJ', params),
    // 默默通物流公司后台首页-切换网络货运情况（$midx=1）和普通货运情况（$midx=2）
    getMemberTranData4WLSJ: (params) => request('Member', 'getMemberTranData4WLSJ', params),
    // 获取默默通货主后台首页相关初始化信息
    getMemberIndexData4HZDJ: (params) => request('Member', 'getMemberIndexData4HZDJ', params),
    // 默默通货主后台首页-切换网络货运情况（$midx=1）和普通货运情况（$midx=2）
    getMemberTranData4HZDJ: (params) => request('Member', 'getMemberTranData4HZDJ', params),
    // 获取默默通运营方后台首页相关初始化信息
    getMemberIndexData4YYF: (params) => request('Member', 'getMemberIndexData4YYF', params),
    // 默默通运营方后台首页-切换司机交易情况（$midx=1）和货主交易情况（$midx=2）
    getMemberTranData4YYF: (params) => request('Member', 'getMemberTranData4YYF', params),
    // 默默通运营方后台首页-切换最近七天货源发布情况（$midx=1）和车源发布情况（$midx=2）
    getMemberSrcPubData4YYF: (params) => request('Member', 'getMemberSrcPubData4YYF', params),
    // 获取默默通平台方后台首页相关初始化信息
    getMemberIndexData4PTF: (params) => request('Member', 'getMemberIndexData4PTF', params),
    // 默默通平台方后台首页-切换网络货运情况（$midx=1）和普通货运情况（$midx=2）
    getMemberTranData4PTF: (params) => request('Member', 'getMemberTranData4PTF', params),
    // 默默通平台方后台首页-切换最近90天货源发布情况（$midx=1）和车源发布情况（$midx=2）
    getMemberSrcPubData4PTF: (params) => request('Member', 'getMemberSrcPubData4PTF', params)
  },
  CollectTKgd: {
    // 获取我的车源收藏列表
    getCollectTKSrcList: (params) => request('FreeTruckCenter', 'getCollectTKSrcList', params),
    // 获取我的货源收藏列表
    getGoodCollectList: (params) => request('GDXXPubCenter', 'getGoodCollectList', params)
  },
  CcgjHomeInit: {
    // 初始化获取仓储板块个人中心信息
    getHomeInitXX: (params) => request('WHMgr', 'getHomeInitXX', params),
    // 获取个人中心底部统计数据 FOR 仓储管家
    getRealInvenTjDataForCCGJ: (params) => request('WHMgr', 'getRealInvenTjDataForCCGJ', params),
    // 获取仓库列表 for 仓库管理
    getWhnameList: (params) => request('WHMgr', 'getWhnameList', params)
  },
  CcgjAPI: {
    // 上添加api对接信息
    addWldwApiMgrInfo: (params) => request('WHMgr', 'addWldwApiMgrInfo', params),
    // 上编辑
    editWldwApiMgrInfo: (params) => request('WHMgr', 'editWldwApiMgrInfo', params),
    // 上删除
    delWldwApiMgrInfo: (params) => request('WHMgr', 'delWldwApiMgrInfo', params),
    // 获取API数据对接上列表
    getWldwApiMgrList: (params) => request('WHMgr', 'getWldwApiMgrList', params),
    // 下添加
    addWldwApiCfgInfo: (params) => request('WHMgr', 'addWldwApiCfgInfo', params),
    // 下编辑
    editWldwApiCfgInfo: (params) => request('WHMgr', 'editWldwApiCfgInfo', params),
    // 下删除
    delWldwApiCfgInfo: (params) => request('WHMgr', 'delWldwApiCfgInfo', params),
    // 获取API数据对接下列表
    getWldwApiCfgList: (params) => request('WHMgr', 'getWldwApiCfgList', params),
    // 获取推送详情列表
    getApiPushList: (params) => request('WHMgr', 'getApiPushList', params)
  },
  cardList: {
    // 获取身份证列表
    getIdCardList: (params) => request('CertificateMgr', 'getIdCardList', params),
    // 修改身份证信息
    modIDCardXX: (params) => request('CertificateMgr', 'modIDCardXX', params),
    // 获取营业执照列表
    getBusinessList: (params) => request('CertificateMgr', 'getBusinessList', params),
    // 修改营业执照信息
    modBusLicenseXX: (params) => request('CertificateMgr', 'modBusLicenseXX', params),
    // 获取驾驶证列表
    getDriverList: (params) => request('CertificateMgr', 'getDriverList', params),
    // 修改驾驶证信息
    modDriverCardXX: (params) => request('CertificateMgr', 'modDriverCardXX', params),
    // 获取从业资格证列表
    getDriverQualifyList: (params) => request('CertificateMgr', 'getDriverQualifyList', params),
    // 修改从业资格证信息
    modDriverCYZGZXX: (params) => request('CertificateMgr', 'modDriverCYZGZXX', params),
    // 获取行驶证列表
    getVehicleList: (params) => request('CertificateMgr', 'getVehicleList', params),
    // 获取行驶证列表
    getRoadList: (params) => request('CertificateMgr', 'getRoadList', params),
    // 获取详情信息
    getHisOtherInfo: (params) => request('CertificateMgr', 'getHisOtherInfo', params),
    // 获取道路运输经营许可证证列表
    getTranJYXKZList: (params) => request('CertificateMgr', 'getTranJYXKZList', params),
    // 检验单一类型证件
    multCheckSingleTypeCertify: (params) => request('CertificateMgr', 'multCheckSingleTypeCertify', params),
    // 检验单个证件
    singleCheckCertify: (params) => request('CertificateMgr', 'singleCheckCertify', params),
    // 获取证件列表前的初始化信息
    getCertifyInitXX: () => request('CertificateMgr', 'getCertifyInitXX'),
    // 删除从业资格证记录
    delDriverCYZGZRecord: (params) => request('CertificateMgr', 'delDriverCYZGZRecord', params),
    // 新增从业资格证记录
    addDriverCYZGZRecord: (params) => request('CertificateMgr', 'addDriverCYZGZRecord', params),
    // 更新从业资格证记录
    modDriverCYZGZRecord: (params) => request('CertificateMgr', 'modDriverCYZGZRecord', params),
    // 修改从业资格证基本信息
    modDriverCYZGZBaseXX: (params) => request('CertificateMgr', 'modDriverCYZGZBaseXX', params),
    // 新增从业资格证照片
    addDriverCYZGZPhoto: (params) => request('CertificateMgr', 'addDriverCYZGZPhoto', params),
    // 删除从业资格证照片
    delDriverCYZGZPhoto: (params) => request('CertificateMgr', 'delDriverCYZGZPhoto', params)
  },
  driverList: {
    // 获取申请状态列表
    getRZAppTypeList: (params) => request('RzUserMgrV1', 'getRZAppTypeList', params),
    // 运营方视角获取司机管理列表
    getDriverRZMgr4PC: (params) => request('RzUserMgrV1', 'getDriverRZMgr4PC', params),
    // 审核认证申请信息
    // chkRzApp: (params) => request('RzUserMgr', 'chkRzApp', params),
    // 审核资料变更申请信息
    // chkModRzApp: (params) => request('RzUserMgr', 'chkModRzApp', params),
    // 撤销认证
    cxRzApp4YYF: (params) => request('RzUserMgrV1', 'cxRzApp4YYF', params),
    // 查看认证审批流转详情
    getRZOperList: (params) => request('RzUserMgrV1', 'getRZOperList', params),
    // 通过申请信息ID查看认证登记信息
    getRzXXByAppid: (params) => request('RzUserMgrV1', 'getRzXXByAppid', params),
    // 根据登记信息ID查看认证登记信息
    getRzXXByRegxxid: (params) => request('RzUserMgrV1', 'getRzXXByRegxxid', params),
    // 重新推送司机信息
    addDriver2CJList: (params) => request('RzUserMgrV1', 'addDriver2CJList', params),
    // 审核司机发起认证申请信息
    agreeSendSJZJRZAppXX: (params) => request('RzUserMgrV1', 'agreeSendSJZJRZAppXX', params),
    // 审核司机变更认证申请信息
    agreeChgSJZJRZAppXX: (params) => request('RzUserMgrV1', 'agreeChgSJZJRZAppXX', params),
    // 更新司机认证台账信息
    editSJZJRZXX: (params) => request('RzUserMgrV1', 'editSJZJRZXX', params),
    // 审核物流公司发起认证申请信息
    agreeSendWLSJRZAppXX: (params) => request('RzUserMgrV1', 'agreeSendWLSJRZAppXX', params),
    // 审核物流公司变更认证申请信息
    agreeChgWLSJRZAppXX: (params) => request('RzUserMgrV1', 'agreeChgWLSJRZAppXX', params),
    // 编辑物流公司已认证信息
    editWLSJRZXX: (params) => request('RzUserMgrV1', 'editWLSJRZXX', params),
    // 审核仓储管家发起认证申请信息
    agreeSendCCGJRZAppXX: (params) => request('RzUserMgrV1', 'agreeSendCCGJRZAppXX', params),
    // 审核仓储管家变更认证申请信息
    agreeChgCCGJRZAppXX: (params) => request('RzUserMgrV1', 'agreeChgCCGJRZAppXX', params),
    // 编辑仓储管家已认证信息
    editCCGJRZXX: (params) => request('RzUserMgrV1', 'editCCGJRZXX', params),
    // 审核货主帝家（企业）发起认证申请信息
    agreeSendHZDJRZAppXX4QY: (params) => request('RzUserMgrV1', 'agreeSendHZDJRZAppXX4QY', params),
    // 审核货主帝家（企业）变更认证申请信息
    agreeChgHZDJRZAppXX4QY: (params) => request('RzUserMgrV1', 'agreeChgHZDJRZAppXX4QY', params),
    // 编辑货主帝家（企业）认证台账信息
    editHZDJRZXX4QY: (params) => request('RzUserMgrV1', 'editHZDJRZXX4QY', params),
    // 审核货主帝家（个人）发起认证申请信息
    agreeSendHZDJRZAppXX4Person: (params) => request('RzUserMgrV1', 'agreeSendHZDJRZAppXX4Person', params),
    // 审核货主帝家（个人）变更认证申请信息
    agreeChgHZDJRZAppXX4Person: (params) => request('RzUserMgrV1', 'agreeChgHZDJRZAppXX4Person', params),
    // 编辑货主帝家（个人）已认证台账信息
    editHZDJRZXX4Person: (params) => request('RzUserMgrV1', 'editHZDJRZXX4Person', params),
    // 驳回认证申请信息
    BHRZApp4YYF: (params) => request('RzUserMgrV1', 'BHRZApp4YYF', params),
    // 驳回资料变更申请信息
    BHRZChangeApp4YYF: (params) => request('RzUserMgrV1', 'BHRZChangeApp4YYF', params)
  },
  shipperList: {
    // 获取申请状态列表
    getRZAppTypeList: (params) => request('RzUserMgrV1', 'getRZAppTypeList', params),
    // 运营方视角获取货主个体户管理
    getPersonGDOwnerRZMgr4PC: (params) => request('RzUserMgrV1', 'getPersonGDOwnerRZMgr4PC', params),
    // 运营方视角获取货主企业用户管理
    getCompGDOwnerRZMgr4PC: (params) => request('RzUserMgrV1', 'getCompGDOwnerRZMgr4PC', params),
    // 审核认证申请信息
    // chkRzApp: (params) => request('RzUserMgr', 'chkRzApp', params),
    // 审核资料变更申请信息
    // chkModRzApp: (params) => request('RzUserMgr', 'chkModRzApp', params),
    // 撤销认证
    cxRzApp4YYF: (params) => request('RzUserMgrV1', 'cxRzApp4YYF', params),
    // 查看认证审批流转详情
    getRZOperList: (params) => request('RzUserMgrV1', 'getRZOperList', params),
    // 通过申请信息ID查看认证登记信息
    getRzXXByAppid: (params) => request('RzUserMgrV1', 'getRzXXByAppid', params),
    // 根据登记信息ID查看认证登记信息
    getRzXXByRegxxid: (params) => request('RzUserMgrV1', 'getRzXXByRegxxid', params),
    // 司机从业资格证验证
    driverQualify: (params) => request('Ziyun', 'driverQualify', params)
  },
  goodXXList: {
    // 货源发布初始化
    getInitXX: (params) => request('GDXXMgr', 'getInitXX', params),
    // 批量审核通过货源信息（运营方）
    multagreegdsrcxx: (params) => request('GDXXMgr', 'multagreegdsrcxx', params),
    // 批量驳回货源信息（运营方）
    multbohuigdsrcxx: (params) => request('GDXXMgr', 'multbohuigdsrcxx', params),
    // 获取货源计划初始化信息（运营方审核用）
    getGDSrcInitXX4YYF: (params) => request('GDXXMgr', 'getGDSrcInitXX4YYF', params),
    // 获取车辆信息
    getTruckTypes: (params) => request('TKXXMgr', 'getTruckTypes', params),
    // 获取司机信息
    getSJList: (params) => request('DriverMgr', 'getSJList', params),
    // 获取地址信息
    getAddrList: (params) => request('AddrMgr', 'getAddrList', params),
    // 通过所属城市+关键字获取推荐地址
    getSuggestAddrList: (params) => request('AddrMgr', 'getSuggestAddrList', params),
    // 添加装货/卸货地址
    addNewAddr: (params) => request('AddrMgr', 'addNewAddr', params),
    // 删除装货/卸货地址
    delAddrXX: (params) => request('AddrMgr', 'delAddrXX', params),
    // 判断是否有CA证书
    hasAppCACert: (params) => request('BestSign', 'hasAppCACert', params),
    // 获取申请CA证书相关信息
    regUser: (params) => request('BestSign', 'regUser', params),
    // 申请CA证书验证码校验
    sendCode4AppCert: (params) => request('BestSign', 'sendCode4AppCert', params),
    // 申请CA证书
    appUserCACert: (params) => request('BestSign', 'appUserCACert', params),
    // 获取签订协议所涉及初始化信息
    getInitXX4SignHT: (params) => request('GDXXMgr', 'getInitXX4SignHT', params),
    // 获取协议详情
    getWTContractXX: (params) => request('WTOrderMgr', 'getWTContractXX', params),
    // 发起并手动签署委托运输合同（货主）
    sendWTContract4HZDJ: (params) => request('BestSign', 'sendWTContract4HZDJ', params),
    // 发起并手动签署委托运输合同（运营方）
    sendWTContract4YYF: (params) => request('BestSign', 'sendWTContract4YYF', params),
    // 在线预览合同
    previewContract: (params) => request('BestSign', 'previewContract', params),
    // 获取合同签订状态
    getContractSignStatus: (params) => request('ContractMgr', 'getContractSignStatus', params),
    // 登记货源信息
    regGDSrcXX: (params) => request('GDXXMgr', 'regGDSrcXX', params),
    // 更新货源信息
    editGDSrcXX: (params) => request('GDXXMgr', 'editGDSrcXX', params),
    // 查询货源列表
    getgdsrclist: (params) => request('GDXXMgr', 'getgdsrclist', params),
    // 导出货源列表
    exportGDSrcList: (params) => request('GDXXMgr', 'exportGDSrcList', params),
    // 删除货源
    delgdsrcxx: (params) => request('GDXXMgr', 'delgdsrcxx', params),
    // 单条一键取回货源审核货源信息（运营方） For 默默通运营方
    onekeygetbackgdsrcxx: (params) => request('GDXXMgr', 'onekeygetbackgdsrcxx', params),
    // 确认发布货源
    qrsendgdsrcxx: (params) => request('GDXXMgr', 'qrsendgdsrcxx', params),
    // 取消发布货源
    cancelgdsrcxx: (params) => request('GDXXMgr', 'cancelgdsrcxx', params),
    // 立即结束发布货源
    endgdsrcxx: (params) => request('GDXXMgr', 'endgdsrcxx', params),
    // 再次发布货源
    resendgdsrcxx: (params) => request('GDXXMgr', 'resendgdsrcxx', params),
    // 复制并生成新货源
    copygdsrcxx: (params) => request('GDXXMgr', 'copygdsrcxx', params),
    // 审核通过货源信息（运营方）
    agreegdsrcxx: (params) => request('GDXXMgr', 'agreegdsrcxx', params),
    // 驳回货源信息（运营方）
    bohuigdsrcxx: (params) => request('GDXXMgr', 'bohuigdsrcxx', params),
    // 获取货源主表和明细表
    getgdsrcxx: (params) => request('GDXXMgr', 'getgdsrcxx', params),
    // 获取承运方列表
    getWLSJList: (params) => request('GDXXMgr', 'getWLSJList', params),
    // 获取货源列表表头 For 货主帝家+默默通运营方
    getHeadFields: (params) => request('GDXXMgr', 'getHeadFields', params),
    // 获取货源管理列表 For 货主帝家（PC使用）
    getGDSrcXXListForHZDJ4PC: (params) => request('GDXXMgr', 'getGDSrcXXListForHZDJ4PC', params),
    // 获取货源管理列表 For 默默通运营方（PC使用）
    getGDSrcXXListForYYF4PC: (params) => request('GDXXMgr', 'getGDSrcXXListForYYF4PC', params)
  },
  orderList: {
    // 查看委托订单列表 FOR 货主
    getWTOrderList4HZDJ: (params) => request('WTOrderMgr', 'getWTOrderList4HZDJ', params),
    // 查看委托订单列表 FOR 运营方
    getWTOrderList4YYF: (params) => request('WTOrderMgr', 'getWTOrderList4YYF', params),
    // 驳回委托订单 FOR 运营方
    bhWTOrder: (params) => request('WTOrderMgr', 'bhWTOrder', params),
    // 取消委托订单 FOR 货主
    cancelWTOrder: (params) => request('WTOrderMgr', 'cancelWTOrder', params),
    // 获取委托订单详情
    getWTOrderDetail: (params) => request('WTOrderMgr', 'getWTOrderDetail', params),
    // 初始化货源委托单的评价
    initWTOrderPJXX: (params) => request('CreditMgr', 'initWTOrderPJXX', params),
    // 获取对司机的评价标签列表 FOR 货主
    getPjSJZJTags: (params) => request('CreditMgr', 'getPjSJZJTags', params),
    // 货主对货源委托单所涉及的司机进行评价
    sendXypjXX4HZDJ: (params) => request('CreditMgr', 'sendXypjXX4HZDJ', params),
    // 获取委托订单的评价信息 FOR 货主
    getXypjList4WTOrder: (params) => request('CreditMgr', 'getXypjList4WTOrder', params)
  },
  eleArcXX: {
    // 上传图片信息
    uploadImgList: (params) => request('ElArcMgr', 'uploadImgList', params),
    // 上传其它文件信息
    uploadQTFileList: (params) => request('ElArcMgr', 'uploadQTFileList', params),
    // 获取上传的图片和其他文件信息
    getElArchiveList: (params) => request('ElArcMgr', 'getElArchiveList', params),
    // 删除电子存档中文件信息
    delElArchives: (params) => request('ElArcMgr', 'delElArchives', params),
    // 更新电子存档中文件信息
    saveElArchives: (params) => request('ElArcMgr', 'saveElArchives', params),
    // 上传认证申请和变更资料申请时驳回图片描述
    uploadRzChkBase64Img: (params) => request('ElArcMgr', 'uploadRzChkBase64Img', params),
    // 上传工地客户签收图片信息
    uploadCusSignBase64Img: (params) => request('ElArcMgr', 'uploadCusSignBase64Img', params),
    // 上传发货图片信息(专门上传64位图片)
    uploadFHOrderBase64Img: (params) => request('ElArcMgr', 'uploadFHOrderBase64Img', params),
    // 上传车队长开票信息(专门上传64位图片)
    uploadTKMayerBillBase64Img: (params) => request('ElArcMgr', 'uploadTKMayerBillBase64Img', params)
  },
  thirdOrderList: {
    // 获取订单列表  FOR 货主
    getOrderList4HZDJ: (params) => request('THDOrderMgr', 'getOrderList4HZDJ', params),
    // 取回或退回我的货源计划 For 货主、物流公司和运管中心
    cancelgdsrc: (params) => request('THDOrderMgr', 'cancelgdsrc', params),
    // 退回配送订单
    cancelorder: (params) => request('THDOrderMgr', 'cancelorder', params),
    // 删除货源信息
    delGDSrcXX: (params) => request('THDOrderMgr', 'delGDSrcXX', params),
    // 删除订单信息
    delOrderXX: (params) => request('THDOrderMgr', 'delOrderXX', params),
    // 释放车辆信息
    releaseTKLimit: (params) => request('THDOrderMgr', 'releaseTKLimit', params),
    // 获取订单列表  FOR 运管中心
    getOrderList4JJXT: (params) => request('THDOrderMgr', 'getOrderList4JJXT', params),
    // 获取订单列表  FOR 运输公司
    getOrderList4WLSJ4PC: (params) => request('THDOrderMgr', 'getOrderList4WLSJ4PC', params),
    // 获取单据详情 For 货主、物流公司和运管中心
    getOrderDetails: (params) => request('THDOrderMgr', 'getOrderDetails', params)
  },
  newThirdOrderList: {
    // 获取订单列表  FOR 运营方
    getOrderList4YYF: (params) => request('NewTHDOrderMgr', 'getOrderList4YYF', params),
    // 获取订单列表  FOR 运输公司 PC
    getOrderList4WLSJ4PC: (params) => request('NewTHDOrderMgr', 'getOrderList4WLSJ4PC', params),
    // 获取订单列表  FOR 货主
    getOrderList4HZDJ: (params) => request('NewTHDOrderMgr', 'getOrderList4HZDJ', params),
    // 获取订单列表  FOR 家家协同 PC
    getOrderList4JJXT4PC: (params) => request('NewTHDOrderMgr', 'getOrderList4JJXT4PC', params),
    // 获取流转详情列表
    getDJOperList: (params) => request('NewTHDOrderMgr', 'getDJOperList', params),
    // 释放车辆信息
    releaseTKLimit: (params) => request('NewTHDOrderMgr', 'releaseTKLimit', params),
    // 删除货源信息
    delGDSrcXX: (params) => request('NewTHDOrderMgr', 'delGDSrcXX', params),
    // 删除订单信息
    delOrderXX: (params) => request('NewTHDOrderMgr', 'delOrderXX', params),
    // 获取参与拼车的其他单据（主要用于派车环节）
    getPCOrderListForWLSJ: (params) => request('NewTHDOrderMgr', 'getPCOrderListForWLSJ', params),
    // 获取单据详情
    getOrderDetails: (params) => request('NewTHDOrderMgr', 'getOrderDetails', params),
    // 导出订单列表  FOR 运营方
    exportOrderList4YYF: (params) => request('NewTHDOrderMgr', 'exportOrderList4YYF', params),
    // 导出订单列表  FOR 运输公司 PC
    exportOrderList4WLSJ4PC: (params) => request('NewTHDOrderMgr', 'exportOrderList4WLSJ4PC', params),
    // 导出订单列表  FOR 货主
    exportOrderList4HZDJ: (params) => request('NewTHDOrderMgr', 'exportOrderList4HZDJ', params),
    // 主动提醒用户签收（司机或物流公司）
    smsToUserForSign: (params) => request('NewTHDOrderMgr', 'smsToUserForSign', params),
    // 主动提醒用户复核签收（货主）
    smsToUserForCheck: (params) => request('NewTHDOrderMgr', 'smsToUserForCheck', params),
    // 获取普通货运列表表头 For 货主帝家+物流世家+默默通运营方
    getHeadFields: (params) => request('NewTHDOrderMgr', 'getHeadFields', params),
    // 获取订单列表  FOR 运营方 PC
    getOrderList4YYF4PC: (params) => request('NewTHDOrderMgr', 'getOrderList4YYF4PC', params),
    // 获取订单列表  FOR 货主 PC
    getOrderList4HZDJ4PC: (params) => request('NewTHDOrderMgr', 'getOrderList4HZDJ4PC', params),
    // 获取我的订单列表  FOR 运营方 PC
    getMyOrderList4YYF4PC: (params) => request('NewTHDOrderMgr', 'getMyOrderList4YYF4PC', params),
    // 取回或退回我的货源计划 For 货主、物流公司和运管中心
    cancelgdsrc: (params) => request('NewTHDOrderMgr', 'cancelgdsrc', params)
  },
  jsOrderList: {
    // 获取结算管理模块中"待办理"或“已取消”列表  FOR 运营方 PC
    getPCDZOrderList4YYF: (params) => request('JSMgr', 'getPCDZOrderList4YYF', params),
    // 获取结算管理模块中"未结算"或"部分结算"或“已结算”列表  FOR 运营方 PC
    getPCYSOrderList4YYF: (params) => request('JSMgr', 'getPCYSOrderList4YYF', params),
    // 导出结算管理模块中"待办理"或"已取消"列表  FOR 运营方 PC
    exportPCDZOrderList4YYF: (params) => request('JSMgr', 'exportPCDZOrderList4YYF', params),
    // 导出结算管理模块中"未结算"或"部分结算"或"已结算"列表  FOR 运营方 PC
    exportPCYSOrderList4YYF: (params) => request('JSMgr', 'exportPCYSOrderList4YYF', params)
  },
  billUploadList: {
    // 上传工地签收图片信息
    uploadBillData: (params) => request('BillUploadMgr', 'uploadBillData', params),
    // 补充工地签收图片或发货图片信息
    addFHSignBills: (params) => request('BillUploadMgr', 'addFHSignBills', params),
    // 关闭上一年度运单（司机和物流公司视角）
    closePreYearPSOrder: (params) => request('BillUploadMgr', 'closePreYearPSOrder', params),
    // 关闭配送订单（含订单台账、配送订单、货源等）（按事务机制处理，新功能，待签收模块使用）
    closeThePSOrderAndGDSrcXXByIDS: (params) => request('BillUploadMgr', 'closeThePSOrderAndGDSrcXXByIDS', params),
    // 复核通过工地签收图片信息
    agreeSignBillUpload: (params) => request('BillUploadMgr', 'agreeSignBillUpload', params),
    // 默默通复核通过工地签收图片信息
    agreeSignBillUpload4YYF: (params) => request('BillUploadMgr', 'agreeSignBillUpload4YYF', params),
    // 撤销工地签收图片信息
    CXSignBillUpload: (params) => request('BillUploadMgr', 'CXSignBillUpload', params),
    // 驳回工地签收图片信息
    BHSignBillUpload: (params) => request('BillUploadMgr', 'BHSignBillUpload', params),
    // 取回工地签收图片信息
    getBackSignBillUpload: (params) => request('BillUploadMgr', 'getBackSignBillUpload', params),
    // 更新运杂费信息 FOR PC
    modYZFXX4PC: (params) => request('BillUploadMgr', 'modYZFXX4PC', params),
    // 获取发货单据详情
    getFHOrderDetails: (params) => request('BillUploadMgr', 'getFHOrderDetails', params),
    // 获取签收详情 For 货主帝家
    getSignOrderXX4Check: (params) => request('BillUploadMgr', 'getSignOrderXX4Check', params)
  },
  sendCarXX: {
    // 获取派单初始化信息  For  运管中心
    getPDInitXX4JJXT: (params) => request('TranMgr', 'getPDInitXX4JJXT', params),
    // 获取改派初始化信息  For  运管中心
    getGPInitXX4JJXT: (params) => request('TranMgr', 'getGPInitXX4JJXT', params),
    // 获取派车初始化信息  For  物流公司
    getPCInitXX4WLSJ: (params) => request('TranMgr', 'getPCInitXX4WLSJ', params),
    // 获取改派初始化信息  For  物流公司
    getGPInitXX4WLSJ: (params) => request('TranMgr', 'getGPInitXX4WLSJ', params),
    // 货源单据指派单位信息  For  运管中心
    setPDInfoForJJXT: (params) => request('TranMgr', 'setPDInfoForJJXT', params),
    // 货源单据指派司机和车辆信息  For  运管中心
    setPCInfoForJJXT: (params) => request('TranMgr', 'setPCInfoForJJXT', params),
    // 货源单据指派司机和车辆信息  For  物流公司
    setPCInfoForWLSJ: (params) => request('TranMgr', 'setPCInfoForWLSJ', params),
    // 批量给货源单据指派司机和车辆信息  For  物流公司
    multSetPCInfoForWLSJ: (params) => request('TranMgr', 'multSetPCInfoForWLSJ', params),
    // 配送订单改派司机和车辆信息  For  物流公司
    setGPPCInfoForWLSJ: (params) => request('TranMgr', 'setGPPCInfoForWLSJ', params),
    // 配送订单改派司机和车辆信息  For  运管中心
    setGPPCInfoForJJXT: (params) => request('TranMgr', 'setGPPCInfoForJJXT', params),
    // 配送订单改派单位信息  For  运管中心
    setGPPDInfoForJJXT: (params) => request('TranMgr', 'setGPPDInfoForJJXT', params),
    // 指派任务-关联车辆列表  For  普通货运
    getTruckList: (params) => request('OffTKXXMgr', 'getTruckList', params),
    // 登记普通货车信息  For  普通货运
    regCommonTruckXX: (params) => request('OffTKXXMgr', 'regCommonTruckXX', params),
    // 登记拖拉机信息  For  普通货运
    regTljTruckXX: (params) => request('OffTKXXMgr', 'regTljTruckXX', params),
    // 获取车辆列表  For  网络货运
    getPCTruckList4WLSJ: (params) => request('NetTKXXMgr', 'getPCTruckList4WLSJ', params),
    // 获取挂车列表
    getPCCarryTruckList4WLSJ: (params) => request('NetTKXXMgr', 'getPCCarryTruckList4WLSJ', params),
    // 获取参与拼车的其他单据  For  物流公司
    getPCOrderListForWLSJ: (params) => request('THDOrderMgr', 'getPCOrderListForWLSJ', params),
    // 车辆入网校验
    checkTruckExist: (params) => request('Ziyun', 'checkTruckExist', params),
    // 获取派单初始化信息 For  默默通运营方
    getPDInitXX4YYF: (params) => request('TranMgr', 'getPDInitXX4YYF', params),
    // 货源单据指派单位信息 FOR 默默通运营方
    setPDInfoForYYF: (params) => request('TranMgr', 'setPDInfoForYYF', params),
    // 获取派车初始化信息 For  默默通运营方
    getPCInitXX4YYF: (params) => request('TranMgr', 'getPCInitXX4YYF', params),
    // 货源单据指派司机和车辆信息 FOR 默默通运营方
    setPCInfoForYYF: (params) => request('TranMgr', 'setPCInfoForYYF', params),
    // 获取改派初始化信息 For  默默通运营方
    getGPInitXX4YYF: (params) => request('TranMgr', 'getGPInitXX4YYF', params),
    // 配送订单改派单位信息 FOR 默默通运营方
    setGPPDInfoForYYF: (params) => request('TranMgr', 'setGPPDInfoForYYF', params),
    // 查看已注册车辆列表 For 物流公司 （派车和改派） ALL
    getSRegTruckList4WLSJ: (params) => request('NetTKXXMgr', 'getSRegTruckList4WLSJ', params)
  },
  psOrderXX: {
    // 获取配送订单列表  For  运营方
    getPSOrderListForYYF: (params) => request('PSOrderMgr', 'getPSOrderListForYYF', params),
    // 获取配送订单列表  For  司机之家
    getPSOrderListForSJZJ: (params) => request('PSOrderMgr', 'getPSOrderListForSJZJ', params),
    // 获取签订协议所涉及初始化信息  For  司机之家
    getInitXX4SignHT: (params) => request('PSOrderMgr', 'getInitXX4SignHT', params),
    // 发起并手动签署配送合同  For  司机之家
    sendPSContract4SJZJ: (params) => request('BestSign', 'sendPSContract4SJZJ', params),
    // 发起并手动签署司机配送合同  For  运营方
    sendPSContract4YYF: (params) => request('BestSign', 'sendPSContract4YYF', params),
    // 查看车辆列表 For 司机视角
    getTruckList4SJZJ: (params) => request('NetTKXXMgr', 'getTruckList4SJZJ', params),
    // 初始化配送订单的评价
    initPSOrderPJXX: (params) => request('CreditMgr', 'initPSOrderPJXX', params),
    // 司机对配送订单所涉及的货主进行评价
    sendXypjXX4SJZJ: (params) => request('CreditMgr', 'sendXypjXX4SJZJ', params),
    // 获取对货主的评价标签列表  FOR  司机
    getPjHZDJTags: (params) => request('CreditMgr', 'getPjHZDJTags', params),
    // 获取配送订单的评价信息  FOR  司机
    getXypjList4PSOrder: (params) => request('CreditMgr', 'getXypjList4PSOrder', params),
    // 取消配送订单  FOR  司机
    cancelPSOrder: (params) => request('PSOrderMgr', 'cancelPSOrder', params),
    // 驳回配送订单  FOR  运营方
    bhPSOrder: (params) => request('PSOrderMgr', 'bhPSOrder', params),
    // 获取配送订单当前步骤详情
    getHisPSOperList: (params) => request('PSOrderMgr', 'getHisPSOperList', params),
    // 获取配送订单详情
    getPSOrderDetails: (params) => request('PSOrderMgr', 'getPSOrderDetails', params),
    // 获取协议详情
    getPSContractXX: (params) => request('PSOrderMgr', 'getPSContractXX', params),
    // 获取配送订单物流详情信息
    getHisDeliverPosList: (params) => request('PSOrderMgr', 'getHisDeliverPosList', params),
    // 获取司机轨迹明细列表
    getPSOrderList4SJMap: (params) => request('PSOrderMgr', 'getPSOrderList4SJMap', params),
    // 取司机轨迹明细列表
    getDriverMapMXList: (params) => request('PSOrderMgr', 'getDriverMapMXList', params),
    // 获取异常运单列表
    getPSOrderList4PushErr: (params) => request('PSOrderMgr', 'getPSOrderList4PushErr', params),
    // 获取网络货运列表表头 For 司机之家+默默通运营方
    getHeadFields: (params) => request('PSOrderMgr', 'getHeadFields', params),
    // 获取网络货运列表 For 默默通运营方（PC使用）
    getPSOrderListForYYF4PC: (params) => request('PSOrderMgr', 'getPSOrderListForYYF4PC', params)
  },
  syncDataXX: {
    // 获取初始化信息
    getInitXX: (params) => request('SyncMonitorMgr', 'getInitXX', params),
    // 获取采集列表数据
    getCJTaskList: (params) => request('SyncMonitorMgr', 'getCJTaskList', params),
    // 立即执行
    operTaskData: (params) => request('SyncMonitorMgr', 'operTaskData', params),
    // 删除采集信息
    delCJTaskInfo: (params) => request('SyncMonitorMgr', 'delCJTaskInfo', params),
    // 车辆信息列表
    getTruckList: (params) => request('SyncMonitorMgr', 'getTruckList', params),
    // 删除车辆信息
    delTruckInfo: (params) => request('SyncMonitorMgr', 'delTruckInfo', params),
    // 恢复车辆信息
    recoverTruckInfo: (params) => request('SyncMonitorMgr', 'recoverTruckInfo', params),
    // 车辆基本信息上传
    sendTruckXX: (params) => request('SyncMonitorMgr', 'sendTruckXX', params),
    // 获取车辆信息
    getTruckInfo: (params) => request('SyncMonitorMgr', 'getTruckInfo', params),
    // 司机信息列表
    getDriverList: (params) => request('SyncMonitorMgr', 'getDriverList', params),
    // 删除司机信息
    delDriverInfo: (params) => request('SyncMonitorMgr', 'delDriverInfo', params),
    // 恢复司机信息
    recoverDriverInfo: (params) => request('SyncMonitorMgr', 'recoverDriverInfo', params),
    // 同步司机信息
    sendDriverXX: (params) => request('SyncMonitorMgr', 'sendDriverXX', params),
    // 获取司机信息
    getDriverInfo: (params) => request('SyncMonitorMgr', 'getDriverInfo', params),
    // 运单信息列表
    getOrderList: (params) => request('SyncMonitorMgr', 'getOrderList', params),
    // 删除运单信息
    delOrderInfo: (params) => request('SyncMonitorMgr', 'delOrderInfo', params),
    // 恢复运单信息
    recoverOrderInfo: (params) => request('SyncMonitorMgr', 'recoverOrderInfo', params),
    // 电子运单上传
    sendOrderData: (params) => request('SyncMonitorMgr', 'sendOrderData', params),
    // 获取运单信息
    getOrderInfo: (params) => request('SyncMonitorMgr', 'getOrderInfo', params),
    // 资金信息列表
    getCapitalList: (params) => request('SyncMonitorMgr', 'getCapitalList', params),
    // excel导入
    importCapital: (params) => request('SyncMonitorMgr', 'importCapital', params),
    // 图片导入
    importFile: (params) => request('SyncMonitorMgr', 'importFile', params),
    // 挂车excel导入
    importYdgc: (params) => request('SyncMonitorMgr', 'importYdgc', params),
    // 运单挂车信息列表
    getYdgcList: (params) => request('SyncMonitorMgr', 'getYdgcList', params),
    // 列表推送
    sendOrderGCXX: (params) => request('SyncMonitorMgr', 'sendOrderGCXX', params),
    // 列表推送
    sendTrackXX4UnPush: (params) => request('SyncMonitorMgr', 'sendTrackXX4UnPush', params),
    // 同步
    syncTrackCJTask: (params) => request('SyncMonitorMgr', 'syncTrackCJTask', params),
    // 恢复运单挂车信息
    recoverYdgcInfo: (params) => request('SyncMonitorMgr', 'recoverYdgcInfo', params),
    // 导出Excel
    exportYdgcList: (params) => request('SyncMonitorMgr', 'exportYdgcList', params),
    // 列表删除
    delYdgcInfo: (params) => request('SyncMonitorMgr', 'delYdgcInfo', params),
    // 删除资金信息
    delCapitalInfo: (params) => request('SyncMonitorMgr', 'delCapitalInfo', params),
    // 恢复资金信息
    recoverCapitalInfo: (params) => request('SyncMonitorMgr', 'recoverCapitalInfo', params),
    // 资金流水上传
    sendCapital: (params) => request('SyncMonitorMgr', 'sendCapital', params),
    // 获取资金信息
    getCapitalInfo: (params) => request('SyncMonitorMgr', 'getCapitalInfo', params),
    // 文件信息列表
    getFileList: (params) => request('SyncMonitorMgr', 'getFileList', params),
    // 删除文件信息
    delFileInfo: (params) => request('SyncMonitorMgr', 'delFileInfo', params),
    // 恢复文件信息
    recoverFileInfo: (params) => request('SyncMonitorMgr', 'recoverFileInfo', params),
    // 同步文件信息
    sendFiles: (params) => request('SyncMonitorMgr', 'sendFiles', params),
    // 获取文件信息
    getFileInfo: (params) => request('SyncMonitorMgr', 'getFileInfo', params),
    // 轨迹信息列表
    getMapsList: (params) => request('SyncMonitorMgr', 'getMapsList', params),
    // 删除轨迹信息
    delMapsInfo: (params) => request('SyncMonitorMgr', 'delMapsInfo', params),
    // 列表删除轨迹信息
    delMapsInfo4UnPush: (params) => request('SyncMonitorMgr', 'delMapsInfo4UnPush', params),
    // 恢复轨迹信息
    recoverMapsInfo: (params) => request('SyncMonitorMgr', 'recoverMapsInfo', params),
    // 同步轨迹信息
    sendTrackXX: (params) => request('SyncMonitorMgr', 'sendTrackXX', params),
    // 获取轨迹信息
    getMapsInfo: (params) => request('SyncMonitorMgr', 'getMapsInfo', params),
    // 获取轨迹详情列表
    getUnPushInfo: (params) => request('SyncMonitorMgr', 'getUnPushInfo', params),
    // 开启定时推送数据
    onTimedPush: (params) => request('SyncMonitorMgr', 'onTimedPush', params),
    // 关闭定时推送数据
    offTimedPush: (params) => request('SyncMonitorMgr', 'offTimedPush', params),
    // 把异常订单再次加入到省公共服务平台的待推送列表
    rePushErrOrders: (params) => request('SyncMonitorMgr', 'rePushErrOrders', params),
    // 运单信息ID获取车辆轨迹和司机轨迹数据
    getTruckAndDriverMaps: (params) => request('SyncMonitorMgr', 'getTruckAndDriverMaps', params)
  },
  YFSettleXX: {
    // 获取运费申请单列表 FOR 物流世家
    getYFSettleAppList4WLSJ: (params) => request('YFSettleMgr', 'getYFSettleAppList4WLSJ', params),
    // 获取运费申请单列表 FOR 货主帝家
    getYFSettleAppList4HZDJ: (params) => request('YFSettleMgr', 'getYFSettleAppList4HZDJ', params),
    // 获取运费申请单列表 FOR 默默通运营方
    getYFSettleAppList4YYF: (params) => request('YFSettleMgr', 'getYFSettleAppList4YYF', params),
    // 根据运费申请单编号获取申请单详情
    getYFAppInfo: (params) => request('YFSettleMgr', 'getYFAppInfo', params),
    // 获取已勾选订单列表
    getYFSettleOrders4Seled: (params) => request('YFSettleMgr', 'getYFSettleOrders4Seled', params),
    // 获取未结算订单列表
    getYFSettleOrders4UNJS: (params) => request('YFSettleMgr', 'getYFSettleOrders4UNJS', params),
    // 获取未结算订单列表（主要用于托运方发起运费结算申请）
    getQTYFSettleOrders4UNJS: (params) => request('YFSettleMgr', 'getQTYFSettleOrders4UNJS', params),
    // 修改未勾选的订单运杂费（签收数量，运费单价、运费金额和杂费金额）
    modYFSettleOrders4UNJS: (params) => request('YFSettleMgr', 'modYFSettleOrders4UNJS', params),
    // 修改勾选的订单（签收数量，运费单价、运费金额和杂费金额）
    modYFSettleOrders4Seled: (params) => request('YFSettleMgr', 'modYFSettleOrders4Seled', params),
    // 查看结算数量、运费单价、杂费和运费金额调整历史
    getSignOrderModHistory: (params) => request('YFSettleMgr', 'getSignOrderModHistory', params),
    // 设置参与结算的订单（审核运费结算申请时用到，主要用于添加新的参与结算的单据）
    addYFSettleOrders: (params) => request('YFSettleMgr', 'addYFSettleOrders', params),
    // 删除已勾选的订单（审核运费结算申请时用到，主要用于删除已勾选的单据）
    delYFSettleOrders: (params) => request('YFSettleMgr', 'delYFSettleOrders', params),
    // 重新提交运费申请单（被驳回的单据）
    retjYFSettleApp: (params) => request('YFSettleMgr', 'retjYFSettleApp', params),
    // 重新提交其他承运人运费申请单（被驳回的单据）
    retjQTYFSettleApp: (params) => request('YFSettleMgr', 'retjQTYFSettleApp', params),
    // 根据条件获取银行卡信息
    getBankList: (params) => request('OffBankMgr', 'getBankList', params),
    // 添加银行卡信息
    addBank: (params) => request('OffBankMgr', 'addBank', params),
    // 一键取回运费申请单
    endYFSettleApp: (params) => request('YFSettleMgr', 'endYFSettleApp', params),
    // 运费申请单审核通过
    agreeYFSettleApp: (params) => request('YFSettleMgr', 'agreeYFSettleApp', params),
    // 驳回运费申请
    bohuiYFSettleApp: (params) => request('YFSettleMgr', 'bohuiYFSettleApp', params),
    // 获取运费结算申请初始化信息（承运方、运营方和托运方发起运费结算都需要调用）
    getYFAppCfgXX: (params) => request('YFSettleMgr', 'getYFAppCfgXX', params),
    // 获取运费结算申请托运单位列表（默认显示50条，其它未显示的通过查询获取）
    getTycompList4YFApp: (params) => request('YFSettleMgr', 'getTycompList4YFApp', params),
    // 获取运费结算申请托运人列表（默认显示50条，其它未显示的通过查询获取）
    getTysellerList4YFApp: (params) => request('YFSettleMgr', 'getTysellerList4YFApp', params),
    // 获取未结算订单列表（主要用于托运方发起运费结算申请）
    getSignOrderList4SendYFJS: (params) => request('YFSettleMgr', 'getSignOrderList4SendYFJS', params),
    // 获取未结算订单列表（主要用于货主发起运费结算申请）
    getQTSignOrderList4SendYFJS: (params) => request('YFSettleMgr', 'getQTSignOrderList4SendYFJS', params),
    // 设置参与结算的订单（发起运费结算申请时用到）
    setYFSettleOrders: (params) => request('YFSettleMgr', 'setYFSettleOrders', params),
    // 删除已勾选的订单（主要用于发起运费申请）
    delAllSeledSignOrders: (params) => request('YFSettleMgr', 'delAllSeledSignOrders', params),
    // 提交运费申请单（初次填报）
    tjYFSettleApp: (params) => request('YFSettleMgr', 'tjYFSettleApp', params),
    // 提交运费申请单（初次填报）（主要用于货主发起其他承运人的结算申请）
    tjQTYFSettleApp: (params) => request('YFSettleMgr', 'tjQTYFSettleApp', params),
    // 导出运费申请单列表 FOR 物流世家 EXCEL
    exportYFSettleAppList4WLSJ: (params) => request('YFSettleMgr', 'exportYFSettleAppList4WLSJ', params),
    // 导出运费申请单列表 FOR 货主帝家 EXCEL
    exportYFSettleAppList4HZDJ: (params) => request('YFSettleMgr', 'exportYFSettleAppList4HZDJ', params),
    // 导出运费申请单列表 FOR 运营方 EXCEL
    exportYFSettleAppList4YYF: (params) => request('YFSettleMgr', 'exportYFSettleAppList4YYF', params),
    // 查看运费结算申请单流转详情
    viewYFAppOperList: (params) => request('YFSettleMgr', 'viewYFAppOperList', params),
    // 获取已认证的运输公司列表 For ALL
    getTrancompList: (params) => request('YFSettleMgr', 'getTrancompList', params)
  },
  YZFAppXX: {
    // 发起运费结算申请初始化信息
    getInitXXSendApp: (params) => request('YZFAppMgr', 'getInitXXSendApp', params),
    // 获取结算方列表  FOR 物流公司和默默通运营方
    getJspartxxList: (params) => request('YZFAppMgr', 'getJspartxxList', params),
    // 获取指定结算方的经办人列表  FOR 物流公司和默默通运营方
    getYWSellerList: (params) => request('YZFAppMgr', 'getYWSellerList', params),
    // 获取指定货主的已对账的运单列表  FOR 运营方
    getDZOrderList4YYF: (params) => request('YZFAppMgr', 'getDZOrderList4YYF', params),
    // 提交运费申请单（初次填报）  FOR 默默通运营方
    tjYFSettleApp4YYF: (params) => request('YZFAppMgr', 'tjYFSettleApp4YYF', params),
    // 获取运杂费申请单据列表 FOR 运营方 PC
    getYZFAppList4YYF4PC: (params) => request('YZFAppMgr', 'getYZFAppList4YYF4PC', params),
    // 获取运杂费申请单详情
    getYZFOrderXX: (params) => request('YZFAppMgr', 'getYZFOrderXX', params),
    // 获取运杂费申请流转详情
    getHisOperList: (params) => request('YZFAppMgr', 'getHisOperList', params),
    // 一键取回运费申请单
    getBackYZFApp: (params) => request('YZFAppMgr', 'getBackYZFApp', params),
    // 审核通过运费申请单
    agreeYZFApp: (params) => request('YZFAppMgr', 'agreeYZFApp', params),
    // 驳回运杂费申请
    bohuiYZFApp: (params) => request('YZFAppMgr', 'bohuiYZFApp', params),
    // 撤销审核运杂费申请
    cxYZFApp: (params) => request('YZFAppMgr', 'cxYZFApp', params),
    // 重新提交运费申请单  FOR 默默通运营方
    retjYFSettleApp4YYF: (params) => request('YZFAppMgr', 'retjYFSettleApp4YYF', params),
    // 导出运杂费申请单据列表 FOR 运营方 PC
    exportYZFAppList4YYF4PC: (params) => request('YZFAppMgr', 'exportYZFAppList4YYF4PC', params)
  },
  YZFList: {
    // 获取付款列表表头 For 物流世家+默默通运营方
    getFKHeadFields: (params) => request('YZFMgr', 'getFKHeadFields', params),
    // 获取收款列表表头 For 货主帝家+默默通运营方
    getSKHeadFields: (params) => request('YZFMgr', 'getSKHeadFields', params),
    // 根据对账单获取结算信息 For 承运方、默默通运营方
    getJSXX4SendSKApp: (params) => request('YZFMgr', 'getJSXX4SendSKApp', params),
    // 上传发票图片信息(专门上传64位图片)
    uploadEBillBase64Img: (params) => request('ElArcMgr', 'uploadEBillBase64Img', params),
    // 生成承运方的收款申请单（用于发起申请） FOR 物流世家、默默通运营方
    sendSKApp: (params) => request('YZFMgr', 'sendSKApp', params),
    // 获取付款申请列表 For 默默通运营方（PC使用）
    getFKAppList4YYF4PC: (params) => request('YZFMgr', 'getFKAppList4YYF4PC', params),
    // 获取付款申请列表 For 货主帝家（PC使用）
    getFKAppList4HZDJ4PC: (params) => request('YZFMgr', 'getFKAppList4HZDJ4PC', params),
    // 获取收款申请列表 For 默默通运营方（PC使用）
    getSKAppList4YYF4PC: (params) => request('YZFMgr', 'getSKAppList4YYF4PC', params),
    // 获取收款申请列表 For 物流世家（PC使用）
    getSKAppList4WLSJ4PC: (params) => request('YZFMgr', 'getSKAppList4WLSJ4PC', params),
    // 审核通过付款申请 FOR 货主方、默默通运营方
    agreeFKApp: (params) => request('YZFMgr', 'agreeFKApp', params),
    // 付款申请退回发起方 FOR 货主方、默默通运营方
    returnFKApp2Send: (params) => request('YZFMgr', 'returnFKApp2Send', params),
    // 确认付款 FOR 货主帝家、默默通运营方
    finishFKApp: (params) => request('YZFMgr', 'finishFKApp', params),
    // 确认部分付款 FOR 默默通运营方
    finishPartFKApp4YYF: (params) => request('YZFMgr', 'finishPartFKApp4YYF', params),
    // 默默通运营方线下支付初始化接口 FOR默默通运营方
    getOffPayInitXX4YYF: (params) => request('YZFMgr', 'getOffPayInitXX4YYF', params),
    // 取回审核付款申请 FOR 货主帝家、默默通运营方
    getBackCheckFKApp: (params) => request('YZFMgr', 'getBackCheckFKApp', params),
    // 撤销已确认付款 FOR 默默通运营方
    cxFinishFKApp: (params) => request('YZFMgr', 'cxFinishFKApp', params),
    // 审核通过收款申请 FOR 承运方、默默通运营方
    agreeSKApp: (params) => request('YZFMgr', 'agreeSKApp', params),
    // 取消收款申请 FOR 承运方、默默通运营方
    cancelSKApp: (params) => request('YZFMgr', 'cancelSKApp', params),
    // 取回审核收款申请 FOR 承运方、默默通运营方
    getBackCheckSKApp: (params) => request('YZFMgr', 'getBackCheckSKApp', params),
    // 一键取回收款申请 FOR 承运方或默默通运营方
    getBackSKApp: (params) => request('YZFMgr', 'getBackSKApp', params),
    // 确认发起收款申请单 FOR 物流公司、默默通运营方
    qrSendSKApp: (params) => request('YZFMgr', 'qrSendSKApp', params),
    // 申请发起者更新发票信息 FOR 物流公司和默默通
    modifySKAppFPXX: (params) => request('YZFMgr', 'modifySKAppFPXX', params),
    // 获取收款申请详情信息 FOR 承运方、默默通运营方
    getSKAppDetails: (params) => request('YZFMgr', 'getSKAppDetails', params),
    // 获取付款申请详情信息 FOR 托运方、默默通运营方
    getFKAppDetails: (params) => request('YZFMgr', 'getFKAppDetails', params),
    // 获取收付款审批流转详情
    getSFKAppCheckLogs: (params) => request('YZFMgr', 'getSFKAppCheckLogs', params),
    // 承运方（物流公司或车队长）和默默通运营方批量确认发起收款申请单、上传或更新发票信息的初始化函数
    getSKAppInitXX: (params) => request('YZFMgr', 'getSKAppInitXX', params),
    // 申请发起者更新发票信息（批量发起） FOR 物流公司和默默通
    multModifySKAppFPXX: (params) => request('YZFMgr', 'multModifySKAppFPXX', params),
    // 确认发起收款申请单（批量发起） FOR 物流公司、默默通运营方
    multQRSendSKApp: (params) => request('YZFMgr', 'multQRSendSKApp', params),
    // 批量确认收款申请 FOR 默默通运营方
    multQRSKApp4YYF: (params) => request('YZFMgr', 'multQRSKApp4YYF', params)
  },
  adminRzUserXX: {
    // 物流世家用户管理 For 平台Admin视角
    getWLSJRZMgr4PC: (params) => request('RzUserMgrV1', 'getWLSJRZMgr4PC', params),
    // 仓储管家用户管理 For 平台Admin视角
    getCCGJRZMgr4PC: (params) => request('RzUserMgrV1', 'getCCGJRZMgr4PC', params)
  },
  memberIist: {
    // 会员管理列表
    getUserList: (params) => request('UserMgr', 'getUserList', params),
    // 已删除会员列表
    getUserList4Del: (params) => request('UserMgr', 'getUserList4Del', params),
    // 下拉初始化列表
    getInitXX: (params) => request('UserMgr', 'getInitXX', params),
    // 账号注册
    register: (params) => request('UserMgr', 'register', params),
    // 修改
    editpwd: (params) => request('UserMgr', 'editpwd', params),
    // 删除
    deleteuser: (params) => request('UserMgr', 'deleteuser', params),
    // 积分充值
    recharge: (params) => request('UserMgr', 'recharge', params),
    // 设置家家协同
    setUserInfo4JJXT: (params) => request('UserMgr', 'setUserInfo4JJXT', params),
    // 获取未关联权限列表和已关联权限列表
    getFuncList4Admin: (params) => request('UserMgr', 'getFuncList4Admin', params),
    // 企业用户分配权限 post传递
    addUserFuncs: (params) => request('UserMgr', 'addUserFuncs', params)
  },
  pslineRoute: {
    // 获取初始化信息
    getInit: (params) => request('PSrtLineMgr', 'getInit', params),
    // 新增线路
    addPsrtInfo: (params) => request('PSrtLineMgr', 'addPsrtInfo', params),
    // 新增线路
    getUserList: (params) => request('PSrtLineMgr', 'getUserList', params),
    // 删除配送线路
    delPsrtInfo: (params) => request('PSrtLineMgr', 'delPsrtInfo', params),
    // 获取配送线路信息
    getPsrtInfo: (params) => request('PSrtLineMgr', 'getPsrtInfo', params),
    // 修改配送线路
    editPsrtInfo: (params) => request('PSrtLineMgr', 'editPsrtInfo', params)
  },
  creditlist: {
    // 获取货主的星级信息 FOR 货主
    getGDOwerStarXX: (params) => request('CreditMgr', 'getGDOwerStarXX', params),
    // 获取司机的星级信息 FOR 司机
    getDriverStarXX: (params) => request('CreditMgr', 'getDriverStarXX', params)
  },
  sjSign: {
    // 签到板块初始化 FOR 仓储管家
    getInitXX4CCGJ: (params) => request('TKSignInMgr', 'getInitXX4CCGJ', params),
    // 获取签收列表  FOR 仓储管家
    getTKSignInOrderList4CCGJ: (params) => request('TKSignInMgr', 'getTKSignInOrderList4CCGJ', params),
    // 获取初次签到的信息 For 仓储管家
    getFirstSignOrderXX: (params) => request('TKSignInMgr', 'getFirstSignOrderXX', params),
    // 初次车辆签到 For 仓储管家
    truckSign: (params) => request('TKSignInMgr', 'truckSign', params),
    // 获取其他签到明细信息 For dispatcher man
    getQTSignOrderXX: (params) => request('TKSignInMgr', 'getQTSignOrderXX', params),
    // 续装签到-针对换票车辆 For 仓储管家
    revTruckSign: (params) => request('TKSignInMgr', 'revTruckSign', params),
    // 取消车辆签到 For 仓储管家
    cancelTruckSign: (params) => request('TKSignInMgr', 'cancelTruckSign', params),
    // 获取被取消签到明细信息 For dispatcher man
    getDelSignOrderXX: (params) => request('TKSignInMgr', 'getDelSignOrderXX', params)
  },
  ZXlocation: {
    // 初始化地图组建中显示最新位置或轨迹 FOR PC
    getInitXX4MapComponent: (params) => request('Tklocation', 'getInitXX4MapComponent', params),
    // 获取车牌最新位置查看历史
    getRealPosHisCXList: (params) => request('Tklocation', 'getRealPosHisCXList', params),
    // 获取车牌最新位置查看历史
    getRealPosHisCXListByID: (params) => request('Tklocation', 'getRealPosHisCXListByID', params),
    // 获取车牌最新位置
    latestLoction: (params) => request('Ziyun', 'latestLoction', params)
  },
  LSlocation: {
    // 初始化地图组建中显示最新位置或轨迹 FOR PC
    getInitXX4MapComponent: (params) => request('Tklocation', 'getInitXX4MapComponent', params),
    // 获取车牌轨迹查看历史列表
    getHisTrackCXList: (params) => request('Tklocation', 'getHisTrackCXList', params),
    // 查看车辆轨迹历史
    getTrackHisCXListByID: (params) => request('Tklocation', 'getTrackHisCXListByID', params),
    // 车辆轨迹查询
    hisTrack: (params) => request('Ziyun', 'hisTrack', params)
  },
  truck: {
    // 查看车辆列表 For 司机视角
    getTruckList4SJZJ: (params) => request('NetTKXXMgr', 'getTruckList4SJZJ', params),
    // 查看车辆列表 For 默默通运营中心视角
    getTruckList4YYF: (params) => request('NetTKXXMgr', 'getTruckList4YYF', params),
    // 注册车辆申请和车辆台帐 For 默默通运营中心视角  PC
    getPCSRegTruckList4YYF: (params) => request('NetTKXXMgr', 'getPCSRegTruckList4YYF', params),
    // 获取自营车辆列表 For 默默通运营中心视角
    getTruckListMMTZY4YYF: (params) => request('NetTKXXMgr', 'getTruckListMMTZY4YYF', params),
    // 获取挂车辆列表 For 默默通运营中心视角
    getCarryTruckListMMTZY4YYF: (params) => request('NetTKXXMgr', 'getCarryTruckListMMTZY4YYF', params),
    // 获取车辆受限列表
    getTruckLimitList: (params) => request('NetTKXXMgr', 'getTruckLimitList', params),
    // 取消车辆受限 For ALL
    cancelTruckLimit: (params) => request('NetTKXXMgr', 'cancelTruckLimit', params),
    // 查看车辆列表 For 物流公司和家家协同
    getTruckList4Other: (params) => request('NetTKXXMgr', 'getTruckList4Other', params),
    // 获取标准注册车辆申请列表或台帐 For 物流公司视角 PC
    getPCSRegTruckList4WLSJ: (params) => request('NetTKXXMgr', 'getPCSRegTruckList4WLSJ', params),
    // 获取标准注册车辆申请列表或台帐 For 司机视角  PC
    getPCSRegTruckList4SJZJ: (params) => request('NetTKXXMgr', 'getPCSRegTruckList4SJZJ', params),
    // 获取车辆型号列表
    getTruckTypes: () => request('NetTKXXMgr', 'getTruckTypes'),
    // 删除车辆信息 For ALL
    delTruckXX: (regtkid) => request('NetTKXXMgr', 'delTruckXX', { regtkid }),
    // 删除普通货车或拖拉机申请信息
    delTruckXX4WLSJ: (regtkid) => request('NetTKXXMgr', 'delTruckXX4WLSJ', { regtkid }),
    // 删除普通货车或拖拉机申请信息
    delTruckXX4SJZJ: (regtkid) => request('NetTKXXMgr', 'delTruckXX4SJZJ', { regtkid }),
    // 取消普通货车或拖拉机申请信息 For 物流公司
    cancelRegTruckXXApp4WLSJ: (tkappid) => request('NetTKXXMgr', 'cancelRegTruckXXApp4WLSJ', { tkappid }),
    cancelRegTruckXXApp4SJZJ: (tkappid) => request('NetTKXXMgr', 'cancelRegTruckXXApp4SJZJ', { tkappid }),
    // 上传车辆头像
    uploadTruckIcon: (imgdata) => request('NetTKXXMgr', 'uploadTruckIcon', { imgdata }),
    // 道路运输证识别信息
    roadLicenseOCR: (faceData) => request('Ziyun', 'roadLicenseOCR', { faceData }),
    newroadLicenseOCR: (faceData) => request('Ziyun', 'newroadLicenseOCR', { faceData }),
    // 获取车辆行驶证OCR信息
    getVehicleLicensesOCR: (carnumber) => request('NetTKXXMgr', 'getVehicleLicensesOCR', { carnumber }),
    // 获取车辆道路运输证OCR信息
    getRoadLicenseOCR: (carnumber, rtcno) => request('NetTKXXMgr', 'getRoadLicenseOCR', { carnumber, rtcno }),
    // 车辆入网校验
    checkTruckExist: (carnumber) => request('Ziyun', 'checkTruckExist', { carnumber }),
    // 查看车辆信息
    getRegTKXX: (regtkid) => request('NetTKXXMgr', 'getRegTKXX', { regtkid }),
    // 审核车辆信息
    chkRegTruck: (params) => request('NetTKXXMgr', 'chkRegTruck', params),
    // 审核通过普通货车或拖拉机信息 For 运营方
    agreeSRegTruckApp: (params) => request('NetTKXXMgr', 'agreeSRegTruckApp', params),
    // 更新台帐中的车辆信息（仅供运营方使用） For 运营方
    updateSRegTruckXX: (params) => request('NetTKXXMgr', 'updateSRegTruckXX', params),
    // 驳回车辆信息
    bohuiRegTruck: (params) => request('NetTKXXMgr', 'bohuiRegTruck', params),
    // 强制驳回车辆信息 For 运营方
    bohuiSRegTruckApp: (params) => request('NetTKXXMgr', 'bohuiSRegTruckApp', params),
    // 查看审核车辆详情
    getRegTKXX4Chk: (regtkid) => request('NetTKXXMgr', 'getRegTKXX4Chk', { regtkid }),
    // 获取车辆注册申请详情 For ALL
    getRegTruckAppDetails: (tkappid) => request('NetTKXXMgr', 'getRegTruckAppDetails', { tkappid }),
    // 获取已注册车辆详情 For ALL
    getRegTruckAccDetails: (regtkid) => request('NetTKXXMgr', 'getRegTruckAccDetails', { regtkid }),
    // 登记普通货车信息
    regCommonTruckXX: (params) => request('NetTKXXMgr', 'regCommonTruckXX', params),
    // 登记普通货车信息 For 运输公司
    regCommonTruckXX4WLSJ: (params) => request('NetTKXXMgr', 'regCommonTruckXX4WLSJ', params),
    regStandTruckXX4WLSJ: (params) => request('NetTKXXMgr', 'regStandTruckXX4WLSJ', params),
    regStandTruckXX4SJZJ: (params) => request('NetTKXXMgr', 'regStandTruckXX4SJZJ', params),
    retjStandTruckXX4WLSJ: (params) => request('NetTKXXMgr', 'retjStandTruckXX4WLSJ', params),
    retjStandTruckXX4SJZJ: (params) => request('NetTKXXMgr', 'retjStandTruckXX4SJZJ', params),
    modStandTruckXX4WLSJ: (params) => request('NetTKXXMgr', 'modStandTruckXX4WLSJ', params),
    modStandTruckXX4SJZJ: (params) => request('NetTKXXMgr', 'modStandTruckXX4SJZJ', params),
    // 编辑普通货车信息 For ALL
    editCommonTruckXX: (params) => request('NetTKXXMgr', 'editCommonTruckXX', params),
    // 编辑普通货车信息 For 运输公司
    editCommonTruckXX4WLSJ: (params) => request('NetTKXXMgr', 'editCommonTruckXX4WLSJ', params),
    // 查看车辆认证信息 For ALL
    viewTruckRZXX: (params) => request('NetTKXXMgr', 'viewTruckRZXX', params),
    // 获取用户积分信息 For ALL
    getUserScoreXX: () => request('NetTKXXMgr', 'getUserScoreXX'),
    // 行驶证信息查询
    queryLicense: (params) => request('Ziyun', 'queryLicense', params),
    // 道路运输证验证
    chkRTCNo: (params) => request('Ziyun', 'chkRTCNo', params),
    // 获取制定规则所消耗的积分
    getTheRuleScoreXX: (actname) => request('NetTKXXMgr', 'getTheRuleScoreXX', { actname }),
    // 获取车辆行驶证信息历史查询
    getHisLicenses: (params) => request('NetTKXXMgr', 'getHisLicenses', params),
    // 获取车辆道路运输证信息历史查询
    getHisChkRoadLicenses: (params) => request('NetTKXXMgr', 'getHisChkRoadLicenses', params),
    // 获取车辆行驶证信息历史查询
    getTheLicenses: (rtcardid) => request('NetTKXXMgr', 'getTheLicenses', { rtcardid }),
    // 获取车辆道路运输证信息历史查询 For ALL
    getTheChkRoadLicenses: (chkroadid) => request('NetTKXXMgr', 'getTheChkRoadLicenses', { chkroadid }),
    // 将车辆信息加入采集列表
    addTruck2CJList: (regtkid) => request('NetTKXXMgr', 'addTruck2CJList', { regtkid }),
    // 修改车辆基本信息
    modTruckBaseXX: (params) => request('NetTKXXMgr', 'modTruckBaseXX', params),
    // 更新车辆行驶证信息
    modTruckVehicleLicensesXX: (params) => request('NetTKXXMgr', 'modTruckVehicleLicensesXX', params),
    // 更新道路运输证信息
    modTruckRoadLicensesXX: (params) => request('NetTKXXMgr', 'modTruckRoadLicensesXX', params),
    // 修改道路运输经营许可证
    modTruckTranJYXKZLicensesXX: (params) => request('CertificateMgr', 'modTruckTranJYXKZLicensesXX', params),
    // 删除证件信息
    delRoadLicenseXX: (params) => request('NetTKXXMgr', 'delRoadLicenseXX', params),
    // 批量检验车辆证件
    multCheckNetTrucks: () => request('NetTKXXMgr', 'multCheckNetTrucks'),
    // 检验单台车辆证件
    singleCheckNetTrucks: (params) => request('NetTKXXMgr', 'singleCheckNetTrucks', params)
  },
  tksrc: {
    // 获取初始化信息
    getInitXX: () => request('TKSrcMgr', 'getInitXX'),
    // 获取车辆列表
    getTruckList: (params) => request('TKSrcMgr', 'getTruckList', params),
    // 发布车源信息 FOR ALL
    regTkSrcXX: (params) => request('TKSrcMgr', 'regTkSrcXX', params),
    // 编辑车源信息 FOR ALL
    editTkSrcXX: (params) => request('TKSrcMgr', 'editTkSrcXX', params),
    // 删除车源信息
    delTKSrcXX: (params) => request('TKSrcMgr', 'delTKSrcXX', params),
    // 确认发布车源信息
    sendTKSrcXX2YYFChk: (params) => request('TKSrcMgr', 'sendTKSrcXX2YYFChk', params),
    // 取消发布车源信息
    cancelTKSrcXX: (params) => request('TKSrcMgr', 'cancelTKSrcXX', params),
    // 立即结束车源信息
    endTKSrcXX: (params) => request('TKSrcMgr', 'endTKSrcXX', params),
    // 复制车源信息
    copyTKSrcXX: (params) => request('TKSrcMgr', 'copyTKSrcXX', params),
    // 再次发布车源信息
    reSendTKSrcXX: (params) => request('TKSrcMgr', 'reSendTKSrcXX', params),
    // 运营方同意车源信息发布
    agreeTKSrcXX4YYF: (params) => request('TKSrcMgr', 'agreeTKSrcXX4YYF', params),
    // 运营方驳回车源信息发布
    BHTKSrcXX4YYF: (params) => request('TKSrcMgr', 'BHTKSrcXX4YYF', params),
    // 获取车辆列表 FOR 司机和物流公司
    getTKSrcList4WLSJAndSJZJ: (params) => request('TKSrcMgr', 'getTKSrcList4WLSJAndSJZJ', params),
    // 获取车辆列表 FOR 运营方
    getTKSrcList4YYF: (params) => request('TKSrcMgr', 'getTKSrcList4YYF', params),
    // 获取车源详情
    getTKSrcDetails: (tksrcid) => request('TKSrcMgr', 'getTKSrcDetails', { tksrcid })
  },
  contract: {
    // 获取合同管理列表
    getContractList: (params) => request('ContractMgr', 'getContractList', params),
    // 查看保险须知
    getCargoBXTips: (params) => request('ContractMgr', 'getCargoBXTips', params),
    // 查看保险电子协议
    getCargoBXDetail: (params) => request('ContractMgr', 'getCargoBXDetail', params),
    // 查看电子协议须知（运营方&货主委托运输的）
    getHZContractTips: (params) => request('ContractMgr', 'getHZContractTips', params),
    // 查看电子协议须知（运营方&司机配送的）
    getSJContractTips: (params) => request('ContractMgr', 'getSJContractTips', params),
    // 识别是否是运营方，是否具有签署权限
    hasSignContractPower4YYF: (params) => request('ContractMgr', 'hasSignContractPower4YYF', params),
    // 获取框架合同列表 FOR 运营方
    getContractList4YYF: (params) => request('FrameContractMgr', 'getContractList4YYF', params),
    // 获取合同列表 FOR 司机
    getContractList4SJZJ: () => request('FrameContractMgr', 'getContractList4SJZJ'),
    // 获取合同列表 FOR 货主
    getContractList4HZDJ: () => request('FrameContractMgr', 'getContractList4HZDJ'),
    // 获取有效合同（轮寻用，也可以用于判断有无签订框架合同） FOR 货主
    getEffectiveContractXX4HZDJ: () => request('FrameContractMgr', 'getEffectiveContractXX4HZDJ')

  },
  index: {
    // 初始化默默通首页函数（PC）
    initIndexPC: () => request('Index', 'initIndexPC'),
    // 获取首页底部栏目信息及相关资讯列表
    getNewsInfo4BottomFid: () => request('Index', 'getNewsInfo4BottomFid'),
    // 获取本期预测数据
    getThisTermForePriceList: () => request('Index', 'getThisTermForePriceList'),
    // 获取历史价格数据
    getHisTermPriceList: (params) => request('Index', 'getHisTermPriceList', params)
  },
  netBank: {
    // 获取钱包初始化信息
    getWalletInitXX: (gpname) => request('NetBankMgr', 'getWalletInitXX', { gpname }),
    // 获取默默通运营方默认钱包信息（含账户余额、收入汇总和明细）
    geWalletDefaultXX4YYF: () => request('NetBankMgr', 'geWalletDefaultXX4YYF', { clientType: 'pc' }),
    // 获取默默通司机默认钱包信息（含账户余额、收入汇总和明细）  FOR 司机
    geWalletDefaultXX4SJZJ: (usercode) => request('NetBankMgr', 'geWalletDefaultXX4SJZJ', { clientType: 'pc', usercode }),
    // 获取默默通司机默认钱包信息（含账户余额、收入汇总和明细）  FOR 货主
    geWalletDefaultXX4HZDJ: (usercode) => request('NetBankMgr', 'geWalletDefaultXX4HZDJ', { clientType: 'pc', usercode }),
    // 获取默默通物流世家默认钱包信息（含账户余额、收入汇总和明细）  FOR 物流世家
    geWalletDefaultXX4WLSJ: (usercode) => request('NetBankMgr', 'geWalletDefaultXX4WLSJ', { clientType: 'pc', usercode }),
    // 获取默默通车队长默认钱包信息（含账户余额、收入汇总和明细）  FOR 车队长
    geWalletDefaultXX4TKMayer: (params) => request('NetBankMgr', 'geWalletDefaultXX4TKMayer', { ...params, clientType: 'pc' }),
    // 获取默默通运营方指定类型的钱包账户和余额列表
    geTheAccTypetWalletXX4YYF: (accountType) => request('NetBankMgr', 'geTheAccTypetWalletXX4YYF', { clientType: 'pc', accountType }),
    // 获取资金流水列表
    getCapFlowList4YYF: (params) => request('NetBankMgr', 'getCapFlowList4YYF', { ...params, clientType: 'pc' }),
    // 获取资金流水列表 FOR 司机之家
    getCapFlowList4SJZJ: (params) => request('NetBankMgr', 'getCapFlowList4SJZJ', { ...params, clientType: 'pc' }),
    // 获取资金流水列表 FOR 货主帝家
    getCapFlowList4HZDJ: (params) => request('NetBankMgr', 'getCapFlowList4HZDJ', { ...params, clientType: 'pc' }),
    // 获取资金流水列表 FOR 物流世家
    getCapFlowList4WLSJ: (params) => request('NetBankMgr', 'getCapFlowList4WLSJ', { ...params, clientType: 'pc' }),
    // 获取资金流水列表 FOR 车队长
    getCapFlowList4TKMayer: (params) => request('NetBankMgr', 'getCapFlowList4TKMayer', { ...params, clientType: 'pc' }),
    // 个体货主或司机用户开户初始化信息 FOR 货主帝家
    getPersonUserRegInitXX: (gpname) => request('NetBankMgr', 'getPersonUserRegInitXX', { gpname, clientType: 'pc' }),
    // 货主企业用户开户初始化信息 FOR 货主帝家
    getCompGDOwnerUserRegInitXX: () => request('NetBankMgr', 'getCompGDOwnerUserRegInitXX', { clientType: 'pc' }),
    // 物流公司开户初始化信息 FOR 物流世家
    getCompWLSJUserRegInitXX: () => request('NetBankMgr', 'getCompWLSJUserRegInitXX', { clientType: 'pc' }),
    // 获取向默默通监管账号充值信息
    getPlatformSupAccXX: () => request('NetBankMgr', 'getPlatformSupAccXX'),
    // 查看用户开户的初始化信息
    getRegUserInitXX4YYF: () => request('NetBankMgr', 'getRegUserInitXX4YYF'),
    // 查看用户开户开户信息 FOR 运营方
    getCSBankRegUserXX4YYF: (params) => request('NetBankMgr', 'getCSBankRegUserXX4YYF', { ...params, clientType: 'pc' }),
    // 查看用户开户列表
    getRegBankUserList4YYF: (params) => request('NetBankMgr', 'getRegBankUserList4YYF', params),
    // 设置支付密码 FOR 运营方
    setPayVerifyPWD: (params) => request('NetBankMgr', 'setPayVerifyPWD', { ...params, dev: 'PC' }),
    // 司机开户
    regDriverUserXX: () => request('NetBankMgr', 'regDriverUserXX', { clientType: 'pc' }),
    // 货主个体开户 FOR 货主帝家
    regGRGDOwnerUserXX: () => request('NetBankMgr', 'regGRGDOwnerUserXX', { clientType: 'pc' }),
    // 查看用户虚拟账号信息 FOR 运营方
    getCSBankVirAccXX4YYF: (usercode) => request('NetBankMgr', 'getCSBankVirAccXX4YYF', { clientType: 'pc', usercode }),
    // 获取默默通指定用户编码的可用余额、冻结余额和金额
    geTheUsercodeWalletXX: (usercode) => request('NetBankMgr', 'geTheUsercodeWalletXX', { clientType: 'pc', usercode }),
    // 禁止与解冻虚拟账号 FOR 运营方
    setCSBankVirAccState4YYF: (params) => request('NetBankMgr', 'setCSBankVirAccState4YYF', { clientType: 'pc', ...params }),
    // 获取用户编码银行卡信息
    getTheUserCodeBankXX: (usercode) => request('NetBankMgr', 'getTheUserCodeBankXX', { usercode }),
    // 司机、货主个体绑卡 FOR 货主帝家和司机之家
    bindBankXX: (params) => request('NetBankMgr', 'bindBankXX', { clientType: 'pc', ...params }),
    // 物流公司的车队长绑卡 FOR 物流世家
    bindBankXX4TKMayer: (params) => request('NetBankMgr', 'bindBankXX4TKMayer', { clientType: 'pc', ...params }),
    // 个体司机修改绑卡 FOR 货主帝家
    modPersonBankXX4SJZJ: (params) => request('NetBankMgr', 'modPersonBankXX4SJZJ', { clientType: 'pc', ...params }),
    // 个体货主修改绑卡 FOR 货主帝家
    modPersonBankXX4HZDJ: (params) => request('NetBankMgr', 'modPersonBankXX4HZDJ', { clientType: 'pc', ...params }),
    // 企业货主修改绑卡 FOR 货主帝家
    modCompBankXX4HZDJ: (params) => request('NetBankMgr', 'modCompBankXX4HZDJ', { clientType: 'pc', ...params }),
    // 企业物流公司修改绑卡 FOR 物流世家
    modCompBankXX4WLSJ: (params) => request('NetBankMgr', 'modCompBankXX4WLSJ', { clientType: 'pc', ...params }),
    // 物流公司的车队长修改绑卡 FOR 车队长
    modTKMayerBankXX: (params) => request('NetBankMgr', 'modTKMayerBankXX', { clientType: 'pc', ...params }),
    // 设置、修改和重置长沙银行支付密码 FOR 货主帝家或司机之家
    setBankPassword: (params) => request('NetBankMgr', 'setBankPassword', { clientType: 'pc', ...params }),
    // 设置、修改和重置长沙银行支付密码 FOR 物流公司的车队长
    setBankPassword4TKMayer: (params) => request('NetBankMgr', 'setBankPassword4TKMayer', { clientType: 'pc', ...params }),
    // 个体用户解绑卡片
    unBindPersonBankXX: (params) => request('NetBankMgr', 'unBindPersonBankXX', { clientType: 'pc', ...params }),
    // 企业用户解绑卡片
    unBindCompBankXX: (params) => request('NetBankMgr', 'unBindCompBankXX', { clientType: 'pc', ...params }),
    // 司机提现
    withDrawVal4SJZJ: (params) => request('NetBankMgr', 'withDrawVal4SJZJ', { clientType: 'pc', ...params }),
    // 运营方提现
    withDrawVal4YYF: (params) => request('NetBankMgr', 'withDrawVal4YYF', { clientType: 'pc', ...params }),
    // 运营方提现后获取钱包余额
    getWalletBalanceXX4YYF: (params) => request('NetBankMgr', 'getWalletBalanceXX4YYF', { clientType: 'pc', ...params }),
    // 货主提现
    withDrawVal4HZDJ: (params) => request('NetBankMgr', 'withDrawVal4HZDJ', { clientType: 'pc', ...params }),
    // 物流公司提现
    withDrawVal4WLSJ: (params) => request('NetBankMgr', 'withDrawVal4WLSJ', { clientType: 'pc', ...params }),
    // 车队长提现
    withDrawVal4TKMayer: (params) => request('NetBankMgr', 'withDrawVal4TKMayer', { clientType: 'pc', ...params }),
    // 企业货主开户绑卡 FOR 货主帝家
    regCompGDOwnerUserXX: (params) => request('NetBankMgr', 'regCompGDOwnerUserXX', { clientType: 'pc', ...params }),
    // 物流公司开户绑卡 FOR 物流世家
    regCompWLSJUserXX: (params) => request('NetBankMgr', 'regCompWLSJUserXX', { clientType: 'pc', ...params }),
    // 获取清算行信息
    getNetBankList: (bankjc) => request('NetBankMgr', 'getNetBankList', { clientType: 'pc', bankjc }),
    // 企业货主（修改绑卡）打款验证 FOR 货主帝家
    payment4VerifyModBind4HZDJ: (params) => request('NetBankMgr', 'payment4VerifyModBind4HZDJ', { clientType: 'pc', ...params }),
    // 物流公司（修改绑卡）打款验证 FOR 物流世家
    payment4VerifyModBind4WLSJ: (params) => request('NetBankMgr', 'payment4VerifyModBind4WLSJ', { clientType: 'pc', ...params }),
    // 监听银行卡信息变动情况（编辑卡片或解绑）
    listenTheUserBankChange: (params) => request('NetBankMgr', 'listenTheUserBankChange', { clientType: 'pc', ...params }),
    // 收银台初始化接口
    getPayCashInitXX: (params) => request('NetBankMgr', 'getPayCashInitXX', { clientType: 'pc', ...params }),
    // 货主支付运营方运费
    payWTOrder: (params) => request('NetBankMgr', 'payWTOrder', { clientType: 'pc', ...params }),
    // 运营方支付司机运费
    payPSOrder: (params) => request('NetBankMgr', 'payPSOrder', { clientType: 'pc', ...params }),
    // 收银台监听付款是否完成
    listenPayCashFinish: (params) => request('NetBankMgr', 'listenPayCashFinish', { clientType: 'pc', ...params }),
    // 监听银行提现处理情况
    getWithDrawTranResult: (params) => request('NetBankMgr', 'getWithDrawTranResult', { clientType: 'pc', ...params })
  },
  capbill: {
    // 获取初始化信息
    getInitXX: () => request('CapBillMgr', 'getInitXX'),
    // 资金凭证列表
    getCapBillList: (params) => request('CapBillMgr', 'getCapBillList', params),
    // 导出凭证列表
    exportCapBillList: (params) => request('CapBillMgr', 'exportCapBillList', params)
  },
  cargobox: {
    // 保险管理列表
    getCargobxList: (params) => request('CargobxMgr', 'getCargobxList', params),
    // 查看电子保单
    getInsurePdf: (params) => request('CargobxMgr', 'getInsurePdf', params)
  },
  qySuoSign: {
    // 个人用户实名认证
    identityUser: (params) => request('QYSuoSign', 'identityUser', params),
    // 企业用户身份信息校验
    identityComp: (params) => request('QYSuoSign', 'identityComp', params),
    // 判断是否进行了认证
    hasPower4SignHT: (params) => request('QYSuoSign', 'hasPower4SignHT', params),
    // 发起签署委托运输合同（货主（含个体和企业）,需要运营方手动盖签署章）
    sendWTContract4HZDJ: (params) => request('QYSuoSign', 'sendWTContract4HZDJ', params),
    // 运营方公章签署合同
    signWTContract4YYF: (params) => request('QYSuoSign', 'signWTContract4YYF', params),
    // 发起签署配送合同（司机,需要运营方自动签署）
    sendPSContract4SJZJ: (params) => request('QYSuoSign', 'sendPSContract4SJZJ', params),
    // 在线预览合同
    previewContract: (params) => request('QYSuoSign', 'previewContract', params),
    // 发起签署一年一签框架合同
    sendOneYearFrameContract4SJZJ: (params) => request('QYSuoSign', 'sendOneYearFrameContract4SJZJ', params),
    // 发起签署委托运输合同（货主（含个体和企业）,一年一签，运营方自动签署）
    sendOneYearFrameContract4HZDJ: () => request('QYSuoSign', 'sendOneYearFrameContract4HZDJ'),
    // 判断是否实名认证
    hasSMRZ: (params) => request('QYSuoSign', 'hasSMRZ', params)
  },
  tkmayer: {
    // 获取车队长列表 FOR 物流世家
    getTKMayerList4WLSJ: (params) => request('TKMayerMgr', 'getTKMayerList4WLSJ', params),
    // 获取初始化信息
    getInitXX: (params) => request('TKMayerMgr', 'getInitXX', params),
    // OCR识别身份证
    IDCardOCR: (params) => request('TKMayerMgr', 'IDCardOCR', params),
    // 添加车队长信息
    regTKMayer: (params) => request('TKMayerMgr', 'regTKMayer', params),
    // 设置车队长状态
    setTKMayerLockState: (params) => request('TKMayerMgr', 'setTKMayerLockState', params),
    // 获取车队长列表(派车选车队长时用到）
    getTKMayerList4SetTruck: (params) => request('TKMayerMgr', 'getTKMayerList4SetTruck', params),
    // 获取车队长列表 FOR 默默通运营方
    getTKMayerList4YYF: (params) => request('TKMayerMgr', 'getTKMayerList4YYF', params),
    // 登记开票信息并更新月度使用额度
    regFPXX: (params) => request('TKMayerMgr', 'regFPXX', params),
    // 获取车队长开票信息
    getTKMayerFPList: (params) => request('TKMayerMgr', 'getTKMayerFPList', params),
    // 删除开票并更新月度使用额度
    delFPXX: (params) => request('TKMayerMgr', 'delFPXX', params)
  },
  tkblack: {
    // 车辆黑名单列表
    getTKBlackList: (params) => request('TKBlackMgr', 'getTKBlackList', params),
    // 检测车牌号是否存在
    detectCarnumber: (carnumber) => request('TKBlackMgr', 'detectCarnumber', { carnumber }),
    // 添加车辆至黑名单
    addTKBlackInfo: (params) => request('TKBlackMgr', 'addTKBlackInfo', params),
    // 车辆解除黑名单
    delTKBlackInfo: (tkblackid) => request('TKBlackMgr', 'delTKBlackInfo', { tkblackid })
  },
  file: {
    // 上传文件
    uploadQTFileList: (formData) => filerequest('ElArcMgr', 'uploadQTFileList', formData),
    // 上传图片
    uploadFiles: (formData) => filerequest('Member', 'uploadFiles', formData)
  },
  oilcard: {
    // 获取物流公司油卡管理列表 FOR 默默通运营方
    getWLSJOilCardList4YYF: (params) => request('OilCardMgr', 'getWLSJOilCardList4YYF', params),
    // 获取车队长油卡管理列表 FOR 默默通运营方
    getTKMayerOilCardList4YYF: (params) => request('OilCardMgr', 'getTKMayerOilCardList4YYF', params),
    // 获取司机油卡管理列表 FOR 默默通运营方
    getSJZJOilCardList4YYF: (params) => request('OilCardMgr', 'getSJZJOilCardList4YYF', params),
    // 禁用/启用卡片 FOR 默默通运营方
    setOilCardState: (params) => request('OilCardMgr', 'setOilCardState', params),
    // 删除卡片 FOR 默默通运营方
    delOilCard: (params) => request('OilCardMgr', 'delOilCard', params),
    // 油卡充值记录 FOR 默默通运营方
    getOilCardRechargeList: (params) => request('OilCardMgr', 'getOilCardRechargeList', params),
    // 获取油卡关联情况 FOR 默默通运营方
    getRelOilCardList: (params) => request('OilCardMgr', 'getRelOilCardList', params),
    // 油卡登记 FOR 默默通运营方
    regOilCard: (params) => request('OilCardMgr', 'regOilCard', params),
    // 编辑加油卡 FOR 默默通运营方
    editOilCard: (params) => request('OilCardMgr', 'editOilCard', params)
  },
  etcbill: {
    // 获取开票申请管理列表表头 For 默默通运营方
    getETCHeadFields: (params) => request('ETCBillMgr', 'getETCHeadFields', params),
    // 获取ETC发票台帐表头 For 默默通运营方
    getAccHeadFields: (params) => request('ETCBillMgr', 'getAccHeadFields', params),
    // 获取ETC发票请求管理列表  FOR 运营方 PC
    getETCAppList4YYF4PC: (params) => request('ETCBillMgr', 'getETCAppList4YYF4PC', params),
    // 获取ETC发票台帐列表  FOR 运营方 PC
    getETCBillAccList4YYF4PC: (params) => request('ETCBillMgr', 'getETCBillAccList4YYF4PC', params),
    // 提交开票申请  FOR 运营方 PC
    tjMakeBillApp: (params) => request('ETCBillMgr', 'tjMakeBillApp', params),
    // 通过发票申请信息ID获取开票信息  FOR 运营方 PC
    getTheAppIDBillList: (params) => request('ETCBillMgr', 'getTheAppIDBillList', params)
  },
  sjbill: {
    // 获取司机发票请求管理列表  FOR 运营方 PC
    getSJBillAppList4YYF4PC: (params) => request('SJBillMgr', 'getSJBillAppList4YYF4PC', params),
    // 获取开票申请管理列表表头 For 默默通运营方
    getSJBillHeadFields: (params) => request('SJBillMgr', 'getSJBillHeadFields', params)
  }
}

export default services
