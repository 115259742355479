<template>
  <div
    class="ly-ag-table-clear"
    :class="{ 'has-filter': hasFilterValue }"
    @click="handleClearClick"
  >
    <template v-if="hasFilterValue">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="16"
        height="16"
      >
        <path
          d="M21 4V6H20L15 13.5V22H9V13.5L4 6H3V4H21ZM6.4037 6L11 12.8944V20H13V12.8944L17.5963 6H6.4037Z"
          fill="#999"
        ></path>
      </svg>
    </template>
    <template v-else>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="16"
        height="16"
      >
        <path
          d="M6.92893 0.514648L21.0711 14.6568L19.6569 16.071L15.834 12.2486L15 13.4999V21.9999H9V13.4999L4 5.99993H3V3.99993L7.585 3.99965L5.51472 1.92886L6.92893 0.514648ZM9.585 5.99965L6.4037 5.99993L11 12.8944V19.9999H13V12.8944L14.392 10.8066L9.585 5.99965ZM21 3.99993V5.99993H20L18.085 8.87193L16.643 7.42893L17.5963 5.99993H15.213L13.213 3.99993H21Z"
          fill="#999"
        ></path>
      </svg>
    </template>
    <div class="c-text">清空</div>
  </div>
</template>

<script>
export default {
  computed: {
    hasFilterValue () {
      return this.params.getFilterLength() !== 0
    }
  },
  methods: {
    handleClearClick () {
      this.params.enter()
    }
  }
}
</script>

<style lang="scss" scoped>
.ly-ag-table-clear {
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;

  color: #999;
  cursor: pointer;

  &.has-filter {
    color: #181d1f;

    svg path {
      fill: #181d1f;
    }
  }

  &:hover {
    color: #32b16c;

    svg path {
      fill: #32b16c;
    }
  }

  svg {
    width: 14px;
  }

  .c-text {
    margin-left: 2px;
  }
}
</style>
