import Vue from 'vue'
import VueRouter from 'vue-router'

import user from '@/router/modules/user'
import front from '@/router/modules/front'
import PTHY from '@/router/modules/back/PTHY'
import WLSJ from '@/router/modules/back/WLSJ'
import SJZJ from '@/router/modules/back/SJZJ'
import CCGJ from '@/router/modules/back/CCGJ'
import HZDJ from '@/router/modules/back/HZDJ'
import YYPLAT from '@/router/modules/back/YYPLAT'
import JJXT from '@/router/modules/back/JJXT'
import MMTPT from '@/router/modules/back/MMTPT'
import store from '@/store/index'

Vue.use(VueRouter)

export const defaultRoutes = [...front, ...user, {
  path: '/404',
  component: () => import(/* webpackChunkName: "error" */ '@/views/error/NotFoundView')
}]
export const asyncRoutes = {
  PTHY,
  WLSJ,
  SJZJ,
  CCGJ,
  HZDJ,
  YYPLAT,
  JJXT,
  MMTPT
}

const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: defaultRoutes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0, behavior: 'smooth' }
  }
})

export function resetRouter () {
  store.dispatch('tagsView/delAllViews')
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

const router = createRouter()

export default router
