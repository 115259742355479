<template>
  <div>
    <el-dialog
      title="固定设置"
      :visible.sync="isShow"
      class="custompinned_dialog"
      top="5vh"
      width="940px"
    >
      <div class="trans_wrap">
        <el-transfer
          filterable
          v-model="leftCheckedColumn"
          @left-check-change="handleLeftCheck"
          :data="originLeftData"
          :titles="['固定左边', '固定左边']"
          :props="{
            key: 'colId',
            label: 'headerName',
          }"
        ></el-transfer>
        <div style="padding: 20px">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-arrow-left"
            @click="handleTransferClick(1)"
            :disabled="centerChecked.length === 0"
          ></el-button>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-arrow-right"
            @click="handleTransferClick(2)"
            :disabled="leftChecked.length === 0"
          ></el-button>
        </div>
        <el-transfer
          filterable
          v-model="centerCheckedColumn"
          @left-check-change="handleCenterCheck"
          :data="originCenterData"
          :titles="['非固定列', '非固定列']"
          :props="{
            key: 'colId',
            label: 'headerName',
          }"
        ></el-transfer>
        <div style="padding: 20px">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-arrow-left"
            @click="handleTransferClick(3)"
            :disabled="rightChecked.length === 0"
          ></el-button>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-arrow-right"
            @click="handleTransferClick(4)"
            :disabled="centerChecked.length === 0"
          ></el-button>
        </div>
        <el-transfer
          filterable
          v-model="rightCheckedColumn"
          @left-check-change="handleRightCheck"
          :data="originRightData"
          :titles="['固定右边', '固定右边']"
          :props="{
            key: 'colId',
            label: 'headerName',
          }"
        ></el-transfer>
      </div>
      <template #footer>
        <el-button size="small" @click="cancel">取消所有固定</el-button>
        <el-button type="primary" size="small" @click="handleSubmit"
          >确定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  computed: {
    isShow: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  data () {
    return {
      rightCheckedColumn: [],
      centerCheckedColumn: [],
      leftCheckedColumn: [],
      // 勾选
      leftChecked: [],
      centerChecked: [],
      rightChecked: [],
      originLeftData: [],
      originCenterData: [],
      originRightData: []
    }
  },
  created () {
    this.originLeftData = this.data.filter(item => item.pinned === 'left')
    this.originCenterData = this.data.filter(item => !item.pinned)
    this.originRightData = this.data.filter(item => item.pinned === 'right')
  },
  methods: {
    handleLeftCheck (keys) {
      this.leftChecked = keys
    },
    handleCenterCheck (keys) {
      this.centerChecked = keys
    },
    handleRightCheck (keys) {
      this.rightChecked = keys
    },
    handleTransferClick (index) {
      if (index === 1) {
        this.originLeftData = [...this.originLeftData, ...this.originCenterData.filter(item => this.centerChecked.includes(item.colId))]
        this.originCenterData = this.originCenterData.filter(item => !this.centerChecked.includes(item.colId))
        this.centerChecked = []
      }
      if (index === 2) {
        this.originCenterData = [...this.originCenterData, ...this.originLeftData.filter(item => this.leftChecked.includes(item.colId))]
        this.originLeftData = this.originLeftData.filter(item => !this.leftChecked.includes(item.colId))
        this.leftChecked = []
      }
      if (index === 3) {
        this.originCenterData = [...this.originCenterData, ...this.originRightData.filter(item => this.rightChecked.includes(item.colId))]
        this.originRightData = this.originRightData.filter(item => !this.rightChecked.includes(item.colId))
        this.rightChecked = []
      }
      if (index === 4) {
        this.originRightData = [...this.originRightData, ...this.originCenterData.filter(item => this.centerChecked.includes(item.colId))]
        this.originCenterData = this.originCenterData.filter(item => !this.centerChecked.includes(item.colId))
        this.centerChecked = []
      }
    },
    cancel () {
      const column = this.data.map(item => {
        return {
          colId: item.colId,
          pinned: null
        }
      })
      this.$emit('display-change', column)
      this.isShow = false
    },
    handleSubmit () {
      const left = this.originLeftData.map(item => {
        return {
          colId: item.colId,
          pinned: 'left'
        }
      })
      const center = this.originCenterData.map(item => {
        return {
          colId: item.colId,
          pinned: null
        }
      })
      const right = this.originRightData.map(item => {
        return {
          colId: item.colId,
          pinned: 'right'
        }
      })
      this.$emit('display-change', [...left, ...center, ...right])
      this.isShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.el-link {
  font-size: 12px;
  line-height: normal;

  & + .el-link {
    margin-left: 10px;
  }
}

::v-deep .custompinned_dialog {
  .el-dialog__body {
    padding-top: 10px !important;
  }

  .trans_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .el-transfer-panel__body {
    height: calc(100vh - 300px);
  }

  .el-transfer__buttons {
    display: none;
  }

  .el-transfer {
    .el-transfer-panel {
      display: none;

      &:first-child {
        display: inline-block;
      }
    }
  }

  .el-transfer-panel__list.is-filterable {
    height: calc(100vh - 352px);
  }
}
</style>
