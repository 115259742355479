import BackLayout from '@/views/back/LayoutView'
const YYPLAT = [
  {
    path: '/memberindex',
    component: BackLayout,
    meta: {
      keys: ['memberindex']
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '后台首页',
          icon: 'icon-home',
          keys: ['memberindex']
        },
        component: () => import(/* webpackChunkName: "memberindex" */ '@/views/back/dashboard/yyplat/IndexView')
      }
    ]
  },
  {
    path: '/viraccountset',
    component: BackLayout,
    meta: {
      title: '个人账户设置',
      icon: 'icon-Union-5',
      keys: ['viraccountset']
    },
    alwaysShow: true,
    children: [
      {
        path: 'accbaseinfo',
        meta: {
          authorities: 'login',
          title: '个人基本信息',
          keys: ['accbaseinfo']
        },
        component: () => import(/* webpackChunkName: "viraccountset-accbaseinfo" */ '@/views/person/basexx/IndexView')
      },
      {
        path: 'cardxx',
        meta: {
          title: '身份基本信息',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-cardxx" */ '@/views/person/basexx/IDCardView')
      },
      {
        path: 'mobmgr',
        meta: {
          title: '修改手机号',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-mobmgr" */ '@/views/person/basexx/phone/IndexView')
      },
      {
        path: 'person',
        meta: {
          title: '实名认证',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-person" */ '@/views/person/certification/person/IndexView')
      },
      {
        path: 'company',
        meta: {
          title: '企业真实性验证',
          activeMenu: '/viraccountset/company',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-company" */ '@/views/person/certification/company/IndexView')
      },
      {
        path: 'modpassword',
        meta: {
          authorities: 'login',
          title: '修改密码',
          keys: ['modpassword']
        },
        component: () => import(/* webpackChunkName: "viraccountset-modpassword" */ '@/views/person/password/IndexView')
      }
    ]
  },
  {
    path: '/sjzjrzmgr',
    component: BackLayout,
    meta: {
      keys: ['sjzjrzmgr'],
      title: '司机管理中心'
    },
    hidden: true,
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '司机管理中心',
          breadcrumb: false,
          icon: 'icon-sijiguanli',
          keys: ['sjzjrzmgr']
        },
        redirect: '/virrzmgr/sjzjrzmgr',
        component: () => import(/* webpackChunkName: "sjzjrzmgr-" */ '@/views/back/driver/IndexView')
      },
      {
        path: 'detail',
        meta: {
          title: '认证信息',
          activeMenu: '/virrzmgr/sjzjrzmgr',
          authorities: 'login',
          keys: ['sjzjrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "sjzjrzmgr-detail" */ '@/views/back/driver/DetailView')
      }
    ]
  },
  {
    path: '/virhzdjrzmgr',
    component: BackLayout,
    meta: {
      title: '货主管理中心',
      icon: 'icon-huozhuguanli',
      keys: ['virhzdjrzmgr']
    },
    hidden: true,
    children: [
      {
        path: 'qyhzrzmgr',
        meta: {
          authorities: 'login',
          title: '企业货主',
          keys: ['qyhzrzmgr']
        },
        redirect: '/virrzmgr/virhzdjrzmgr/qyhzrzmgr',
        component: () => import(/* webpackChunkName: "virhzdjrzmgr-qyhzrzmgr" */ '@/views/back/hzdjrz/list/CompView')
      },
      {
        path: 'compdetail',
        meta: {
          title: '查看认证信息',
          authorities: 'login',
          activeMenu: '/virrzmgr/virhzdjrzmgr/qyhzrzmgr',
          keys: ['qyhzrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virhzdjrzmgr-compdetail" */ '@/views/back/hzdjrz/detail/CompDetail')
      },
      {
        path: 'grhzrzmgr',
        meta: {
          authorities: 'login',
          title: '个体货主',
          keys: ['grhzrzmgr']
        },
        redirect: '/virrzmgr/virhzdjrzmgr/grhzrzmgr',
        component: () => import(/* webpackChunkName: "virhzdjrzmgr-grhzrzmgr" */ '@/views/back/hzdjrz/list/PersonView')
      },
      {
        path: 'persondetail',
        meta: {
          title: '查看认证信息',
          authorities: 'login',
          activeMenu: '/virrzmgr/virhzdjrzmgr/grhzrzmgr',
          keys: ['grhzrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "grhzrzmgr-persondetail" */ '@/views/back/hzdjrz/detail/PersonDetail')
      }
    ]
  },
  {
    path: '/wlsjrzmgr',
    component: BackLayout,
    hidden: true,
    meta: {
      title: '物流世家认证',
      keys: ['wlsjrzmgr'],
      authorities: 'login'
    },
    children: [
      {
        path: '',
        meta: {
          title: '物流世家认证',
          icon: 'icon-xiaoxiwuliu',
          keys: ['wlsjrzmgr'],
          authorities: 'login'
        },
        redirect: '/virrzmgr/wlsjrzmgr',
        component: () => import(/* webpackChunkName: "wlsjrzmgr" */ '@/views/back/wlsjrz/list/IndexView')
      }
    ]
  },
  {
    path: '/virrzmgr',
    component: BackLayout,
    meta: {
      title: '认证管理中心',
      icon: 'icon-identification',
      keys: ['virrzmgr']
    },
    // alwaysShow: true,
    // 认证详情，要兼容admin菜单，不展示
    // hidden: true,
    children: [
      {
        path: 'sjzjrzmgr',
        name: 'driverCheckList',
        meta: {
          keys: ['sjzjrzmgr'],
          title: '司机之家认证',
          authorities: 'login'
        },
        component: () => import(/* webpackChunkName: "sjzjrzmgr-" */ '@/views/back/driver/IndexView')
      },
      // 针对嵌套路由，没法增加router-view增加
      {
        path: 'virhzdjrzmgr/qyhzrzmgr',
        name: 'CompRZList',
        meta: {
          keys: ['qyhzrzmgr'],
          title: '企业货主',
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virhzdjrzmgr-qyhzrzmgr" */ '@/views/back/hzdjrz/list/CompView')
      },
      // 针对嵌套路由，没法增加router-view增加
      {
        path: 'virhzdjrzmgr/grhzrzmgr',
        name: 'PersonRZList',
        meta: {
          keys: ['grhzrzmgr'],
          title: '个体货主',
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virhzdjrzmgr-grhzrzmgr" */ '@/views/back/hzdjrz/list/PersonView')
      },
      // 路由无效，仅用于菜单列表展示
      {
        path: 'virhzdjrzmgr',
        meta: {
          keys: ['virhzdjrzmgr'],
          title: '货主帝家认证'
        },
        children: [
          {
            path: 'qyhzrzmgr',
            meta: {
              authorities: 'login',
              title: '企业货主',
              keys: ['qyhzrzmgr']
            }
          },
          {
            path: 'grhzrzmgr',
            meta: {
              authorities: 'login',
              title: '个体货主',
              keys: ['grhzrzmgr']
            }
          }
        ]
      },
      {
        path: 'wlsjrzmgr',
        meta: {
          title: '物流世家认证',
          keys: ['wlsjrzmgr'],
          authorities: 'login'
        },
        component: () => import(/* webpackChunkName: "wlsjrzmgr" */ '@/views/back/wlsjrz/list/IndexView')
      },
      {
        path: 'ccgjrzmgr',
        meta: {
          title: '仓储管家认证',
          authorities: 'login',
          keys: ['ccgjrzmgr']
        },
        component: () => import(/* webpackChunkName: "ccgjrzmgr" */ '@/views/back/ccgjrz/list/IndexView')
      },
      {
        path: 'wlsjdetail',
        meta: {
          title: '查看详情',
          keys: ['wlsjrzmgr'],
          authorities: 'login',
          activeMenu: '/virrzmgr/wlsjrzmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "wlsjdetail" */ '@/views/back/wlsjrz/detail/IndexView')
      },
      {
        path: 'ccgjdetail',
        meta: {
          title: '查看详情',
          authorities: 'login',
          keys: ['ccgjrzmgr'],
          activeMenu: '/virrzmgr/ccgjrzmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "ccgjdetail" */ '@/views/back/ccgjrz/detail/IndexView')
      }
    ]
  },
  {
    path: '/ccgjrzmgr',
    component: BackLayout,
    meta: {
      title: '仓储管家认证',
      authorities: 'login',
      keys: ['ccgjrzmgr']
    },
    hidden: true,
    children: [
      {
        path: '',
        meta: {
          title: '仓储管家认证',
          icon: 'icon-huozhuguanli1',
          authorities: 'login',
          keys: ['ccgjrzmgr']
        },
        redirect: '/virrzmgr/ccgjrzmgr',
        component: () => import(/* webpackChunkName: "ccgjrzmgr" */ '@/views/back/ccgjrz/list/IndexView')
      }
    ]
  },
  {
    path: '/certifymgr',
    component: BackLayout,
    meta: {
      keys: ['certifymgr'],
      title: '证件管理中心'
    },
    children: [
      {
        path: '',
        name: 'certifymgrList',
        meta: {
          authorities: 'login',
          title: '证件管理中心',
          breadcrumb: false,
          icon: 'icon-zhengjian',
          keys: ['certifymgr']
        },
        component: () => import(/* webpackChunkName: "certifymgr-" */ '@/views/back/card/IndexView')
      },
      {
        path: 'detail',
        meta: {
          title: '证件信息',
          activeMenu: '/certifymgr',
          authorities: 'login',
          keys: ['certifymgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "certifymgr-detail" */ '@/views/back/card/DetailView')
      },
      {
        path: 'roadlic',
        meta: {
          title: '道路运输证',
          activeMenu: '/certifymgr',
          authorities: 'login',
          keys: ['certifymgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "certifymgr-roadlic" */ '@/views/back/truck/detail/roadlic/IndexView')
      },
      {
        path: 'vehlic',
        meta: {
          title: '行驶证',
          activeMenu: '/certifymgr',
          authorities: 'login',
          keys: ['certifymgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "certifymgr-vehlic" */ '@/views/back/truck/detail/vehlic/IndexView')
      },
      {
        path: 'jyxkz',
        meta: {
          title: '道路运输经营许可证',
          activeMenu: '/certifymgr',
          authorities: 'login',
          keys: ['certifymgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "certifymgr-jyxkz" */ '@/views/back/truck/detail/jyxkz/IndexView')
      }
    ]
  },
  {
    path: '/virtruckmgr',
    redirect: '/virtruckmgr/truckmgr',
    component: BackLayout,
    meta: {
      title: '车辆管理中心',
      icon: 'icon-cheliangguanli',
      keys: ['virtruckmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'truckmgr',
        name: 'truckmgrList',
        meta: {
          authorities: 'login',
          title: '车辆台帐',
          keys: ['truckmgr']
        },
        component: () => import(/* webpackChunkName: "virtruckmgr-truckmgr" */ '@/views/back/truck/yyplat/list/IndexView')
      },
      {
        path: 'truckcheck',
        meta: {
          authorities: 'login',
          title: '车辆审核',
          keys: ['truckmgr'],
          activeMenu: '/virtruckmgr/truckmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virtruckmgr-truckcheck" */ '@/views/back/truck/yyplat/check/IndexView')
      },
      {
        path: 'truckxx/:type',
        meta: {
          authorities: 'login',
          title: '车辆编辑',
          keys: ['truckmgr'],
          activeMenu: '/virtruckmgr/truckmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virtruckmgr-truckcheck" */ '@/views/back/truck/yyplat/check/IndexView')
      },
      {
        path: 'blacklist',
        meta: {
          authorities: 'login',
          title: '车辆黑名单',
          activeMenu: '/virtruckmgr/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virtruckmgr-blacklist" */ '@/views/back/truck/yyplat/blacklist/IndexView')
      },
      {
        path: 'limitlist',
        meta: {
          authorities: 'login',
          title: '受限车辆',
          activeMenu: '/virtruckmgr/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virtruckmgr-limitlist" */ '@/views/back/truck/yyplat/limitlist/IndexView')
      },
      {
        path: 'realpos',
        meta: {
          authorities: 'login',
          title: '最新位置查询',
          keys: ['realpos']
        },
        component: () => import(/* webpackChunkName: "realpos" */ '@/views/back/realpos/IndexView')
      },
      {
        path: 'histrack',
        meta: {
          authorities: 'login',
          title: '历史轨迹查询',
          keys: ['histrack']
        },
        component: () => import(/* webpackChunkName: "histrack" */ '@/views/back/histrack/IndexView')
      }
    ]
  },
  {
    path: '/truckmgr',
    redirect: '/virtruckmgr/truckmgr',
    meta: {
      keys: ['truckmgr'],
      title: '车辆管理中心'
    },
    hidden: true,
    component: BackLayout,
    children: [
      {
        path: 'detail',
        redirect: '/virtruckmgr/truckmgr',
        meta: {
          authorities: 'login',
          title: '车辆台账',
          activeMenu: '/virtruckmgr/truckmgr',
          keys: ['truckmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "truckmgr-detail" */ '@/views/back/truck/detail/IndexView'),
        children: [
          {
            path: 'base',
            meta: {
              authorities: 'login',
              title: '基本信息',
              activeMenu: '/virtruckmgr/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-base" */ '@/views/back/truck/detail/base/IndexView')
          },
          {
            path: 'vehlic',
            meta: {
              authorities: 'login',
              title: '行驶证',
              activeMenu: '/virtruckmgr/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-vehlic" */ '@/views/back/truck/detail/vehlic/IndexView')
          },
          {
            path: 'roadlic',
            meta: {
              authorities: 'login',
              title: '道路运输证',
              activeMenu: '/virtruckmgr/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-roadlic" */ '@/views/back/truck/detail/roadlic/IndexView')
          },
          {
            path: 'jyxkz',
            meta: {
              authorities: 'login',
              title: '道路运输经营许可证',
              activeMenu: '/virtruckmgr/truckmgr',
              keys: ['truckmgr']
            },
            component: () => import(/* webpackChunkName: "truckmgr-detail-jyxkz" */ '@/views/back/truck/detail/jyxkz/IndexView')
          }
        ]
      }
    ]
  },
  {
    path: '/tksrcmgr',
    component: BackLayout,
    meta: {
      keys: ['tksrcmgr'],
      title: '车源管理中心'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '车源管理中心',
          breadcrumb: false,
          icon: 'icon-cheyuanguanli',
          keys: ['tksrcmgr']
        },
        component: () => import(/* webpackChunkName: "tksrcmgr" */ '@/views/back/tksrc/yyplat/list/IndexView')
      }
    ]
  },
  {
    path: '/gdsrcmgr',
    component: BackLayout,
    meta: {
      keys: ['gdsrcmgr'],
      title: '货源管理中心'
    },
    children: [
      {
        path: '',
        name: 'GoodListMgr',
        meta: {
          authorities: 'login',
          title: '货源管理中心',
          keys: ['gdsrcmgr'],
          breadcrumb: false,
          icon: 'icon-Union1'
        },
        component: () => import(/* webpackChunkName: "gdsrcmgr-" */ '@/views/back/goods/list/IndexView')
      },
      {
        path: 'agree',
        meta: {
          title: '货物委托协议',
          authorities: 'login',
          activeMenu: '/gdsrcmgr',
          keys: ['gdsrcmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "gdsrcmgr-agree" */ '@/views/back/order/agree/IndexView')
      }
    ]
  },
  {
    path: '/collect',
    component: BackLayout,
    meta: {
      title: '收藏管理中心'
    },
    hidden: true,
    children: [
      {
        path: 'tklist',
        meta: {
          authorities: 'login',
          title: '车源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-tklist" */ '@/views/back/collect/tklist/IndexView')
      },
      {
        path: 'gdlist',
        meta: {
          authorities: 'login',
          title: '货源收藏',
          activeMenu: '/memberindex'
        },
        component: () => import(/* webpackChunkName: "collect-gdlist" */ '@/views/back/collect/gdlist/IndexView')
      }
    ]
  },
  {
    path: '/virordermgr',
    component: BackLayout,
    meta: {
      title: '订单管理中心',
      icon: 'icon-Union-2',
      keys: ['virordermgr']
    },
    children: [
      {
        path: 'wtordermgr',
        meta: {
          authorities: 'login',
          title: '货源委托',
          keys: ['wtordermgr']
        },
        component: () => import(/* webpackChunkName: "virordermgr-wtordermgr" */ '@/views/back/order/list/IndexView')
      },
      {
        path: 'detail',
        meta: {
          title: '查看详情',
          authorities: 'login',
          activeMenu: '/virordermgr/wtordermgr',
          keys: ['wtordermgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virordermgr-detail" */ '@/views/back/order/detail/IndexView')
      },
      {
        path: 'psordermgr',
        name: 'OnlineOrderMgr',
        meta: {
          authorities: 'login',
          title: '网络货运',
          keys: ['psordermgr']
        },
        component: () => import(/* webpackChunkName: "virordermgr-psordermgr" */ '@/views/back/psorder/yyplat/list/IndexView')
      },
      {
        path: 'psdetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['psordermgr'],
          activeMenu: '/virordermgr/psordermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virordermgr-psdetail" */ '@/views/back/psorder/yyplat/detail/IndexView')
      },
      {
        path: 'psagree',
        meta: {
          title: '货物运输协议',
          authorities: 'login',
          activeMenu: '/virordermgr/psordermgr',
          keys: ['psordermgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virordermgr-psordermgr" */ '@/views/back/psorder/yyplat/agree/IndexView')
      },
      {
        path: 'offlineordermgr',
        name: 'OfflineOrderMgr',
        meta: {
          authorities: 'login',
          title: '普通货运',
          keys: ['otherordermgr']
        },
        component: () => import(/* webpackChunkName: "virordermgr-offlineordermgr" */ '@/views/back/psorder/yyplat/list/OfflineView')
      },
      {
        path: 'offlinedetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['otherordermgr'],
          activeMenu: '/virordermgr/offlineordermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virordermgr-psdetail" */ '@/views/back/psorder/yyplat/detail/OfflineView')
      },
      {
        path: 'myordermgr',
        name: 'MyOrderMgr',
        meta: {
          authorities: 'login',
          title: '我的运单',
          keys: ['myordermgr']
        },
        component: () => import(/* webpackChunkName: "virordermgr-offlineordermgr" */ '@/views/back/psorder/yyplat/list/SelfView')
      },
      {
        path: 'myorderdetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['myordermgr'],
          activeMenu: '/virordermgr/myordermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "virordermgr-psdetail" */ '@/views/back/psorder/yyplat/detail/OfflineView')
      },
      {
        path: 'sendcar',
        meta: {
          authorities: 'login',
          title: '我要派车',
          keys: ['myordermgr'],
          activeMenu: '/virordermgr/myordermgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "myorders-sendcar" */ '@/views/back/psorder/yyplat/sendcar/IndexView')
      }
    ]
  },
  {
    path: '/enquirymgr',
    component: BackLayout,
    meta: {
      keys: ['virordermgr'],
      title: '询价管理中心'
    },
    children: [
      {
        path: '',
        name: 'EnquiryListMgr',
        meta: {
          authorities: 'login',
          title: '询价管理中心',
          breadcrumb: false,
          icon: 'icon-Union1',
          keys: ['virordermgr']
        },
        component: () => import(/* webpackChunkName: "gdsrcmgr" */ '@/views/back/enquiry/list/IndexView')
      }
    ]
  },
  {
    path: '/virjsmgr',
    component: BackLayout,
    meta: {
      title: '结算管理中心',
      icon: 'icon-bulujiesuan',
      keys: ['virjsmgr']
    },
    alwaysShow: true,
    children: [
      // {
      //   path: 'reconciliation',
      //   name: 'ReconciliationComponent',
      //   meta: {
      //     authorities: 'login',
      //     title: '对账管理',
      //     keys: ['dzordermgr']
      //   },
      //   component: () => import(/* webpackChunkName: "reconciliation" */ '@/views/back/reconciliation/yyplat/list/IndexView')
      // },
      {
        path: 'recon-order-list',
        name: 'ReconOrderList',
        meta: {
          authorities: 'login',
          title: '订单台账',
          keys: ['dzordermgr']
        },
        component: () => import(/* webpackChunkName: "recon-order-list" */ '@/views/back/reconciliation/new/yyplat/orderlist/IndexView')
      },
      {
        path: 'recon-order-list-detail',
        meta: {
          authorities: 'login',
          title: '订单台账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/recon-order-list'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "recon-new-detail" */ '@/views/back/reconciliation/new/yyplat/orderlist/DetailView')
      },
      {
        path: 'reconciliation-new',
        name: 'ReconciliationNew',
        meta: {
          authorities: 'login',
          title: '对账管理',
          keys: ['dzordermgr']
        },
        component: () => import(/* webpackChunkName: "reconciliation-new" */ '@/views/back/reconciliation/new/yyplat/list/IndexView')
      },
      {
        path: 'recon-new-detail',
        meta: {
          authorities: 'login',
          title: '对账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation-new'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "recon-new-detail" */ '@/views/back/reconciliation/new/yyplat/list/DetailView')
      },
      {
        path: 'reconciliation-configure',
        meta: {
          authorities: 'login',
          title: '对账配置',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation-new'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "reconciliation-configure" */ '@/views/back/reconciliation/yyplat/configure/IndexView')
      },
      {
        path: 'reconciliation-apply',
        meta: {
          authorities: 'login',
          title: '发起对账',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "reconciliation-apply" */ '@/views/back/reconciliation/yyplat/apply/IndexView')
      },
      {
        path: 'reconciliation-detail',
        meta: {
          authorities: 'login',
          title: '对账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "reconciliation-detail" */ '@/views/back/reconciliation/yyplat/detail/IndexView')
      },
      {
        path: 'reconciliation-detail2',
        meta: {
          authorities: 'login',
          title: '对账详情',
          keys: ['dzordermgr'],
          activeMenu: '/virjsmgr/reconciliation'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "reconciliation-detail2" */ '@/views/back/reconciliation/yyplat/detail/DetailView')
      },
      {
        path: 'ordersettlemgr',
        meta: {
          authorities: 'login',
          title: '结算管理',
          keys: ['ordersettlemgr']
        },
        component: () => import(/* webpackChunkName: "ordersettlemgr" */ '@/views/back/ordersettle/IndexView')
      },
      {
        path: 'yzffkapp',
        name: 'PayApplyOrderMgr',
        meta: {
          authorities: 'login',
          title: '付款申请',
          keys: ['yzffkapp']
        },
        component: () => import(/* webpackChunkName: "ordersignmgr" */ '@/views/back/jsapply/payapply/IndexView')
      },
      {
        path: 'yzfskapp',
        name: 'RevApplyOrderMgr',
        meta: {
          authorities: 'login',
          title: '收款申请',
          keys: ['yzfskapp']
        },
        component: () => import(/* webpackChunkName: "ordersignmgr" */ '@/views/back/jsapply/revapply/IndexView')
      },
      {
        path: 'ordersignmgr',
        meta: {
          authorities: 'login',
          title: '签收管理',
          keys: ['ordersignmgr']
        },
        component: () => import(/* webpackChunkName: "ordersignmgr" */ '@/views/back/ordersign/yyplat/IndexView')
      },
      {
        path: 'ordersigndetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['ordersignmgr'],
          activeMenu: '/virjsmgr/ordersignmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "ordersigndetail" */ '@/views/back/ordersign/yyplat/detail/IndexView')
      },
      {
        path: 'yfjsmgr',
        meta: {
          authorities: 'login',
          title: '运费结算',
          keys: ['yfjsmgr']
        },
        component: () => import(/* webpackChunkName: "yfjsmgr" */ '@/views/back/yfjs/yyplat/list/IndexView')
      },
      {
        path: 'yfjsapply',
        meta: {
          authorities: 'login',
          title: '发起运费结算申请',
          keys: ['yfjsmgr'],
          activeMenu: '/virjsmgr/yfjsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "yfjsapply" */ '@/views/back/yfjs/yyplat/apply/IndexView')
      },
      {
        path: 'yfjsreapply',
        meta: {
          authorities: 'login',
          title: '重新提交申请',
          keys: ['yfjsmgr'],
          activeMenu: '/virjsmgr/yfjsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "yfjsreapply" */ '@/views/back/yfjs/yyplat/reapply/IndexView')
      },
      {
        path: 'yfjsdetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          keys: ['yfjsmgr'],
          activeMenu: '/virjsmgr/yfjsmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "yfjsdetail" */ '@/views/back/yfjs/yyplat/detail/IndexView')
      }
    ]
  },
  {
    path: '/vircapmgr',
    component: BackLayout,
    redirect: '/vircapmgr/mmtwallet',
    meta: {
      title: '资金管理中心',
      icon: 'icon-Vector1',
      keys: ['vircapmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'mmtwallet',
        meta: {
          authorities: 'login',
          title: '默默账簿',
          keys: ['mmtwallet']
        },
        component: () => import(/* webpackChunkName: "vircapmgr-mmtwallet" */ '@/views/person/wallet/yyplat/IndexView')
      },
      {
        path: 'walletlist',
        meta: {
          authorities: 'login',
          title: '用户信息查询',
          keys: ['mmtwallet'],
          activeMenu: '/vircapmgr/mmtwallet'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "vircapmgr-walletlist" */ '@/views/person/wallet/yyplat/customer/list/IndexView')
      },
      {
        path: 'tkmayermgr',
        meta: {
          authorities: 'login',
          title: '车队长管理',
          keys: ['tkmayermgr']
        },
        component: () => import(/* webpackChunkName: "vircapmgr-mmtwallet" */ '@/views/back/captain/yyplat/IndexView')
      },
      {
        path: 'vouchermgr',
        meta: {
          authorities: 'login',
          title: '凭证管理',
          keys: ['vouchermgr']
        },
        component: () => import(/* webpackChunkName: "vircapmgr-vouchermgr" */ '@/views/back/voucher/IndexView')
      },
      {
        path: 'oilcardmgr',
        name: 'OIL-CARD-LIST_MGR',
        meta: {
          authorities: 'login',
          title: '油卡管理',
          keys: ['vouchermgr']
        },
        component: () => import(/* webpackChunkName: "vircapmgr-oilcardmgr" */ '@/views/back/oilcard/IndexView')
      }
    ]
  },
  // {
  //   path: '/virbillmgr',
  //   component: BackLayout,
  //   meta: {
  //     keys: ['virhtbxmgr'],
  //     title: '发票管理中心',
  //     icon: 'icon-fapiao'
  //   },
  //   alwaysShow: true,
  //   children: [
  //     {
  //       path: 'etcmgrlist',
  //       name: 'ETC-TABLE-LIST_MGR',
  //       meta: {
  //         authorities: 'login',
  //         title: 'ETC发票申请',
  //         keys: ['vouchermgr']
  //       },
  //       component: () => import(/* webpackChunkName: "vircapmgr-etcmgrlist" */ '@/views/back/etclist/yyplat/IndexView')
  //     },
  //     {
  //       path: 'etcledgerlist',
  //       name: 'ETC-TABLE-LEDGER-LIST',
  //       meta: {
  //         authorities: 'login',
  //         title: 'ETC发票台账',
  //         keys: ['vouchermgr'],
  //         activeMenu: '/virbillmgr/etcmgrlist'
  //       },
  //       hidden: true,
  //       component: () => import(/* webpackChunkName: "vircapmgr-etcledgerlist" */ '@/views/back/etclist/yyplat/ledger/IndexView')
  //     },
  //     {
  //       path: 'driverbilllist',
  //       meta: {
  //         authorities: 'login',
  //         title: '司机运费发票',
  //         keys: ['vouchermgr']
  //       },
  //       component: () => import(/* webpackChunkName: "vircapmgr-driverbilllist" */ '@/views/back/billlist/driver/IndexView')
  //     }
  //   ]
  // },
  {
    path: '/virhtbxmgr',
    component: BackLayout,
    meta: {
      keys: ['virhtbxmgr'],
      title: '合同保险管理中心',
      icon: 'icon-baoxianhetong'
    },
    alwaysShow: true,
    children: [
      {
        path: 'contractmgr',
        meta: {
          authorities: 'login',
          title: '订单合同',
          keys: ['contractmgr']
        },
        component: () => import(/* webpackChunkName: "contractmgr" */ '@/views/back/contract/IndexView')
      },
      {
        path: 'framework-contractmgr',
        name: 'YYF_FRAMEWORK_CONTRACT_TABLE',
        meta: {
          authorities: 'login',
          title: '框架合同',
          keys: ['contractmgr']
        },
        component: () => import(/* webpackChunkName: "framework-contractmgr" */ '@/views/back/contract/framework/yyplat/IndexView')
      },
      {
        path: 'cargobxmgr',
        meta: {
          authorities: 'login',
          title: '保险管理',
          keys: ['cargobxmgr']
        },
        component: () => import(/* webpackChunkName: "cargobxmgr" */ '@/views/back/cargobx/IndexView')
      }
    ]
  },
  {
    path: '/virxypjmgr',
    component: BackLayout,
    meta: {
      title: '信用评价管理中心',
      icon: 'icon-xingji',
      keys: ['virxypjmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'creditstar',
        meta: {
          authorities: 'login',
          title: '信用星级',
          keys: ['creditstar']
        },
        component: () => import(/* webpackChunkName: "virxypjmgr-creditstar" */ '@/views/back/xypjmgr/CreditView')
      },
      {
        path: 'userevaluate',
        meta: {
          authorities: 'login',
          title: '用户评价',
          keys: ['userevaluate']
        },
        component: () => import(/* webpackChunkName: "virxypjmgr-userevaluate" */ '@/views/back/xypjmgr/EvalView')
      }
    ]
  },
  {
    path: '/zxtsmgr',
    component: BackLayout,
    meta: {
      keys: ['zxtsmgr'],
      title: '咨询投诉管理中心'
    },
    children: [
      {
        path: '',
        meta: {
          authorities: 'login',
          title: '咨询投诉管理中心',
          breadcrumb: false,
          icon: 'icon-zixun1',
          keys: ['zxtsmgr']
        },
        component: () => import(/* webpackChunkName: "zxtsmgr-" */ '@/views/back/zxtsmgr/IndexView')
      }
    ]
  },
  {
    path: '/virdatasync',
    component: BackLayout,
    meta: {
      title: '数据统计管理中心',
      icon: 'icon-filesync',
      keys: ['virdatasync']
    },
    children: [
      {
        path: 'synctruckxx',
        name: 'SYNC_DATA_TABLE_TRUCK',
        meta: {
          authorities: 'login',
          title: '车辆信息',
          keys: ['synctruckxx']
        },
        component: () => import(/* webpackChunkName: "synctruckxx" */ '@/views/back/syncdata/truck/list/IndexView')
      },
      {
        path: 'truckdetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          activeMenu: '/virdatasync/synctruckxx',
          keys: ['synctruckxx']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "synctruckxx-truckdetail" */ '@/views/back/syncdata/truck/detail/IndexView')
      },
      {
        path: 'syncdriverxx',
        name: 'SYNC_DATA_TABLE_DRIVER',
        meta: {
          authorities: 'login',
          title: '司机信息',
          keys: ['syncdriverxx']
        },
        component: () => import(/* webpackChunkName: "syncdriverxx" */ '@/views/back/syncdata/driver/list/IndexView')
      },
      {
        path: 'driverdetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          activeMenu: '/virdatasync/syncdriverxx',
          keys: ['syncdriverxx']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "syncdriverxx-driverdetail" */ '@/views/back/syncdata/driver/detail/IndexView')
      },
      {
        path: 'syncorderxx',
        name: 'SYNC_DATA_TABLE_ORDER',
        meta: {
          authorities: 'login',
          title: '运单信息',
          keys: ['syncorderxx']
        },
        component: () => import(/* webpackChunkName: "syncorderxx" */ '@/views/back/syncdata/order/list/IndexView')
      },
      {
        path: 'trailer',
        meta: {
          authorities: 'login',
          activeMenu: '/virdatasync/syncorderxx',
          title: '挂车补录',
          keys: ['syncorderxx']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "trailer" */ '@/views/back/syncdata/order/trailer/IndexView')
      },
      {
        path: 'exception',
        name: 'SYNC_DATA_TABLE_ORDER_ERROR',
        meta: {
          authorities: 'login',
          activeMenu: '/virdatasync/syncorderxx',
          title: '异常运单',
          keys: ['syncorderxx']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "exception" */ '@/views/back/syncdata/order/exception/IndexView')
      },
      {
        path: 'orderdetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          activeMenu: '/virdatasync/syncorderxx',
          keys: ['syncorderxx']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "syncorderxx-orderdetail" */ '@/views/back/syncdata/order/detail/IndexView')
      },
      {
        path: 'synccapxx',
        name: 'SYNC_DATA_TABLE_CAPITAL',
        meta: {
          authorities: 'login',
          title: '资金流水',
          keys: ['synccapxx']
        },
        component: () => import(/* webpackChunkName: "synccapxx" */ '@/views/back/syncdata/capital/list/IndexView')
      },
      {
        path: 'capdetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          activeMenu: '/virdatasync/synccapxx',
          keys: ['synccapxx']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "synccapxx-capdetail" */ '@/views/back/syncdata/capital/detail/IndexView')
      },
      {
        path: 'syncfilexx',
        meta: {
          authorities: 'login',
          title: '文件上传',
          keys: ['syncfilexx']
        },
        component: () => import(/* webpackChunkName: "syncfilexx" */ '@/views/back/syncdata/file/list/IndexView')
      },
      {
        path: 'filedetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          activeMenu: '/virdatasync/syncfilexx',
          keys: ['syncfilexx']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "syncfilexx-filedetail" */ '@/views/back/syncdata/file/detail/IndexView')
      },
      {
        path: 'synctrackxx',
        meta: {
          authorities: 'login',
          title: '轨迹上传',
          keys: ['synctrackxx']
        },
        component: () => import(/* webpackChunkName: "synctrackxx" */ '@/views/back/syncdata/track/list/IndexView')
      },
      {
        path: 'synctracklist',
        meta: {
          authorities: 'login',
          title: '轨迹列表',
          keys: ['synctrackxx'],
          activeMenu: '/virdatasync/synctrackxx'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "synctracklist" */ '@/views/back/syncdata/track/list/TrackList')
      },
      {
        path: 'trackdetail',
        meta: {
          authorities: 'login',
          title: '查看详情',
          activeMenu: '/virdatasync/synctrackxx',
          keys: ['synctrackxx']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "synctrackxx-trackdetail" */ '@/views/back/syncdata/track/detail/IndexView')
      },
      {
        path: 'driver-position',
        name: 'SYNC_DATA_TABLE_DRIVER_POSITION',
        meta: {
          authorities: 'login',
          title: '司机位置信息',
          activeMenu: '/virdatasync/synctrackxx',
          keys: ['synctrackxx']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "driver-position" */ '@/views/back/syncdata/track/driver-position/IndexView')
      }
    ]
  },
  {
    path: '/virregyjmgr',
    component: BackLayout,
    meta: {
      title: '区域运价管理',
      icon: 'icon-quyuyunjia',
      keys: ['virregyjmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'lineclaimmgr',
        meta: {
          title: '线路认领权管理',
          authorities: 'login',
          keys: ['lineclaimmgr']
        },
        component: () => import(/* webpackChunkName: "aiworkbook-linelist" */ '@/views/back/aiworkbook/linelist/IndexView')
      },
      {
        path: 'yfpricechk',
        meta: {
          title: '物流运价审批',
          authorities: 'login',
          keys: ['yfpricechk']
        },
        component: () => import(/* webpackChunkName: "aiworkbook-wlyjcheck" */ '@/views/back/aiworkbook/wlyjcheck/IndexView')
      },
      {
        path: 'linepowerset',
        meta: {
          title: '线路权限配置',
          authorities: 'login',
          keys: ['linepowerset']
        },
        component: () => import(/* webpackChunkName: "aiworkbook-lineauth" */ '@/views/back/aiworkbook/lineauth/IndexView')
      },
      {
        path: 'yfpriceacc',
        meta: {
          title: '物流运价台帐',
          authorities: 'login',
          keys: ['yfpriceacc']
        },
        component: () => import(/* webpackChunkName: "aiworkbook-wlyjlist" */ '@/views/back/aiworkbook/wlyjlist/IndexView')
      }
    ]
  },
  {
    path: '/virinvenmgr',
    component: BackLayout,
    meta: {
      title: '库存管理中心',
      icon: 'icon-cangkuguanli',
      keys: ['virinvenmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'realinven',
        meta: {
          authorities: 'login',
          title: '实时库存',
          keys: ['realinven'],
          iframeSrc: '/plugins/whserv/project1/page/ssStock4yyf/index.html'
        },
        component: () => import(/* webpackChunkName: "realinven" */ '@/views/back/warehouse/iframe/IndexView')
      },
      {
        path: 'invenaccount',
        meta: {
          authorities: 'login',
          title: '库存台帐',
          keys: ['invenaccount'],
          iframeSrc: '/plugins/whserv/project1/page/stockTable4yyf/index4wldw.html'
        },
        component: () => import(/* webpackChunkName: "invenaccount" */ '@/views/back/warehouse/iframe/IndexView')
      }
    ]
  },
  {
    path: '/virsysmgr',
    component: BackLayout,
    meta: {
      keys: ['virsysmgr'],
      title: '系统管理中心',
      icon: 'icon-Union'
    },
    alwaysShow: true,
    children: [
      {
        path: 'deptmgr',
        meta: {
          authorities: 'login',
          title: '部门管理',
          keys: ['deptmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-deptmgr" */ '@/views/back/department/IndexView')
      },
      {
        path: 'staffmgr',
        meta: {
          authorities: 'login',
          title: '员工账号管理',
          keys: ['staffmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-staffmgr" */ '@/views/back/compuser/IndexView')
      },
      {
        path: 'flowmgr',
        meta: {
          authorities: 'login',
          title: '流程管理',
          keys: ['flowmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-flowmgr" */ '@/views/back/flow/IndexView')
      },
      {
        path: 'newmgr',
        meta: {
          authorities: 'login',
          title: '资讯管理',
          keys: ['newmgr']
        },
        component: () => import(/* webpackChunkName: "virsysmgr-newmgr" */ '@/views/back/news/list/IndexView')
      },
      {
        path: 'pubnews',
        meta: {
          title: '发布资讯',
          authorities: 'login',
          keys: ['newmgr'],
          activeMenu: '/virsysmgr/newmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "pubnews" */ '@/views/back/news/publish/IndexView')
      },
      {
        path: 'editnews',
        meta: {
          title: '编辑资讯',
          authorities: 'login',
          keys: ['newmgr'],
          activeMenu: '/virsysmgr/newmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "editnews" */ '@/views/back/news/detail/IndexView')
      }
    ]
  },
  {
    path: '/myexport',
    component: BackLayout,
    meta: {
    },
    hidden: true,
    children: [
      {
        path: '',
        meta: {
          title: '省监控数据导出'
        },
        component: () => import(/* webpackChunkName: "myexport" */ '@/views/back/truck/yyplat/list/ExportView')
      }
    ]
  }
]
export default YYPLAT
