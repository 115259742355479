import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/store/modules/user'
import router from '@/store/modules/router'
import getters from '@/store/getter'
import app from '@/store/modules/app'
import tagsView from '@/store/modules/tagsView'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters,
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    user,
    router,
    tagsView
  }
})
