import BackLayout from '@/views/back/LayoutView'
const MMTPT = [
  {
    path: '/memberindex',
    component: BackLayout,
    meta: {
      keys: ['memberindex']
    },
    children: [
      {
        path: '',
        meta: {
          title: '后台首页',
          icon: 'icon-home',
          authorities: 'login',
          keys: ['memberindex']
        },
        component: () => import(/* webpackChunkName: "memberindex" */ '@/views/back/dashboard/mmtpt/IndexView')
      }
    ]
  },
  // {
  //   path: '/viraccountset',
  //   meta: {
  //     title: '个人账户设置',
  //     icon: 'icon-Union-5',
  //     keys: ['viraccountset']
  //   },
  //   alwaysShow: true,
  //   component: BackLayout,
  //   children: [
  //     {
  //       path: 'accbaseinfo',
  //       meta: {
  //         authorities: 'login',
  //         title: '个人基本信息',
  //         keys: ['accbaseinfo']
  //       },
  //       component: () => import(/* webpackChunkName: "viraccountset-accbaseinfo" */ '@/views/person/basexx/IndexView')
  //     },
  //     {
  //       path: 'cardxx',
  //       meta: {
  //         title: '身份基本信息',
  //         activeMenu: '/viraccountset/accbaseinfo',
  //         keys: ['accbaseinfo'],
  //         authorities: 'login'
  //       },
  //       hidden: true,
  //       component: () => import(/* webpackChunkName: "viraccountset-cardxx" */ '@/views/person/basexx/IDCardView')
  //     },
  //     {
  //       path: 'mobmgr',
  //       meta: {
  //         title: '修改手机号',
  //         activeMenu: '/viraccountset/accbaseinfo',
  //         keys: ['accbaseinfo'],
  //         authorities: 'login'
  //       },
  //       hidden: true,
  //       component: () => import(/* webpackChunkName: "viraccountset-mobmgr" */ '@/views/person/basexx/phone/IndexView')
  //     },
  //     {
  //       path: 'modpassword',
  //       meta: {
  //         authorities: 'login',
  //         title: '修改密码',
  //         keys: ['modpassword']
  //       },
  //       component: () => import(/* webpackChunkName: "viraccountset-modpassword" */ '@/views/person/password/IndexView')
  //     }
  //   ]
  // },
  {
    path: '/usermgr',
    component: BackLayout,
    meta: {
      keys: ['usermgr'],
      title: '会员管理中心'
    },
    children: [
      {
        path: '',
        meta: {
          title: '会员管理中心',
          authorities: 'login',
          breadcrumb: false,
          icon: 'icon-Union-5',
          keys: ['usermgr']
        },
        component: () => import(/* webpackChunkName: "usermgr" */ '@/views/back/member/IndexView')
      }
    ]
  },
  {
    path: '/virrzmgr',
    redirect: '/virrzmgr/wlsjrzmgr',
    component: BackLayout,
    meta: {
      title: '认证管理中心',
      icon: 'icon-identification',
      keys: ['virrzmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'wlsjrzmgr',
        meta: {
          title: '物流世家认证',
          keys: ['wlsjrzmgr'],
          authorities: 'login'
        },
        component: () => import(/* webpackChunkName: "wlsjrzmgr" */ '@/views/back/wlsjrz/list/IndexView')
      },
      {
        path: 'wlsjdetail',
        meta: {
          title: '查看详情',
          keys: ['wlsjrzmgr'],
          authorities: 'login',
          activeMenu: '/virrzmgr/wlsjrzmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "wlsjdetail" */ '@/views/back/wlsjrz/detail/IndexView')
      },
      {
        path: 'ccgjrzmgr',
        meta: {
          title: '仓储管家认证',
          authorities: 'login',
          keys: ['ccgjrzmgr']
        },
        component: () => import(/* webpackChunkName: "ccgjrzmgr" */ '@/views/back/ccgjrz/list/IndexView')
      },
      {
        path: 'ccgjdetail',
        meta: {
          title: '查看详情',
          authorities: 'login',
          keys: ['ccgjrzmgr'],
          activeMenu: '/virrzmgr/ccgjrzmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "ccgjdetail" */ '@/views/back/ccgjrz/detail/IndexView')
      }
    ]
  },
  {
    path: '/apiunionmgr',
    component: BackLayout,
    meta: {
      keys: ['apiunionmgr'],
      title: 'API对接管理'
    },
    children: [
      {
        path: '',
        meta: {
          title: 'API对接管理',
          icon: 'icon-API',
          keys: ['apiunionmgr'],
          authorities: 'login'
        },
        component: () => import(/* webpackChunkName: "apiunionmgr" */ '@/views/back/apiunion/list/IndexView')
      },
      {
        path: 'pushlist',
        meta: {
          title: 'API推送详情',
          icon: 'icon-API',
          keys: ['apiunionmgr'],
          authorities: 'login',
          activeMenu: '/apiunionmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "apiunionmgr-pushlist" */ '@/views/back/apiunion/detail/IndexView')
      }
    ]
  },
  {
    path: '/virregyjmgr',
    component: BackLayout,
    meta: {
      title: '区域运价管理',
      icon: 'icon-quyuyunjia',
      keys: ['virregyjmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'lineclaimmgr',
        meta: {
          title: '线路认领权管理',
          authorities: 'login',
          keys: ['lineclaimmgr']
        },
        component: () => import(/* webpackChunkName: "aiworkbook-linelist" */ '@/views/back/aiworkbook/linelist/IndexView')
      },
      {
        path: 'yfpricechk',
        meta: {
          title: '物流运价审批',
          authorities: 'login',
          keys: ['yfpricechk']
        },
        component: () => import(/* webpackChunkName: "aiworkbook-wlyjcheck" */ '@/views/back/aiworkbook/wlyjcheck/IndexView')
      },
      {
        path: 'linepowerset',
        meta: {
          title: '线路权限配置',
          authorities: 'login',
          keys: ['linepowerset']
        },
        component: () => import(/* webpackChunkName: "aiworkbook-lineauth" */ '@/views/back/aiworkbook/lineauth/IndexView')
      },
      {
        path: 'yfpriceacc',
        meta: {
          title: '物流运价台帐',
          authorities: 'login',
          keys: ['yfpriceacc']
        },
        component: () => import(/* webpackChunkName: "aiworkbook-wlyjlist" */ '@/views/back/aiworkbook/wlyjlist/IndexView')
      }
    ]
  },
  {
    path: '/virtruckmgr',
    component: BackLayout,
    meta: {
      title: '车辆管理中心',
      icon: 'icon-cheliangguanli',
      keys: ['virtruckmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'realpos',
        meta: {
          title: '最新位置查询',
          authorities: 'login',
          keys: ['realpos']
        },
        component: () => import(/* webpackChunkName: "realpos" */ '@/views/back/realpos/IndexView')
      },
      {
        path: 'histrack',
        meta: {
          title: '历史轨迹查询',
          authorities: 'login',
          keys: ['histrack']
        },
        component: () => import(/* webpackChunkName: "histrack" */ '@/views/back/histrack/IndexView')
      }
    ]
  },
  {
    path: '/virsysmgr',
    component: BackLayout,
    meta: {
      title: '系统管理中心',
      icon: 'icon-Union',
      keys: ['virsysmgr']
    },
    alwaysShow: true,
    children: [
      {
        path: 'funcmgr',
        meta: {
          title: '权限管理',
          keys: ['funcmgr']
        },
        component: () => import(/* webpackChunkName: "basefuncmgr" */ '@/views/back/basefunc/IndexView')
      },
      {
        path: 'rolemgr',
        meta: {
          title: '角色配置',
          keys: ['rolemgr']
        },
        component: () => import(/* webpackChunkName: "rolefuncmgr" */ '@/views/back/rolefunc/IndexView')
      },
      {
        path: 'rolelevmgr',
        meta: {
          title: '等级配置',
          keys: ['rolelevmgr']
        },
        component: () => import(/* webpackChunkName: "levfuncmgr" */ '@/views/back/levfunc/IndexView')
      },
      {
        path: 'newfidmgr',
        meta: {
          title: '栏目管理',
          keys: ['newfidmgr']
        },
        component: () => import(/* webpackChunkName: "newfidmgr" */ '@/views/back/colums/list/IndexView')
      },
      {
        path: 'newmgr',
        meta: {
          title: '资讯管理',
          authorities: 'login',
          keys: ['newmgr']
        },
        component: () => import(/* webpackChunkName: "newmgr" */ '@/views/back/news/list/IndexView')
      },
      {
        path: 'pubnews',
        meta: {
          title: '发布资讯',
          authorities: 'login',
          keys: ['newmgr'],
          activeMenu: '/virsysmgr/newmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "pubnews" */ '@/views/back/news/publish/IndexView')
      },
      {
        path: 'editnews',
        meta: {
          title: '编辑资讯',
          authorities: 'login',
          keys: ['newmgr'],
          activeMenu: '/virsysmgr/newmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "editnews" */ '@/views/back/news/detail/IndexView')
      },
      {
        path: 'matmgr',
        meta: {
          title: '物料管理',
          iframeSrc: '/plugins/whserv/project1/page/MaterialMgr/weihuMgr4yg.html',
          keys: ['matmgr']
        },
        component: () => import(/* webpackChunkName: "whmgr" */ '@/views/back/warehouse/matmgr/IndexView')
      },
      {
        path: 'matapplymgr',
        meta: {
          authorities: 'login',
          title: '物料申请管理',
          keys: ['matmgr'],
          activeMenu: '/virsysmgr/matmgr'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "matapplymgr" */ '@/views/back/warehouse/matapplymgr/IndexView')
      },
      {
        path: 'pslinemgr',
        meta: {
          title: '配送线路管理',
          keys: ['pslinemgr']
        },
        component: () => import(/* webpackChunkName: "pslinemgr" */ '@/views/back/psline/IndexView')
      },
      {
        path: 'addpsline',
        meta: {
          title: '新增路线',
          activeMenu: '/virsysmgr/pslinemgr',
          keys: ['pslinemgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "addpsline" */ '@/views/back/psline/AddLine')
      }
    ]
  }
]
export default MMTPT
