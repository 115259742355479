import BackLayout from '@/views/back/LayoutView'
const PTHY = [
  {
    path: '/viraccountset',
    component: BackLayout,
    meta: {
      title: '个人账户设置',
      icon: 'icon-Union-5',
      keys: ['viraccountset']
    },
    alwaysShow: true,
    children: [
      {
        path: 'accbaseinfo',
        meta: {
          authorities: 'login',
          title: '个人基本信息',
          keys: ['accbaseinfo']
        },
        component: () => import(/* webpackChunkName: "viraccountset-accbaseinfo" */ '@/views/person/basexx/IndexView')
      },
      {
        path: 'cardxx',
        meta: {
          title: '身份基本信息',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-cardxx" */ '@/views/person/basexx/IDCardView')
      },
      {
        path: 'mobmgr',
        meta: {
          title: '修改手机号',
          activeMenu: '/viraccountset/accbaseinfo',
          keys: ['accbaseinfo'],
          authorities: 'login'
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-mobmgr" */ '@/views/person/basexx/phone/IndexView')
      },
      {
        path: 'myrzmgr',
        meta: {
          authorities: 'login',
          title: '我的认证',
          keys: ['myrzmgr']
        },
        component: () => import(/* webpackChunkName: "viraccountset-myrzmgr" */ '@/views/person/certification/ApplyView')
      },
      // {
      //   path: 'person',
      //   meta: {
      //     title: '实名认证',
      //     activeMenu: '/viraccountset/myrzmgr',
      //     keys: ['myrzmgr'],
      //     authorities: 'login'
      //   },
      //   hidden: true,
      //   component: () => import(/* webpackChunkName: "viraccountset-person" */ '@/views/person/certification/person/IndexView')
      // },
      // {
      //   path: 'company',
      //   meta: {
      //     title: '企业真实性验证',
      //     activeMenu: '/viraccountset/myrzmgr',
      //     keys: ['myrzmgr'],
      //     authorities: 'login'
      //   },
      //   hidden: true,
      //   component: () => import(/* webpackChunkName: "viraccountset-company" */ '@/views/person/certification/company/IndexView')
      // },
      {
        path: 'gdrz',
        redirect: 'gdrz/comp',
        meta: {
          title: '货主帝家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-gdrz" */ '@/views/person/certification/hzdj/IndexView'),
        children: [
          {
            path: 'comp',
            meta: {
              title: '企业货主认证',
              authorities: 'login',
              activeMenu: '/viraccountset/myrzmgr',
              keys: ['myrzmgr']
            },
            component: () => import(/* webpackChunkName: "viraccountset-gdrzcomp" */ '@/views/person/certification/hzdj/comp/IndexView')
          },
          {
            path: 'person',
            meta: {
              title: '个人货主认证',
              authorities: 'login',
              activeMenu: '/viraccountset/myrzmgr',
              keys: ['myrzmgr']
            },
            component: () => import(/* webpackChunkName: "viraccountset-gdrzperson" */ '@/views/person/certification/hzdj/user/IndexView')
          }
        ]
      },
      {
        path: 'driver',
        meta: {
          title: '司机之家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-driver" */ '@/views/person/certification/sjzj/IndexView')
      },
      {
        path: 'driverchk',
        meta: {
          title: '司机之家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-drivercheck" */ '@/views/person/certification/sjzj/ChkView')
      },
      {
        path: 'driverunchk',
        meta: {
          title: '司机之家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-driverunchk" */ '@/views/person/certification/sjzj/UnChkView')
      },
      {
        path: 'gdrzpersonchk',
        meta: {
          title: '货主帝家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-gdrzpersonchk" */ '@/views/person/certification/hzdj/user/ChkView')
      },
      {
        path: 'gdrzpersonunchk',
        meta: {
          title: '货主帝家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-gdrzpersonunchk" */ '@/views/person/certification/hzdj/user/UnChkView')
      },
      {
        path: 'gdrzcompchk',
        meta: {
          title: '货主帝家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-gdrzcompchk" */ '@/views/person/certification/hzdj/comp/ChkView')
      },
      {
        path: 'gdrzcompunchk',
        meta: {
          title: '货主帝家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-gdrzcompunchk" */ '@/views/person/certification/hzdj/comp/UnChkView')
      },
      {
        path: 'transport',
        meta: {
          title: '物流世家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-transport" */ '@/views/person/certification/wlsj/IndexView')
      },
      {
        path: 'transunchk',
        meta: {
          title: '物流世家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-transunchk" */ '@/views/person/certification/wlsj/UnChkView')
      },
      {
        path: 'transchk',
        meta: {
          title: '物流世家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-transchk" */ '@/views/person/certification/wlsj/ChkView')
      },
      {
        path: 'warehouse',
        meta: {
          title: '仓储管家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-warehouse" */ '@/views/person/certification/ccgj/IndexView')
      },
      {
        path: 'warehousechk',
        meta: {
          title: '仓储管家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-warehousechk" */ '@/views/person/certification/ccgj/ChkView')
      },
      {
        path: 'warehouseunchk',
        meta: {
          title: '仓储管家认证',
          authorities: 'login',
          activeMenu: '/viraccountset/myrzmgr',
          keys: ['myrzmgr']
        },
        hidden: true,
        component: () => import(/* webpackChunkName: "viraccountset-warehouseunchk" */ '@/views/person/certification/ccgj/UnChkView')
      },
      {
        path: 'modpassword',
        meta: {
          authorities: 'login',
          title: '修改密码',
          keys: ['modpassword']
        },
        component: () => import(/* webpackChunkName: "viraccountset-modpassword" */ '@/views/person/password/IndexView')
      }
    ]
  }
]
export default PTHY
