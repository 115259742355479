<template>
  <div>
    <el-input
      v-model.trim="currentValue"
      class="float-el-input"
      size="mini"
      prefix-icon="el-icon-search"
      clearable
      placeholder="搜索"
      @input="handleChange"
      @keyup.enter.native="handleEnterClick"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: 'CustomeTextFilterComponent',
  data () {
    return {
      currentValue: ''
    }
  },
  methods: {
    handleChange () {
      if (this.currentValue) {
        this.params.parentFilterInstance(instance => {
          instance.onFloatingFilterChanged('contains', this.currentValue)
        })
        return
      }
      this.params.parentFilterInstance(instance => {
        instance.onFloatingFilterChanged('contains', null)
      })
    },
    onParentModelChanged (parentModel, event) {
      // When the filter is empty we will receive a null value here
      if (!parentModel) {
        this.currentValue = ''
      } else {
        this.currentValue = parentModel.filter
      }
    },
    handleEnterClick () {
      this.params.enter()
      // this.$parent && this.$parent.$parent && this.$parent.$parent.handleEnterClick && this.$parent.$parent.handleEnterClick()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .float-el-input {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% - 1px);

  .el-input__inner {
    height: 29px;
    border-radius: 0;
    border-color: transparent;

    &:focus {
      border-color: #32b16c;
    }
  }
}
</style>
