import { getUserInfo, setUserInfo, removeUserInfo, getErrorTime, setErrorTime, removeErrorTime, getLastTime, setLastTime } from '@/untils/storage'
import { resetRouter } from '@/router'
import { Message } from 'element-ui'
import services from '@/services'

export default {
  state: {
    userInfo: {
      uid: '',
      domain: '',
      icon: '',
      username: '',
      session_id: '',
      groupid: '',
      groupname: '',
      allowoper: '',
      ...getUserInfo()
    },
    role: '',
    keys: [],
    lastTime: getLastTime(),
    errorTime: {
      time: Date.now(),
      number: 0,
      ...getErrorTime()
    }
  },
  getters: {
  },
  mutations: {
    SET_USERINFO (state, value = {}) {
      const info = { ...state.userInfo, ...value }
      state.userInfo = info
    },
    CLEAR_USERINFO (state) {
      state.userInfo = {
        uid: '',
        username: '',
        session_id: '',
        domain: '',
        icon: '',
        groupid: '',
        groupname: '',
        allowoper: ''
      }
    },
    SET_ROLE: (state, role) => {
      state.role = role
    },
    SET_KEYS: (state, keys) => {
      state.keys = keys
    },
    SET_ERROR: (state, value) => {
      const info = { ...state.errorTime, ...value }
      state.errorTime = info
    },
    CLEAR_ERROR: (state) => {
      state.errorTime = {
        time: Date.now(),
        number: 0
      }
    },
    SET_LASTTIME: (state, time) => {
      state.lastTime = time
    }
  },
  actions: {
    async login ({ commit, state }, params) {
      const { username, password } = params
      try {
        const data = await services.user.login({ username, password })
        const now = Date.now()
        if (data.status === 1) {
          setUserInfo(data.info)
          commit('SET_USERINFO', data.info)
          commit('SET_LASTTIME', now)
          setLastTime(now)
        } else {
          // 存储密码错误次数/时间
          // 如果大于30分钟，就重置错误次数
          const isReset = (now - state.errorTime.time) > 30 * 60 * 1000
          const errorTime = {
            time: now,
            number: isReset ? 1 : state.errorTime.number + 1
          }
          commit('SET_ERROR', errorTime)
          setErrorTime(errorTime)
          // Message.error(data.info)
        }
        return data
      } catch (error) {
        Message.error(error)
      }
    },
    async tellogin ({ commit }, params) {
      try {
        const data = await services.user.loginbyMobCode(params)
        if (data.status === 1) {
          setUserInfo(data.info)
          commit('SET_USERINFO', data.info)
        } else {
          // Message.error(data.info)
        }
        return data
      } catch (error) {
        Message.error(error)
      }
    },
    async logout ({ commit, state }) {
      const { uid } = state.userInfo
      if (uid) {
        await services.user.quit(uid)
      }
      commit('CLEAR_USERINFO')
      removeUserInfo()
      resetRouter()
      commit('SET_ROUTES', [])
      commit('SET_ROLE', '')
      commit('SET_KEYS', [])
    },
    async getUserRoles ({ commit, state }) {
      const data = await services.user.getUserMenueList4PC()
      const { status, info } = data
      if (status) {
        commit('SET_ROLE', info.gpkwdname)

        const userInfo = state.userInfo
        userInfo.groupid = info.gpid
        userInfo.groupname = info.gpkwdname
        setUserInfo(userInfo)
        commit('SET_USERINFO', userInfo)
        const menuelist = info.menuelist || []

        const ismmtyymonitor = parseInt(info.ismmtyymonitor || 0)

        // const keys = menuelist.map(item => item.kwd)
        let keys = menuelist.filter(item => item.haspower).map(item => item.kwd)

        // 认证管理中心
        keys.push('virrzmgr')
        // 特殊处理----------
        if (userInfo.uid === '22224') {
          keys = ['memberindex', 'virinvenmgr', 'realinven', 'invenaccount']
        }
        // -----------------

        const myHideMenu = ['virsysmgr', 'virjsmgr', 'otherordermgr', 'virdatasync']
        // hide menus
        if (ismmtyymonitor) {
          keys = keys.filter(item => !myHideMenu.includes(item))
        }

        commit('SET_KEYS', keys)
      } else {
        // Message.error(info)
        commit('CLEAR_USERINFO')
        removeUserInfo()
        resetRouter()
        commit('SET_ROUTES', [])
        commit('SET_ROLE', '')
        commit('SET_KEYS', [])
      }
      return data
    },
    async updateIcon ({ commit, state }, icon) {
      const userInfo = state.userInfo
      userInfo.icon = icon
      setUserInfo(userInfo)
      commit('SET_USERINFO', userInfo)
    },
    async clearRoleXX ({ commit }) {
      commit('SET_ROUTES', [])
      commit('SET_ROLE', '')
      commit('SET_KEYS', [])
      resetRouter()
    },
    async clearErrorTime ({ commit }) {
      commit('CLEAR_ERROR')
      removeErrorTime()
    },
    async setLastTime ({ commit }) {
      const now = Date.now()
      commit('SET_LASTTIME', now)
      setLastTime(now)
    }
  }
}
