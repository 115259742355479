<template>
  <div class="ag-table-component">
    <div class="toolbar">
      <div class="left">
        <!-- <el-button type="primary" size="mini" @click="clearFilter" plain
          >清空筛选</el-button
        > -->
        <slot name="left"></slot>
      </div>
      <div class="right">
        <slot name="right"></slot>
        <el-pagination
          v-if="showPagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curPage"
          :page-sizes="[20, 50, 100, 500, 1000, 2000, 5000, 10000]"
          :page-size.sync="curPageSize"
          :pager-count="5"
          layout="prev, pager, next, sizes, total"
          :total="pageTotalNum"
        >
        </el-pagination>
        <el-dropdown trigger="click" @command="handleCommand">
          <el-button
            type="primary"
            size="mini"
            plain
            style="width: 28px; height: 28px"
            icon="el-icon-s-tools"
            circle
          ></el-button>
          <el-dropdown-menu slot="dropdown" ref="mydropd">
            <el-dropdown-item icon="el-icon-s-grid" command="hide"
              >列表设置</el-dropdown-item
            >
            <el-dropdown-item icon="el-icon-s-flag" command="fixed"
              >固定设置</el-dropdown-item
            >
            <!-- <el-dropdown-item icon="el-icon-rank" command="full"
              >全屏展示</el-dropdown-item
            > -->
            <el-dropdown-item icon="el-icon-refresh" command="reset"
              >重置表格</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <ag-grid-vue
      ref="agGridVue"
      class="ag-theme-alpine ag-grid-v-line"
      v-bind="$attrs"
      v-on="$listeners"
      :style="{ height: `calc(100vh - ${dlength})` }"
      :rowHeight="30"
      :headerHeight="30"
      :columnDefs="allColumns"
      :rowData="displayData"
      :defaultColDef="defaultColDef"
      rowSelection="multiple"
      @grid-ready="onGridReady"
      @first-data-rendered="onFirstDataRendered"
      :rowMultiSelectWithClick="true"
      :suppressRowClickSelection="true"
      :suppressCellFocus="true"
      :pinnedBottomRowData="pinnedBottomRowData"
      :localeText="localeText"
      :tooltipShowDelay="0"
      @selection-changed="onRowSelected"
      @column-moved="handleColumnMoved"
      @column-visible="handleColumnVisibled"
      @filter-changed="onFilterChanged"
      @sort-changed="handleSortChanged"
      @column-resized="handleColumnResized"
      @newColumnsLoaded="handleColumnValueChanged"
      @cell-clicked="onCellClicked"
      :rowClassRules="rowClassRules"
    >
    </ag-grid-vue>
    <CustomDiaplayVue
      v-if="showCustomDisplay"
      :visible.sync="showCustomDisplay"
      :data="diaplayData"
      @display-change="displayChange"
    ></CustomDiaplayVue>
    <CustomPinned
      v-if="showCustomPinned"
      :visible.sync="showCustomPinned"
      @display-change="displayChange"
      :data="diaplayData"
    ></CustomPinned>
  </div>
</template>

<script>
// :enableCellTextSelection="true"
// :ensureDomOrder="true"
// 导致样式问题
import { AgGridVue } from 'ag-grid-vue'
import AG_GRID_LOCALE_CN from '@/components/AgTable/AG_GRID_LOCALE_CN'
import CustomeTextFilterComponent from '@/components/AgTable/CustomeTextFilterComponent'
import CustomDiaplayVue from '@/components/AgTable/CustomDiaplay.vue'
import CustomPinned from '@/components/AgTable/CustomPinned'
import ClearFilterBtn from '@/components/AgTable/ClearFilterBtn.vue'

const NoFilterComponent = {
  name: 'NoFilterComponent',
  render (h) {
    return h(
      'div',
      {
        style: {
          'text-align': 'center'
        }
      },
      ['筛选']
    )
  }
}

const EmptyComponent = {
  name: 'EmptyComponent',
  render (h) {
    return h('div')
  }
}

export default {
  components: {
    AgGridVue,
    CustomDiaplayVue,
    CustomPinned,
    /* eslint-disable */
    NoFilterComponent,
    EmptyComponent,
    ClearFilterBtn,
    CustomeTextFilterComponent
    /* eslint-enable */
  },
  props: {
    address: {
      type: String,
      required: true
    },
    dlength: {
      type: String,
      default: '200px'
    },
    columns: {
      type: Array,
      default: () => [],
      required: true
    },
    data: {
      type: Array,
      default: () => [],
      required: true
    },
    total: {
      type: Object,
      default: () => ({})
    },
    selectTotal: {
      type: Object,
      default: () => ({})
    },
    filterTotal: {
      type: Object,
      default: () => ({})
    },
    allTotal: {
      type: Object,
      default: () => ({})
    },
    pageTotalNum: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 100
    },
    currentPage: {
      type: Number,
      default: 1
    },
    showPagination: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    data: {
      handler (newData) {
        if (this.gridApi) {
          this.gridApi.refreshCells()
          this.$nextTick(() => {
            this.onRowSelected()
          })
          const filter = this.gridApi.getFilterModel()
          if (Object.keys(filter).length === 0) {
            this.$emit('filter-search', [])
          } else {
            const rowData = []
            this.$nextTick(() => {
              this.gridApi.forEachNodeAfterFilter(node => {
                rowData.push(node.data)
              })
              this.$emit('filter-search', rowData)
            })
          }
        }
      },
      deep: true
    }
  },
  computed: {
    displayData () {
      return this.data.map(item => item)
    },
    pinnedBottomRowData () {
      const t1 = Object.keys(this.selectTotal || {}).length !== 0
      const t2 = Object.keys(this.filterTotal || {}).length !== 0
      const t3 = Object.keys(this.total || {}).length !== 0
      const t4 = Object.keys(this.allTotal || {}).length !== 0
      const arr = []
      if (t1) arr.push({ typeIndex: '选中', ...this.selectTotal })
      if (t2) arr.push({ typeIndex: '筛选', ...this.filterTotal })
      if (t3) arr.push({ typeIndex: '合计', ...this.total })
      if (t4) arr.push({ typeIndex: '总计', ...this.allTotal })
      // if (!t1 && !t2) {
      //   return [{ typeIndex: '选中', ...this.selectTotal }, { typeIndex: '合计', ...this.total }]
      // } else if (!t1) {
      //   return [{ typeIndex: '选中', ...this.selectTotal }]
      // } else if (!t2) {
      //   return [{ typeIndex: '合计', ...this.total }]
      // } else return []
      return arr
    },
    curPage: {
      get () {
        return this.currentPage
      },
      set (val) {
        this.$emit('update:currentPage', val)
      }
    },
    curPageSize: {
      get () {
        return this.pageSize
      },
      set (val) {
        this.$emit('update:pageSize', val)
      }
    },
    // allTableData () {
    //   return this.data.map((item, index) => {
    //     return {
    //       ...item,
    //       typeIndex: index + 1
    //     }
    //   })
    // },
    allColumns () {
      // const simpeColumns = this.columns.filter(item => (!item.cellRenderer && !item.cellRendererSelector))
      // const btnColumns = this.columns.filter(item => (item.cellRenderer || item.cellRendererSelector))
      // btnColumns = btnColumns.map(item => {
      //   return {
      //     field: item.field,
      //     headerName: item.headerName,
      //     sortable: false,
      //     filter: false,
      //     cellRenderer: item.cellRenderer,
      //     cellRendererParams: item.cellRendererParams,
      //     cellRendererSelector: item.cellRendererSelector
      //   }
      // })
      const allColumns = [
        this.typeIndexColumnDef,
        this.checkBoxColumnDef,
        ...this.columns.map(i => {
          if (i.cellRenderer || i.cellRendererSelector) {
            return i
          } else {
            return {
              ...i,
              tooltipField: i.field
            }
          }
        })
      ]
      return allColumns
    },
    keyName () {
      const routeName = this.$route.path
      const address = this.address
      const keyName = address ? `${routeName}_${address}` : `${routeName}`
      return keyName
    }
  },
  data () {
    return {
      typeIndexColumnDef: {
        headerName: '',
        pinned: 'left',
        field: 'typeIndex',
        width: 55,
        minWidth: 50,
        floatingFilterComponent: 'NoFilterComponent',
        valueGetter: params => {
          if (params.node.rowPinned) return params.data[params.colDef.field]
          return params.node.rowIndex + 1
        },
        cellStyle: {
          textAlign: 'center'
        },
        sortable: false,
        resizable: false,
        suppressMovable: true,
        suppressMenu: true
      },
      checkBoxColumnDef: {
        width: 60,
        headerName: '',
        field: 'typeSelectBox',
        checkboxSelection: true,
        pinned: 'left',
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        floatingFilterComponent: 'ClearFilterBtn',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          enter: this.clearFilter,
          getFilterLength: () => this.filterLength
        },
        sortable: false,
        // filter: false,
        resizable: false,
        suppressMovable: true,
        cellClass: 'cellCenter',
        headerClass: 'headerCellCenter'
        // suppressMenu: true,
      },
      filterLength: 0,
      defaultColDef: {
        // flex: 1,
        resizable: true,
        sortable: true,
        filter: true,
        floatingFilter: true,
        floatingFilterComponent: 'CustomeTextFilterComponent',
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          enter: this.handleEnterClick
        },
        width: 100,
        lockPinned: true
        // editable: true
        // suppressMenu: true
      },
      localeText: AG_GRID_LOCALE_CN,
      gridApi: null,
      gridColumnApi: null,
      showCustomDisplay: false,
      showCustomPinned: false,
      diaplayData: [],
      localStorageKey: 'AG_TABLE_CUSTOM_DISPLAY'
    }
  },
  created () {
    this.rowClassRules = {
      'check-bottom-pinned': params => {
        return params.node.rowPinned && params.data.typeIndex === '选中'
      },
      'total-bottom-pinned': params => {
        return params.node.rowPinned
      }
    }
  },
  mounted () {
    // this.$refs.dropdownhere.appendChild(
    //   this.$refs.mydropd.popperElm
    // )
  },
  activated () {
    this.initData()
  },
  methods: {
    clearFilter () {
      this.gridApi.setFilterModel(null)
    },
    handleEnterClick () {
      const filter = this.gridApi.getFilterModel()
      const params = Object.keys(filter).map(key => {
        return {
          field: key,
          value: filter[key].filter
        }
      })
      this.$emit('filter-enter', params)
    },
    onRowSelected () {
      const selectedRows = this.gridApi.getSelectedRows()
      this.$emit('select-changed', selectedRows)
    },
    handleColumnMoved (event) {
      if (event.finished) {
        this.saveData()
      }
    },
    handleColumnValueChanged (event) {
      // 为了解决兼容不同tab显示不同字段，所以需要重置column状态
      // 但会带来一个问题，排序的状态不会保留
      // 解决办法，将排序存入storage
      this.gridColumnApi && this.gridColumnApi.resetColumnState()
      this.initData()
    },
    handleSortChanged (e) {
      if (e.source === 'uiColumnSorted' && e.type === 'sortChanged') {
        this.saveData()
      }
    },
    handleColumnVisibled (event) {
      this.saveData()
    },
    handleColumnResized (event) {
      if (event.finished) {
        // 如果是表格自动改变
        if (event.source === 'gridOptionsChanged') {
          this.initData()
        } else {
          this.saveData()
        }
      }
    },
    onFilterChanged (e) {
      const filter = this.gridApi.getFilterModel()
      this.filterLength = Object.keys(filter).length
      if (this.filterLength === 0) {
        this.$emit('filter-search', [])
      } else {
        const rowData = []
        e.api.forEachNodeAfterFilter(node => {
          rowData.push(node.data)
        })
        this.$emit('filter-search', rowData)
      }
    },
    onGridReady (params) {
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
      this.initData()
    },
    onFirstDataRendered (params) {
      if (this.data.length > 0) {
        // 本地数据
        const customDisplay = JSON.parse(
          window.localStorage.getItem(this.localStorageKey) || '{}'
        )
        const columnLocal =
          (customDisplay[this.keyName] && customDisplay[this.keyName].column) ||
          []
        if (columnLocal.length > 0) {
          return
        }
        // const allColumnIds = params.columnApi.getColumns().map((column) => column.getId())
        const allColumnIds = this.gridColumnApi
          .getColumns()
          .filter(column => !column.userProvidedColDef.width)
          .map(column => column.getId())
        params.columnApi.autoSizeColumns(allColumnIds, false)
      }
    },
    handleSizeChange (val) {
      this.curPage = 1
      this.$emit('size-change', val)
    },
    handleCurrentChange (val) {
      this.$emit('current-change', val)
    },
    handleCommand (command) {
      if (command === 'hide') this.handleShowCustomDisply()
      if (command === 'fixed') this.handleShowCustomPinned()
      if (command === 'full') this.fullScreen()
      if (command === 'reset') this.reset()
    },
    reset () {
      const customDisplay = JSON.parse(
        window.localStorage.getItem(this.localStorageKey) || '{}'
      )
      customDisplay[this.keyName] = {}
      window.localStorage.setItem(
        this.localStorageKey,
        JSON.stringify(customDisplay)
      )
      if (this.gridColumnApi) {
        this.gridColumnApi.resetColumnState()
        // this.gridColumnApi && this.gridColumnApi.sizeColumnsToFit()
        // const allColumnIds = this.gridColumnApi.getColumns().map((column) => column.getId())
        const allColumnIds = this.gridColumnApi
          .getColumns()
          .filter(column => !column.userProvidedColDef.width)
          .map(column => column.getId())
        this.gridColumnApi.autoSizeColumns(allColumnIds, false)
      }
    },
    getDisplayData () {
      const cols = this.gridColumnApi.getAllGridColumns()
      this.diaplayData = cols
        .map(col => {
          return {
            colId: col.colId,
            field: col.colDef.field,
            headerName: col.colDef.headerName,
            actualWidth: col.actualWidth,
            visible: col.visible,
            pinned: col.pinned || null
          }
        })
        .filter(
          item => item.field !== 'typeIndex' && item.field !== 'typeSelectBox'
        )
    },
    handleShowCustomDisply () {
      this.getDisplayData()
      this.showCustomDisplay = true
    },
    handleShowCustomPinned () {
      this.getDisplayData()
      this.showCustomPinned = true
    },
    displayChange (state) {
      this.gridColumnApi.applyColumnState({
        state
      })
      this.getDisplayData()
      this.saveData()
    },
    initData () {
      // 本地数据
      const customDisplay = JSON.parse(
        window.localStorage.getItem(this.localStorageKey) || '{}'
      )
      const columnLocal =
        (customDisplay[this.keyName] && customDisplay[this.keyName].column) ||
        []
      if (columnLocal.length > 0 && this.gridColumnApi) {
        this.gridColumnApi.applyColumnState({
          state: [...columnLocal],
          applyOrder: true
        })
      } else if (
        this.gridColumnApi &&
        this.data.length > 0 &&
        columnLocal.length === 0
      ) {
        // const allColumnIds = params.columnApi.getColumns().map((column) => column.getId())
        const allColumnIds = this.gridColumnApi
          .getColumns()
          .filter(column => !column.userProvidedColDef.width)
          .map(column => column.getId())
        this.gridColumnApi.autoSizeColumns(allColumnIds, false)
      }
    },
    saveData () {
      const allColumnState = this.gridColumnApi.getColumnState()
      const columnList = allColumnState.map(item => {
        return {
          colId: item.colId,
          hide: item.hide,
          pinned: item.pinned,
          width: item.width,
          sort: item.sort,
          sortIndex: item.sortIndex
        }
      })
      const customDisplay = JSON.parse(
        window.localStorage.getItem(this.localStorageKey) || '{}'
      )
      if (!customDisplay[this.keyName]) {
        customDisplay[this.keyName] = {}
      }
      customDisplay[this.keyName].column = columnList
      window.localStorage.setItem(
        this.localStorageKey,
        JSON.stringify(customDisplay)
      )
    },
    fullScreen () {
      const element = this.$refs.agGridVue.$el
      const runfullScreen =
        element.requestFullscreen ||
        element.mozRequestFullScreen ||
        element.webkitRequestFullScreen ||
        element.msRequestFullscreen

      if (runfullScreen) runfullScreen.call(element)
      else {
        this.$message.error('当前浏览器不支持全屏！')
      }
    },
    onCellClicked (e) {
      this.$emit('cell-single-click', e)
    },
    exitFullScreen () {
      const runExit =
        document.exitFullscreen ||
        document.mozCancelFullScreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen

      if (runExit) runExit.call(document)
      else {
        console.error('当前浏览器不支持退出全屏！')
      }
    }
  }
}
</script>

<style lang="scss">
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
.ag-theme-alpine {
  --ag-row-height: 30px;
  --ag-cell-horizontal-padding: 7px;
  --ag-font-size: 12px;

  --ag-background-color: #fff;
  --ag-header-background-color: #fff;
  --ag-alpine-active-color: #32b16c;
  --ag-selected-row-background-color: #9fdebc;
  --ag-row-hover-color: #e2efe8;
  --ag-header-column-separator-display: block;
  --ag-border-color: #e4e7ed;
  --ag-header-column-separator-color: #e4e7ed;
  --ag-header-column-resize-handle-display: none;

  // --ag-input-border-color: #32b16c;
  --ag-input-focus-box-shadow: none;
  --ag-checkbox-unchecked-color: #dcdfe6;
  --ag-input-focus-border-color: #32b16c;

  --ag-border-radius: 0;
  --ag-card-radius: 0;

  .ag-picker-field-wrapper {
    border-radius: 0;
  }

  .ag-unselectable {
    .ag-floating-top,
    .ag-body-viewport,
    .ag-floating-bottom {
      user-select: text;
    }
  }

  .ag-cell-editor {
    .ag-text-field-input {
      border: none;
    }
  }

  .check-bottom-pinned {
    font-weight: bold;
    background-color: #9fdebc;
  }

  .total-bottom-pinned {
    font-weight: bold;
  }

  .ag-filter-body .ag-input-field-input.ag-text-field-input {
    background-color: #fff;
    background-image: none;
    // border-radius: 5px;
    // border: 1px solid #dcdfe6;
    // border: none;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 28px;
    line-height: 28px;
    outline: none;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }

  .cellCenter {
    .ag-cell-wrapper {
      justify-content: center;

      .ag-selection-checkbox {
        margin-right: 0;
      }
    }
    .ag-cell-value {
      display: none;
    }
  }

  .headerCellCenter {
    justify-content: center;

    .ag-header-select-all {
      margin-right: 0;
    }

    .ag-header-cell-comp-wrapper {
      display: none;
    }
  }
}

.ag-grid-v-line .ag-cell {
  & > * {
    height: 27px;
    line-height: 27px;
  }
  border-right-color: #e4e7ed !important;

  &.ag-cell-inline-editing {
    border-right-color: #32b16c !important;
  }
}

// .ag-grid-v-line .ag-header-cell {
//   border-right: 1px solid #e4e7ed !important;
// }
</style>

<style lang="scss">
.ag-table-component {
  padding: 5px;
  background-color: #fff;

  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 5px 0px;
    background-color: #f9f9f9;

    .el-button {
      border-radius: 0 !important;
    }

    .el-input__inner {
      border-radius: 0 !important;
    }

    .el-input {
      border-radius: 0 !important;
    }

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .left {
      flex: 1;
      min-width: 0;
    }

    // ::v-deep .el-pagination__sizes {
    //   // .el-input {
    //   //   margin: 0;

    //   //   .el-input__inner {
    //   //     // height: 21px;
    //   //     // line-height: 21px;
    //   //     font-size: 12px;
    //   //   }
    //   //   .el-input__icon {
    //   //     line-height: 21px;
    //   //   }
    //   // }
    // }
  }
}
</style>
